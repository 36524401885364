import * as React from 'react'
import { useState } from 'react'
import classNames from 'classnames'
import TopNavMenuItem from './TopNavMenuItem'
import { TopNavStoryblokType, } from '../../types/storyblokTypes'
import { useIsMenuFloating } from './useIsMenuFloating'
import Button from '../../components/buttons/components/Button'
import { isDesktopView } from '../navigationUtils';
import TopBar from './TopBar';
import TopNavLogo from './TopNavLogo';
import TopNavHamburger from './TopNavHamburger';
import TopNavMenuItemThreeCards from './TopNavMenuItemThreeCards';

export type TopNavProps = {
  inverted?: boolean
  hideTopBar?: boolean
} & TopNavStoryblokType;

const TopNav = ({
                  menu_items,
                  logo_link,
                  inverted,
                  loginButtonLink,
                  loginButtonLabel,
                  primaryButtonLabel,
                  primaryButtonLink,
                  secondaryButtonLabel,
                  secondaryButtonLink,
                  topBarText,
                  topBarLabel,
                  topBarLink,
                  hideTopBar
                }: TopNavProps) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [expandedMenuItem, setExpandedMenuItem] = useState<number | null>(null);
  const isMenuFloating = useIsMenuFloating();

  const toggleHover = (menuId: number | null) => {
    isDesktopView() && setExpandedMenuItem(menuId)
  };

  const toggleClick = (menuId: number | null) => {
    !isDesktopView() && setExpandedMenuItem(menuId)
  };

  const menuItemsElms = menu_items.map((menuItem, idx) => {
    const isExpanded = expandedMenuItem === idx;
    const commonProps = {
      isExpanded: isExpanded,
      onClick: isExpanded ? () => toggleClick(null) : () => toggleClick(idx),
      onMouseEnter: () => toggleHover(idx),
      onMouseLeave: () => toggleHover(null),
    };

    switch (menuItem.component) {
      case 'TopNav Menu Item':
        return <TopNavMenuItem {...commonProps} menuItem={menuItem} key={idx}/>;
      case 'TopNav Three Cards Item':
        return <TopNavMenuItemThreeCards {...commonProps} menuItem={menuItem} key={idx}/>;
      default:
        return null;
    }
  });

  return (
    <>
      {!hideTopBar && <TopBar
        loginButtonLabel={loginButtonLabel}
        loginButtonLink={loginButtonLink}
        topBarLabel={topBarLabel}
        topBarLink={topBarLink}
        topBarText={topBarText}
      />}
      <div
        className={classNames('topNav', {
          'topNav--expanded': isMenuExpanded,
          'topNav--floating': isMenuFloating,
          'topNav--inverted': inverted && !isMenuFloating
        })}
      >
        <div className="container">
          <div className="topNav__inner">
            <TopNavLogo logo_link={logo_link}/>
            <TopNavHamburger
              isMenuExpanded={isMenuExpanded}
              onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            />
            <ul
              className={classNames('topNav__menu', {
                'topNav__menu-expanded': isMenuExpanded,
              })}
            >
              {menuItemsElms}
              {secondaryButtonLabel && (
                <li className="topNav__button topNav__button--secondaryCtaButton">
                  <Button
                    severity={'tertiary-small'}
                    label={secondaryButtonLabel}
                    link={secondaryButtonLink}
                  />
                </li>
              )}
              {primaryButtonLabel && (
                <li className="topNav__button">
                  <Button
                    severity={'primary'}
                    label={primaryButtonLabel}
                    link={primaryButtonLink}
                  />
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
};

export default TopNav
