import React from 'react'
import TextField from '../../form/components/TextField'
import FormRow from '../../form/components/FormRow'
import CheckBox from '../../form/components/CheckBox'
import TextArea from '../../form/components/TextArea'
import { FormLabels } from '../../form/types'
import { ZOHO_FORM_IDS } from '../zohoConfig';
import { useZohoFormLogic, ZohoFormFields, } from '../../form/zohoLogic'
import SubmitButton from '../../form/components/SubmitButton';
import { getMarketingConsent } from '../utils/marketingConsent';

const ZOHO_CONTACT_US_SUBMIT_URL =
  `https://form.accurity.ai/simplitygroup/form/${ZOHO_FORM_IDS.CONTACT}/htmlRecords/submit`;

export type ContactUsType = {
  firstName: string
  lastName: string
  company: string
  email: string
  enquiry: string
  newsletter: boolean
  marketing: boolean
}

export type ZohoContactUsFormProps = {
  formLabels: FormLabels
  formSource?: string
  successRedirectUrl: string
}

const ZohoContactUsForm = ({
                             formLabels,
                             formSource = 'Accurity Contact Us',
                             successRedirectUrl
                           }: ZohoContactUsFormProps) => {
  const zohoFields: ZohoFormFields<ContactUsType> = {
    firstName: {
      zohoFieldName: 'SingleLine7',
      label: formLabels.firstNameLabel,
      initialValue: '',
      required: true,
    },
    lastName: {
      zohoFieldName: 'SingleLine8',
      label: formLabels.lastNameLabel,
      initialValue: '',
      required: true,
    },
    company: {
      zohoFieldName: 'SingleLine9',
      label: formLabels.companyLabel,
      initialValue: '',
      required: true,
    },
    email: {
      zohoFieldName: 'Email',
      label: formLabels.emailLabel,
      initialValue: '',
      required: true,
      runEmailValidation: true,
    },
    enquiry: {
      zohoFieldName: 'MultiLine',
      label: formLabels.enquiryLabel,
      initialValue: '',
      required: true,
    },
    newsletter: {
      zohoFieldName: 'DecisionBox',
      initialValue: false,
      required: false,
    },
    marketing: {
      zohoFieldName: 'DecisionBox1',
      initialValue: false,
      required: false,
    },
  };

  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
  } = useZohoFormLogic<ContactUsType>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_CONTACT_US_SUBMIT_URL,
    successRedirectUrl: successRedirectUrl,
    submitErrorMessage: formLabels.submitErrorMessage || '',
    zohoFormFields: zohoFields,
  });

  return (
    <div className="form__container">
      <h3 className="form__headline">{formLabels.title}</h3>
      <FormRow>
        <TextField
          name="firstName"
          label={zohoFields.firstName.label}
          value={values.firstName}
          setValue={setValue}
          error={errors.firstName}
          clearError={clearError}
        />
        <TextField
          name="lastName"
          label={zohoFields.lastName.label}
          value={values.lastName}
          setValue={setValue}
          error={errors.lastName}
          clearError={clearError}
        />
      </FormRow>
      <TextField
        name="company"
        label={zohoFields.company.label}
        value={values.company}
        setValue={setValue}
        error={errors.company}
        clearError={clearError}
      />
      <TextField
        name="email"
        label={zohoFields.email.label}
        value={values.email}
        setValue={setValue}
        error={errors.email}
        clearError={clearError}
      />
      <TextArea
        name="enquiry"
        label={zohoFields.enquiry.label}
        value={values.enquiry}
        setValue={setValue}
        error={errors.enquiry}
        clearError={clearError}
      />
      <div className="form__checkBoxContainer">
        <CheckBox
          name="marketing"
          label={getMarketingConsent()}
          value={values.marketing}
          setValue={setValue}
        />
      </div>
      <div className="form__buttonContainer">
        <SubmitButton
          isFetching={isFetching}
          label={formLabels.ctaButtonLabel}
          doSubmit={doSubmit}
        />
        {!!submitError && <p className="form__submit-error">{submitError}</p>}
      </div>
    </div>
  )
};

export default ZohoContactUsForm
