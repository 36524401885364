import React from 'react'
// @ts-ignore
import Available from '../../../../images/icons/available.svg'
// @ts-ignore
import NonAvailable from '../../../../images/icons/non-available.svg'
import { StoryblokPricingTableRow, StoryblokTableRowType } from '../../../../types/storyblokTypes';
import { SolutionTableRow } from './SolutionTableRow';

const mapStoryblokPricingTableRow = (row: StoryblokPricingTableRow): SolutionTableRow => ({
  cells: [
    {value: row.title, tooltip: row.titleTooltip},
    {value: PricingTableUtils.formatValue(row.tier1text || row.tier1sign)},
    {value: PricingTableUtils.formatValue(row.tier2text || row.tier2sign)},
    {value: PricingTableUtils.formatValue(row.tier3text || row.tier3sign)}
  ],
  children: row.children?.map(mapStoryblokPricingTableRow),
  expanded: row.expanded
});

const mapStoryblokTableRow = (row: StoryblokTableRowType): SolutionTableRow => ({
  cells: row.body.map(q => ({
    value: PricingTableUtils.formatValue(q.value)
  }))
});

const formatValue = (value?: string) => {
  switch (value) {
    case 'tick':
      return <Available/>;
    case 'dash':
      return <NonAvailable/>;
    default:
      return value;
  }
};


export const PricingTableUtils = {
  mapStoryblokPricingTableRow: mapStoryblokPricingTableRow,
  mapStoryblokTableRow: mapStoryblokTableRow,
  formatValue: formatValue
};




