import { FilterOption, Filters, isFilterOptionEqual } from '../types/types';
import { useCallback, useState } from 'react';

export const useFiltering = <T extends Filters>(config: T) => {

  type FiltersKeys = keyof T;
  type ActiveFilters = { [filter in FiltersKeys]: FilterOption[] };

  const getInitialActiveFilters = () => {
    return Object.keys(config).reduce((collector, filterKey) => ({
      ...collector,
      [filterKey]: config[filterKey].preselectedOptions || []
    }), {} as ActiveFilters)
  };

  const [activeFilters, setActiveFilters] = useState<ActiveFilters>(getInitialActiveFilters());

  const resetFilters = useCallback(() => {
    setActiveFilters(getInitialActiveFilters())
  }, [config]);

  const toggleFilterValue = useCallback((filter: FiltersKeys, option: FilterOption, isChecked: boolean) => {
    setActiveFilters(prevState => {
      const filterConfig = config[filter];
      let selectedOptions: FilterOption[] = [];

      if (filterConfig.multiSelection) {
        selectedOptions = isChecked
          ? [...prevState[filter], option]
          : prevState[filter].filter(q => !isFilterOptionEqual(q, option));
      } else {
        selectedOptions = isChecked ? [option] : [];
      }

      return {
        ...prevState,
        [filter]: selectedOptions
      }
    });
  }, [config]);

  return {
    activeFilters,
    config,
    resetFilters,
    toggleFilterValue
  }
};

