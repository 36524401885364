import * as React from 'react'
import { ResourceCardType, TopNavMenuItemStoryblokType, } from '../../types/storyblokTypes'
import TopNavMenuItemContainer from './TopNavMenuItemContainer';
import StoryblokLink from '../../components/storyblokLink/components/StoryblokLink';
import TopNavDropdownWrapper from './dropdown/TopNavDropdownWrapper';
import Button from '../../components/buttons/components/Button';
import TopNavComponents from './components'
import { getResourceCardData } from '../../components/resourceCardContainer/utils';

export type TopNavMenuItemProps = {
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isExpanded?: boolean
  menuItem: TopNavMenuItemStoryblokType;
};

const TopNavMenuItem = ({
                          onClick,
                          onMouseEnter,
                          onMouseLeave,
                          isExpanded,
                          menuItem
                        }: TopNavMenuItemProps) => {

  const submenuItemsElements = menuItem.submenu_items?.map((item, i) =>
    React.createElement(TopNavComponents(item.component), { ...item, key: i, })
  );

  const itemList = <ul className="topNavDropdown__menu">
    {submenuItemsElements}
    {menuItem.button && menuItem.button[0] ? <Button {...menuItem.button[0]} /> : null}
  </ul>;

  const promotedItemElm = getPromotedItemElement(menuItem.promotedItem, menuItem.promotedCtaLabel);

  return <TopNavMenuItemContainer
    label={menuItem.label}
    link={menuItem.link}
    style={menuItem.style}
    isExpanded={isExpanded}
    onClick={onClick}
    onMouseLeave={onMouseLeave}
    onMouseEnter={onMouseEnter}
  >
    {submenuItemsElements && submenuItemsElements?.length > 0 ? (
      <TopNavDropdownWrapper isVisible={!!isExpanded}>
        {promotedItemElm ? (
          <div className="topNavDropdown__promoted">
            {itemList}
            {promotedItemElm}
          </div>
        ) : itemList}
      </TopNavDropdownWrapper>
    ) : null}
  </TopNavMenuItemContainer>
};

const getPromotedItemElement = (promotedItem?: ResourceCardType, promotedCtaLabel?: string) => {

  const promotedItemConfig = promotedItem ? getResourceCardData(promotedItem) : null;

  if (!promotedItemConfig) {
    return null;
  }

  return (
    <div className="topNavDropdown__promotedItem">
      <div className="topNavDropdown__promotedItem-image" style={{ backgroundImage: `url(${promotedItemConfig.image?.filename})` }}/>
      <div className="topNavDropdown__promotedItem-text">
        <div className="topNavDropdown__promotedItem-headline">
          {promotedItemConfig.title}
        </div>
        <StoryblokLink to={promotedItemConfig.link} className="topNavDropdown__promotedItem-link">
          {promotedCtaLabel}
        </StoryblokLink>
      </div>
    </div>
  );
};

export default TopNavMenuItem
