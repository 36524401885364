import React from 'react'
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta'
import SubHeading from '../../../components/subHeading/components/SubHeading';
import ThreeElementBlockContainer from '../../../components/threeElementBlock/components/ThreeElementBlockContainer';
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock';
import { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';
import TestimonialSlider, { TestimonialSliderType } from '../../../components/testimonialsSlider/components/TestimonialSlider';
import ErrorBoundary from '../../../components/ErrorBoundary';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import CaseStudies from '../../../components/caseStudies/components/CaseStudies';
import { StoryblokImageType, StoryblokLinkType } from '../../../types/storyblokTypes';
import { LandingPageCtaBanner } from '../components/LandingPageCtaBanner';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

type LandingPageProps = {
  seoMeta: AccuritySeoMetaType,
  header: StorybookUniversalHeaderType[],
  title: string,
  paragraph: string,
  title3: string,
  useCaseOrBenefit: ThreeElementBlockType[];
  ctaBanner: { content: CtaBannerType };
  testimonials: TestimonialSliderType[];
  title2: string;
  caseStudyTitle: string;
  companyLogo: StoryblokImageType;
  image: StoryblokImageType;
  text: string;
  ctaButton: string;
  ctaLink: StoryblokLinkType;
}

const LandingPage = ({ seoMeta, header, title, paragraph, title3, useCaseOrBenefit, ctaBanner, testimonials, title2, caseStudyTitle, companyLogo, image, text, ctaButton, ctaLink }: LandingPageProps) => {
  return (
    <div className="landingPage">
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader
        {...header[0]}
        reducedMenu={true}
      />
      {/*Second headline*/}
      <section className={'homePage__referenceLogos'}>
        <div className="container">
          <SubHeading
            title={title}
            paragraph={paragraph}
          />
        </div>
      </section>

      {/*Case studies*/}
      <section className="caseStudies">
        <div className="container">
          <SubHeading title={title2} />
          <CaseStudies
            image={image}
            caseStudyTitle={caseStudyTitle}
            companyLogo={companyLogo}
            text={text}
            ctaButton={ctaButton}
            ctaLink={ctaLink}
          />
        </div>
        <BackgroundLines
          images={{
            'center': 'https://a.storyblok.com/f/91820/x/3f27aa36d1/acc-data-governance-lines.svg',
          }}
        />
      </section>

      {/*Other use cases*/}
      <section className={'businessGlossaryPage__newFeatures container'}>
        <SubHeading title={title3} />
        <ThreeElementBlockContainer
          blocksPerRow={4}
          blocks={useCaseOrBenefit}
          centered
        />
      </section>

      {/*CTA Banner*/}
      <LandingPageCtaBanner ctaBanner={ctaBanner} classname={'landingPage__ctaBanner'} />

      <div className="container">
        <section className="landingPage__testimonial">
          <ErrorBoundary placeholderStyle={{ width: '100px', height: '100px' }}>
            <TestimonialSlider
              {...testimonials[0]}
            />
          </ErrorBoundary>
        </section>
      </div>

      <FooterContainer isCompact/>
    </div>
  )
};

export default LandingPage
