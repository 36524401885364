import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import CtaBanner from '../../ctaBanner/components/CtaBanner';

type RTEBannerProps = {
  bannerUID: string;
}

const useLoadBanner = (bannerUid: string) => {
  try {
    const queryResult = useStaticQuery(
      graphql`
        query LoadAllCtaBanners {
          allStoryblokEntry(filter: {full_slug: {regex: "/^cta-banners/"}}) {
            nodes {
              content
              uuid
            }
          }
        }
      `
    );

    const banner = queryResult.allStoryblokEntry.nodes.find(q => q.uuid === bannerUid);
    return banner ? JSON.parse(banner.content) : null;
  } catch {
    console.error('Static Query for LoadAllCtaBanners failed!!!');
    return []
  }
};

const RTEBanner = ({ bannerUID }: RTEBannerProps) => {
  const banner = useLoadBanner(bannerUID);
  return (
    <div className="c-rteBanner">
      <CtaBanner banner={banner}/>
    </div>
  )
};

export default RTEBanner;
