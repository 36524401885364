import React from 'react'
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import { BlogType } from '../../../types/storyblokTypes';
import { graphql, useStaticQuery } from 'gatsby'
import { data as dummyBlogPostsData } from './fakeBlogPostsData';
import FeaturedBlogPostHero from '../../../components/blog/components/FeaturedBlogPostHero';
import { createResourceCardElement } from '../../../components/resourceCardContainer/utils';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';

const isProduction = process.env.NODE_ENV === 'production';

type BlogOverviewPageProps = {
  seoMeta: AccuritySeoMetaType;
  featuredBlog: { content: BlogType };
  featuredBlogTag: string;
  readBlogBtnLabel: string;
  loadMoreBtnLabel?: string;
  ctaBanner: { content: CtaBannerType };
  title: string;
};

const useLoadBlogPosts = (): { full_slug: string, published_at: string | null, content: BlogType }[] => {
  let queryResult: typeof dummyBlogPostsData;
  try {
    queryResult = useStaticQuery(
      graphql`
      query LoadBlogPosts {
        allStoryblokEntry(filter: {
          field_component: {eq:"Blog"}
        }) {
          nodes{
            full_slug
            published_at
            content
          }
        }
      }
    `
    );
  } catch {
    console.error('Static Query for Blog Overview failed!!! Falling back to dummy data.');
    queryResult = dummyBlogPostsData;
  }

  return queryResult.allStoryblokEntry.nodes.map(node => {
    return {
      ...node,
      content: JSON.parse(node.content)
    };
  });
};

const BlogOverviewPage = ({
                            seoMeta,
                            ctaBanner,
                            featuredBlog,
                            loadMoreBtnLabel,
                            featuredBlogTag,
                            readBlogBtnLabel,
                            title
                          }: BlogOverviewPageProps) => {

  const allBlogPosts = useLoadBlogPosts();

  // show only published blog posts in production. In development, we can see all posts
  const publishedBlogPosts = allBlogPosts.filter(q => isProduction ? q.published_at !== null : true);
  const featuredBlogPost = allBlogPosts.find(q => q.content?._uid === featuredBlog?.content?._uid) || publishedBlogPosts[0];

  return (
    <div className={'blogOverviewPage'}>
      <AccuritySeoMeta {...seoMeta} />

      {featuredBlogPost && <FeaturedBlogPostHero
        blogPost={featuredBlogPost}
        tag={featuredBlogTag}
        readPostLabel={readBlogBtnLabel}
      />}

      <div className="blogOverviewPage__blogPosts container">
        <h2>{title}</h2>
        <div className="blogOverviewPage__cardsContainer">
          {publishedBlogPosts.map(createResourceCardElement)}
        </div>
      </div>

      <BackgroundLines
        images={{
          'right': 'https://a.storyblok.com/f/91820/x/9951bf3d03/acc-blog-overview-lines-01.svg',
          'left': 'https://a.storyblok.com/f/91820/x/f0b29d97b0/acc-resource-center-lines-02.svg',
        }}
      />

      <CtaBanner banner={ctaBanner.content}/>

      <FooterContainer/>
    </div>
  )
};

export default BlogOverviewPage;
