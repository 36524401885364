import React, { useEffect, useState } from 'react'
import PricingTabsNavigation from './PricingTabsNavigation';
import { StoryblokPricingSolution } from '../../../types/storyblokTypes';
import SolutionAddonsTable from './solutionTable/SolutionAddonsTable';
import SolutionTable from './solutionTable/SolutionTable';
import CtaBanner from '../../../components/ctaBanner/components/CtaBanner';
import PricingTabForm from './PricingTabForm';
import { useLoadCtaBanner } from '../../../components/ctaBanner/hooks/loadCtaBanner';
import { kebabCase } from 'lodash'
import scrollTo from 'gatsby-plugin-smoothscroll';

export type PricingTabsProps = {
  plansTitle: string;
  tabs: StoryblokPricingSolution[];
}

const PricingTabs = ({ plansTitle, tabs }: PricingTabsProps) => {

  const [activeTab, selectActiveTab] = useState(0);
  const selectedTab = tabs[activeTab];
  const ctaBanner = useLoadCtaBanner(selectedTab.ctaBanner);
  const solutionTitles = tabs.map(q => kebabCase(q.solutionTitle));

  useEffect(() => {
    const hash = location.hash.length > 0 ? location.hash.slice(1) : undefined;
    if (hash && solutionTitles.includes(hash)) {
      selectActiveTab(solutionTitles.indexOf(hash));
      scrollTo('.pricingTabs');
    }
  }, []);

  const renderTab = (tab: StoryblokPricingSolution) => {
    if (tab.form) {
      return <PricingTabForm
        formUUID={tab.form}
        formSource={tab.formSource}
      />;
    } else {
      return <>
        <SolutionTable tab={selectedTab}/>
        <SolutionAddonsTable
          addonsTable={selectedTab.addonsTable}
          addonsSwitchLabel={selectedTab.addonsSwitchLabel}
          addonsTitle={selectedTab.addonsTitle}
        />
        {ctaBanner && <CtaBanner
          banner={ctaBanner}
        />}
      </>
    }
  };

  return (
    <div className="pricingTabs">
      <div className="container">
        <h2 className="pricingTabs__title">{plansTitle}</h2>
      </div>

      <PricingTabsNavigation
        labels={tabs.map(q => q.solutionTitle)}
        activeTabIndex={activeTab}
        onChange={selectActiveTab}
      />

      <div className="pricingTabs__content">
        <div className="container">
          {selectedTab && renderTab(selectedTab)}
        </div>
      </div>
    </div>
  )
};


export default PricingTabs;


