import * as React from 'react'
import { StoryblokLinkType, } from '../../../types/storyblokTypes'
import { LinkBoxType } from './Footer';

type FooterLinkBoxProps = {
  box: LinkBoxType;
}

const FooterLinkBox = ({
                         box
                       }: FooterLinkBoxProps) => {

  const renderLinkOrText = (label: string, link: StoryblokLinkType, className: string) => {
    return link?.url
      ? <a href={link.url} className={className}>{label}</a>
      : <span className={className}>{label}</span>
  };

  return (
    <div className="footer__linkBox">
      <div className="footer__linkBoxHeaderWrapper">
        {renderLinkOrText(box.header_label, box.header_link, 'footer__linkBoxHeader')}
      </div>

      {box.links.length > 0 && (
        <ul className="footer__linkBoxList">
          {box.links.map((link, i) => (
            <li key={i}>
              {renderLinkOrText(link.label, link.link, 'footer__linkBoxItem')}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
};

export default FooterLinkBox
