import React from 'react';
import classNames from 'classnames';
import { StoryblokImageType, StoryblokLinkType } from '../../../types/storyblokTypes';
import Button from '../../buttons/components/Button';
import { getUrlFromLink } from '../../../utils/linkUtils';

export type BlogBannerType = {
  component: 'Blog Banner'
  title?: string
  subtitle?: string
  ctaLabel: string
  ctaLink: StoryblokLinkType
  image?: StoryblokImageType
  style?: any
}

const BlogBanner = ({
                      ctaLabel,
                      ctaLink,
                      image,
                      style,
                      subtitle,
                      title
                    }: BlogBannerType) => {

  return (
    <div className="container">
      <a
        className={classNames('blogBanner', `blogBanner--${style}`)}
        href={getUrlFromLink(ctaLink)}
      >
        <div className="blogBanner__image" style={{ 'backgroundImage': `url("${image?.filename}")` }}/>
        <div className="blogBanner__textContent">
          {title && <h3 className="blogBanner__title">{title}</h3>}
          {subtitle && <p className="blogBanner__subtitle">{subtitle}</p>}
          <Button
            className="blogBanner__ctaButton"
            severity="primary"
            label={ctaLabel}
            link={ctaLink ? ctaLink : undefined}
          />
          <div className="blogBanner__dots"/>
        </div>
      </a>
    </div>
  )
};

export default BlogBanner;
