import React from 'react'
import ThreeElementBlockContainer from '../../../components/threeElementBlock/components/ThreeElementBlockContainer';
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import CustomerStoryBanner, { CustomerStoryBannerType } from '../../../components/customerStoryBanner/components/CustomerStoryBanner';
import ReactMarkdown from 'react-markdown';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type PlatformDetailPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  detailId: string;
  benefitsTitle: string;
  benefits: string;
  titleFeatures: string;
  features: ThreeElementBlockType[];
  customerStory: { content: CustomerStoryBannerType };
  ctaBanner: { content: CtaBannerType };
}

const PlatformDetailPage = ({ seoMeta, header, detailId, benefitsTitle, benefits, titleFeatures, features, customerStory, ctaBanner }: PlatformDetailPageProps) => {
  return (
    <div className={`platformDetailPage platformDetailPage--${detailId}`}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />
      <CtaBanner
        banner={ctaBanner.content}
        addMargin={true}
      />
      <div className={'container platformDetailPage__contentContainer'}>
        <div className={'platformDetailPage__benefitsSection'}>
          <h2 className={'platformDetailPage__benefitsTitle'}>{benefitsTitle}</h2>
          <ReactMarkdown>
            {benefits}
          </ReactMarkdown>
        </div>
        <div className={'platformDetailPage__featuresSection'}>
          <h2 className={'platformDetailPage__featuresTitle'}>{titleFeatures}</h2>
          <ThreeElementBlockContainer
            blocksPerRow={2}
            blocks={features}
          />
        </div>
        <div className={'platformDetailPage__callToActionBottomSection'}>
          {customerStory ?
            <CustomerStoryBanner
              {...customerStory.content}
            />
            : null
          }
        </div>
      </div>
      <BackgroundLines
        images={{
          'left': 'https://a.storyblok.com/f/91820/x/341d8f3260/acw-solution-overview-lines-01.svg',
          'right': 'https://a.storyblok.com/f/91820/x/970560b364/acw-solution-overview-lines-02.svg',
          'bottom': 'https://a.storyblok.com/f/91820/x/06f36ba3ef/acw-solution-overview-lines-03.svg'
        }}
      />
      <FooterContainer/>
    </div>
  )
};

export default PlatformDetailPage;
