import React from 'react'
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import FiftyFiftyBlock, { FiftyFiftyBlockType } from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlock';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type AccuritySaasPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  ctaBanner: { content: CtaBannerType };
  solutions: FiftyFiftyBlockType[];
}

const AccuritySaasPage = ({
                            seoMeta,
                            header,
                            ctaBanner,
                            solutions
                          }: AccuritySaasPageProps) => {

  return (
    <div className={'accuritySaasPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]}/>

      <div className={'container accuritySaasPage__contentContainer'}>
        {solutions.map((block, index) => (
          <FiftyFiftyBlock
            key={index}
            {...block}
          />
        ))}
      </div>

      <CtaBanner banner={ctaBanner.content}/>

      <FooterContainer/>
    </div>
  )
};

export default AccuritySaasPage;
