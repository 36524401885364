import { StoryblokLinkType } from "../types/storyblokTypes"

export const getUrlFromLink = (link: StoryblokLinkType) => {
  if (
    !link ||
    (link.linktype === "story" && !link.cached_url) ||
    (link.linktype === "url" && !link.url)
  ) {
    return undefined
  }

  if (link.linktype === "story") {
    let result = link.cached_url

    // url have to start with / in order to be absolute
    if (result[0] !== "/") {
      result = "/" + result
    }

    // url have to end up with /, just a convention
    if (result[result.length - 1] !== "/") {
      result = result + "/"
    }
    return result
  } else {
    return link.url
  }
}

export const checkLinkUrl = (link: string) => {
  if (!link) return "/"

  if (link === "home") return "/"

  const lastCharacter = link.slice(link.length - 1)
  const firstCharachter = link.slice(0, 1)

  let finalLink = link

  if (lastCharacter !== "/" && link.length > 1) {
    finalLink = link.concat("/")
  }

  if (firstCharachter !== "/" && link.length > 1) {
    finalLink = "/" + finalLink
  }

  return finalLink
}
