import * as React from "react"
import { navigate } from "gatsby"
import {
  StoryblokImageType,
  StoryblokLinkType,
} from "../../../types/storyblokTypes"
import { getUrlFromLink } from "../../../utils/linkUtils"

export type ResourceCardProps = {
  image?: StoryblokImageType
  title: string
  tag?: string
  date?: string
  ctaButton?: string
  ctaTarget?: StoryblokLinkType
}

const ResourceCard = ({
  image,
  ctaButton,
  ctaTarget,
  date,
  tag,
  title,
}: ResourceCardProps) => {
  const url = ctaTarget && getUrlFromLink(ctaTarget)

  return (
    <a className="resourceCard" href={url}>
      {image && (
        <div
          className="resourceCard__image"
          style={{ backgroundImage: `url(${image.filename})` }}
        />
      )}
      <div className="resourceCard__textContainer">
        {tag && <div className="resourceCard__tag">{tag}</div>}
        {title && <h4 className="resourceCard__headline">{title}</h4>}
        {date && <div className="resourceCard__date">{date}</div>}
        {ctaButton && (
          <p
            className={
              "resourceCard__callToAction fiftyFiftyBlock__callToAction"
            }
          >
            {ctaButton}
          </p>
        )}
      </div>
    </a>
  )
}

export default ResourceCard
