import { render } from 'storyblok-rich-text-react-renderer-ts';
import React from 'react';
import RTEBanner from '../../rte/components/RTEBanner';

const markResolvers: any = {
  link: (children: any, attrs: any[]) => <a {...attrs} className="blogPostText__link">{children}</a>,
  superscript: (children: any) => <sup>{children}</sup>,
  subscript: (children: any) => <sub>{children}</sub>,
};

export const renderBlogBody = (blogBody: any) => render(blogBody, {
  nodeResolvers: {
    image: (children, props) => <img className="blogPostText__image" {...(props as any)} />,
    paragraph: (children) => <p className="blogPostText__paragraph">{children}</p>,
    bullet_list: children => <ul className="blogPostText__bulletList">{children}</ul>,
    ordered_list: children => <ol className="blogPostText__orderedList">{children}</ol>,
    heading: (children, props) => {
      (props as any).className = 'blogPostText__heading' + props.level;
      return React.createElement(`h${props.level}`, props, children)
    },
  },
  blokResolvers: {
    'RTE Banner': (props: { ctaBanner: string }) => <RTEBanner bannerUID={props.ctaBanner}/>
  },
  markResolvers: markResolvers,
});
