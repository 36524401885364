import React from 'react'
import SubmitButton from '../../form/components/SubmitButton';
import { useZohoFormLogic, ZohoFormFields } from '../../form/zohoLogic';
import FormRow from '../../form/components/FormRow';
import { StorybookFormField, StorybookFormRow, StorybookUniversalFormType } from '../../../types/storyblokTypes';
import { renderField } from '../utils/universalFormUtils';
import FormContainer, { FormContainerProps } from '../../form/components/FormContainer';

const getZohoSubmitUrl = (identifier: string) =>
  `https://form.accurity.ai/simplitygroup/form/${identifier}/htmlRecords/submit`;

export type ZohoUniversalFormProps = {
  formSource: string;
  style?: FormContainerProps['style'];
  onSubmit?: () => void;
} & StorybookUniversalFormType;

const ZohoUniversalForm = (props: ZohoUniversalFormProps) => {

  const zohoFields: ZohoFormFields<any> = {};

  const processField = (field: StorybookFormField) => {
    zohoFields[field.zohoFieldName] = {
      label: field.label,
      initialValue: field.fieldType === 'checkbox' ? false : '',
      required: field.required,
      zohoFieldName: field.zohoFieldName,
      runEmailValidation: field.fieldType === 'email'
    }
  };

  props.fields.forEach(fieldOrRow => {
    if (fieldOrRow.component === 'Form Field') {
      processField(fieldOrRow);
    } else if (fieldOrRow.component === 'Form Row') {
      fieldOrRow.fields.forEach(field => processField(field));
    }
  });

  const formHandlers = useZohoFormLogic<any>({
    formIdentifier: props?.formSource,
    zohoSubmitUrl: getZohoSubmitUrl(props.zohoFormIdentifier),
    successRedirectUrl: props?.successRedirectUrl,
    submitErrorMessage: props?.submitErrorMessage || '',
    zohoFormFields: zohoFields,
    onSubmit: props.onSubmit
  });

  const renderRow = (row: StorybookFormRow, key: number) => {
    return (
      <FormRow key={key}>
        {row.fields.map((field, idx) => renderField(field, idx, formHandlers))}
      </FormRow>
    )
  };

  const renderFormItem = (item: StorybookFormRow | StorybookFormField, key: number) => {
    if (item.component === 'Form Row') {
      return renderRow(item, key);
    } else if (item.component === 'Form Field') {
      return renderField(item, key, formHandlers);
    }
  };

  return (
    <FormContainer title={props?.titleForm} style={props.style}>
      {props?.fields.map(renderFormItem)}
      <div className="form__buttonContainer">
        <SubmitButton
          isFetching={formHandlers.isFetching}
          label={props?.submitLabel}
          doSubmit={formHandlers.doSubmit}
        />
        {!!formHandlers.submitError && <p className="form__submit-error">{formHandlers.submitError}</p>}
      </div>
    </FormContainer>
  )
};

export default ZohoUniversalForm
