import React from 'react';

export type SolutionTableTooltipProps = {
  tooltip?: string;
};

const SolutionTableTooltip = ({ tooltip }: SolutionTableTooltipProps) => {

  if (!tooltip) {
    return null;
  }

  return (
    <>
      <span className="c-st__tooltip">
        <span className="c-st__tooltipIcon" data-tip={tooltip}/>
      </span>
    </>
  );
};

export default SolutionTableTooltip;
