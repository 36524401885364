import React, { useState } from 'react';
import 'react-intl-tel-input/dist/main.css';
import IntlTelInput from 'react-intl-tel-input';
import { useCountryCode } from '../utils';

export type PhoneFieldProps = {
  name: string;
  label?: string;
  value?: string;
  setValue: (name: string, value: string) => void;
  error?: string;
  clearError?: (name: string) => void;
  setError: (fieldName: string, error: string) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}

const PhoneField = ({ name, label, value = '', setValue, error, clearError, setError }: PhoneFieldProps) => {
  const [isFocused, setFocused] = useState(false);
  const country = useCountryCode();
  const hasError = !!error;

  const handleChange: Required<IntlTelInput['props']>['onPhoneNumberChange'] = (isValid, value) => {
    if (error && clearError) {
      clearError(name);
    }
    setValue(name, value);
  };

  const handleInputBlur: Required<IntlTelInput['props']>['onPhoneNumberBlur'] = (isValid, value1, selectedCountryData, fullNumber) => {
    if (isValid) {
      setValue(name, fullNumber);
    } else {
      setError(name, 'Please enter a valid phone number.')
    }
    setFocused(false);
  };

  const handleFlagSelect: Required<IntlTelInput['props']>['onSelectFlag'] = (currentNumber, selectedCountryData) => {
    if (!currentNumber) {
      setValue(name, '+' + selectedCountryData.dialCode);
    }

    // there is no way how to target input element via refs, so we need to target it directly via DOM
    const input = document.getElementById(name) as HTMLInputElement;
    if (input) {
      setTimeout(() => {
        // set cursor at the end
        input.selectionStart = input.selectionEnd = 10000
      }, 10)
    }
  };

  return (
    <div className={`phoneField textField ${isFocused ? 'textField--focused' : ''} ${hasError && !isFocused ? 'textField--error' : ''}`}>
      <span className={'textField__label'}>
        {label}
      </span>
      <IntlTelInput
        fieldId={name}
        value={value}
        onPhoneNumberChange={handleChange}
        onPhoneNumberFocus={() => setFocused(true)}
        onPhoneNumberBlur={handleInputBlur}
        preferredCountries={[]}
        defaultCountry={country}
        onSelectFlag={handleFlagSelect}
      />
      {hasError ?
        <span className={'textField__error'}>
          {error}
        </span>
        : null
      }
    </div>
  );
};

export default PhoneField;
