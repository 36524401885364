import React from 'react'
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import FiftyFiftyBlock, { FiftyFiftyBlockType } from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlock';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type AccurityLatestFeaturesPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  title: string;
  blurb: string;
  features: FiftyFiftyBlockType[];
  ctaBanner: { content: CtaBannerType };
}

const AccurityLatestFeaturesPage = ({
                                      seoMeta,
                                      header,
                                      ctaBanner,
                                      blurb,
                                      title,
                                      features
                                    }: AccurityLatestFeaturesPageProps) => {

  return (
    <div className={'accurityLatestFeaturesPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />

      <div className={'container accurityLatestFeaturesPage__contentContainer'}>

        <div className="accurityLatestFeaturesPage__introBox">
          <h2>{title}</h2>
          <p>{blurb}</p>
        </div>

        {features.map((block, index) => (
          <FiftyFiftyBlock
            key={index}
            {...block}
          />
        ))}

      </div>

      <CtaBanner banner={ctaBanner.content}/>

      <FooterContainer/>
    </div>
  )
};

export default AccurityLatestFeaturesPage;
