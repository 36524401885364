import * as React from 'react'
import classNames from 'classnames'
import { TopNavCardItem, TopNavMenuThreeCardItemStoryblokType, } from '../../types/storyblokTypes'
import TopNavDropdownWrapper from './dropdown/TopNavDropdownWrapper';
import Button, { ButtonProps } from '../../components/buttons/components/Button';
import TopNavMenuItemContainer from './TopNavMenuItemContainer';
import { isDesktopView } from '../navigationUtils';

export type TopNavMenuItemThreeCardsProps = {
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isExpanded: boolean
  menuItem: TopNavMenuThreeCardItemStoryblokType;
};

const TopNavMenuItemThreeCards = ({
                                    isExpanded,
                                    onMouseLeave,
                                    onMouseEnter,
                                    onClick,
                                    menuItem
                                  }: TopNavMenuItemThreeCardsProps) => {

  const getCtaButtonStyleProps = (style: TopNavCardItem['style']) => {
    const severity: Record<string, ButtonProps['severity']> = {
      normal: isDesktopView() ? 'primary' : 'secondary',
      dark: 'tertiary'
    };

    const props: Partial<ButtonProps> = {
      fullWidth: !isDesktopView(),
      severity: severity[style]
    };

    return props;
  };

  const cardsElms = menuItem.cards.map((card, idx) => {
    return (
      <div
        className={classNames('topNavThreeCardItem__card', {
          'topNavThreeCardItem__card--dark': card.style === 'dark'
        })}
        key={idx}
      >
        <div
          className="topNavThreeCardItem__cardImage"
          style={{ backgroundImage: `url(${card.image.filename})` }}
        />
        <div className="topNavThreeCardItem__cardTextContainer">
          <div className="topNavThreeCardItem__cardHeadline">{card.headline}</div>
          <div className="topNavThreeCardItem__cardDescription">{card.description}</div>
          {card.button[0] && <Button {...card.button[0]} className="topNavThreeCardItem__cardCtaButton" {...getCtaButtonStyleProps(card.style)} />}
        </div>
      </div>
    )
  });

  return <li onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
    <TopNavMenuItemContainer
      label={menuItem.label}
      isExpanded={isExpanded}
      onClick={onClick}
      className="topNavThreeCardItem"
      Component="div"
    >
      <TopNavDropdownWrapper isVisible={isExpanded}>
      </TopNavDropdownWrapper>
    </TopNavMenuItemContainer>
    {isExpanded && <div className="topNavThreeCardItem__dropdown">
      <div className="topNavThreeCardItem__cardsContainer">
        {cardsElms}
      </div>
    </div>}
  </li>
};

export default TopNavMenuItemThreeCards
