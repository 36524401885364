import React, { useState } from 'react'
import classNames from 'classnames'
import { StoryblokPricingSolution } from '../../../../types/storyblokTypes';
import SolutionTableHeader from './SolutionTableHeader';
import { PricingTableUtils } from './utils';
import SolutionTableRows from './SolutionTableRows';
import { SolutionTableRow } from './SolutionTableRow';
import SolutionMobileTierSwitch from './SolutionMobileTierSwitch';
import ReactTooltip from 'react-tooltip';

export type SolutionTableProps = {
  tab: StoryblokPricingSolution;
}

const SolutionTable = ({
                         tab
                       }: SolutionTableProps) => {

  // for active tier switch which is used on mobile/tablet
  const [activeTier, setActiveTier] = useState(0);

  const supportRows: SolutionTableRow[] = tab.supportTable?.tbody?.map(PricingTableUtils.mapStoryblokTableRow);
  const additionalUsersRows: SolutionTableRow[] = tab.additionalUsersTable?.tbody?.map(PricingTableUtils.mapStoryblokTableRow);

  // skip empty rows based on active tier
  const mobileTabletSupportRows: SolutionTableRow[] = supportRows?.filter(row => !!row.cells[activeTier + 1]);

  return (
    <>
      <SolutionMobileTierSwitch
        tab={tab}
        activeTier={activeTier}
        setActiveTier={setActiveTier}
      />
      <div className={classNames('c-st__table', {
        [`c-st__table--mostPopularCol2`]: tab.mostPopularTier === 'tier1',
        [`c-st__table--mostPopularCol3`]: tab.mostPopularTier === 'tier2',
        [`c-st__table--mostPopularCol4`]: tab.mostPopularTier === 'tier3',
        [`c-st__table--mobileActiveTier0`]: activeTier === 0,
        [`c-st__table--mobileActiveTier1`]: activeTier === 1,
        [`c-st__table--mobileActiveTier2`]: activeTier === 2,
      })}>
        <SolutionTableHeader tab={tab}/>

        {tab.featuresRows && tab.featuresRows.length > 0 && <div className="c-st_featuresTable">
          <SolutionTableRows
            rows={tab.featuresRows?.map(PricingTableUtils.mapStoryblokPricingTableRow)}
            title={tab.featuresTitle}
            isCollapsible={true}
            onDemandColumnLabel={tab.tier3isOnDemand ? tab.featuresOnDemandLabel : undefined}
          />
        </div>}


        {supportRows && supportRows.length > 0 && <div className="c-st_supportTableDesktop">
          <SolutionTableRows
            headerCells={tab?.supportTable?.thead.map(q => q.value)}
            rows={supportRows}
            title={tab.supportTitle}
          />
        </div>}


        {mobileTabletSupportRows && mobileTabletSupportRows.length > 0 && <div className="c-st_supportTableMobileTablet">
          <SolutionTableRows
            headerCells={tab?.supportTable?.thead.map(q => q.value)}
            rows={mobileTabletSupportRows}
            title={tab.supportTitle}
          />
        </div>}


        {additionalUsersRows && additionalUsersRows.length > 0 && <SolutionTableRows
          rows={additionalUsersRows}
          title={tab.additionalUsersTitle}
        />}
      </div>
      <ReactTooltip
        place={'top'}
        className="c-st__tooltipText"
        effect={'solid'}
      />
    </>
  )
};

export default SolutionTable;


