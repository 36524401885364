export const data = {
  "logo": {
    "id": 1466507,
    "alt": null,
    "name": "",
    "focus": null,
    "title": null,
    "filename": "https://a.storyblok.com/f/91820/x/a09dfc4dcb/acc-logotype-white.svg",
    "copyright": null,
    "fieldtype": "asset"
  },
  "slogan": "Making Data Intelligence Simpler",
  "component": "Footer",
  "copyright": "Copyright © 2020 Simplity. All Rights Reserved.",
  "link_boxes": [
    {
      "links": [
        {
          "link": {
            "id": "",
            "url": "https://www.google.com/",
            "linktype": "url",
            "fieldtype": "multilink",
            "cached_url": "https://www.google.com/"
          },
          "label": "Data Catalog and Business Glossary",
          "component": "Footer Link"
        },
        {
          "link": {
            "id": "",
            "url": "https://www.google.com/",
            "linktype": "url",
            "fieldtype": "multilink",
            "cached_url": "https://www.google.com/"
          },
          "label": "Model-drivean DWH Development and Automation",
          "component": "Footer Link"
        },
        {
          "link": {
            "id": "",
            "url": "https://www.google.com/",
            "linktype": "url",
            "fieldtype": "multilink",
            "cached_url": "https://www.google.com/"
          },
          "label": "Data-driven Business Process Visualization",
          "component": "Footer Link"
        },
        {
          "link": {
            "id": "",
            "url": "https://www.google.com/",
            "linktype": "url",
            "fieldtype": "multilink",
            "cached_url": "https://www.google.com/"
          },
          "label": "Data Quality Monitoring",
          "component": "Footer Link"
        },
        {
          "link": {
            "id": "",
            "url": "https://www.google.com/",
            "linktype": "url",
            "fieldtype": "multilink",
            "cached_url": "https://www.google.com/"
          },
          "label": "Reference Data Management",
          "component": "Footer Link"
        }
      ],
      "component": "Footer Link Box",
      "header_link": {
        "id": "",
        "url": "https://www.google.com/",
        "linktype": "url",
        "fieldtype": "multilink",
        "cached_url": "https://www.google.com/"
      },
      "header_label": "Solutions"
    },
    {
      "links": [
        {
          "link": {
            "id": "",
            "url": "https://www.google.com/",
            "linktype": "url",
            "fieldtype": "multilink",
            "cached_url": "https://www.google.com/"
          },
          "label": "Glossary",
          "component": "Footer Link"
        },
        {
          "link": {
            "id": "",
            "url": "https://www.google.com/",
            "linktype": "url",
            "fieldtype": "multilink",
            "cached_url": "https://www.google.com/"
          },
          "label": "Quality",
          "component": "Footer Link"
        },
        {
          "link": {
            "id": "",
            "url": "https://www.google.com/",
            "linktype": "url",
            "fieldtype": "multilink",
            "cached_url": "https://www.google.com/"
          },
          "label": "Reference",
          "component": "Footer Link"
        }
      ],
      "component": "Footer Link Box",
      "header_link": {
        "id": "",
        "url": "https://www.google.com/",
        "linktype": "url",
        "fieldtype": "multilink",
        "cached_url": "https://www.google.com/"
      },
      "header_label": "Platform"
    },
    {
      "links": [
        {
          "link": {
            "id": "",
            "url": "https://www.google.com/",
            "linktype": "url",
            "fieldtype": "multilink",
            "cached_url": "https://www.google.com/"
          },
          "label": "Get in Touch",
          "component": "Footer Link"
        }
      ],
      "component": "Footer Link Box",
      "header_link": {
        "id": "",
        "url": "https://www.google.com/",
        "linktype": "url",
        "fieldtype": "multilink",
        "cached_url": "https://www.google.com/"
      },
      "header_label": "Company"
    },
    {
      "links": [],
      "component": "Footer Link Box",
      "header_link": {
        "id": "",
        "url": "https://www.google.com/",
        "linktype": "url",
        "fieldtype": "multilink",
        "cached_url": "https://www.google.com/"
      },
      "header_label": "Business Glossary"
    },
    {
      "links": [],
      "component": "Footer Link Box",
      "header_link": {
        "id": "",
        "url": "https://www.google.com/",
        "linktype": "url",
        "fieldtype": "multilink",
        "cached_url": "https://www.google.com/"
      },
      "header_label": "Demo"
    }
  ],
  "bottom_links": [
    {
      "link": {
        "id": "",
        "url": "",
        "linktype": "story",
        "fieldtype": "multilink",
        "cached_url": ""
      },
      "label": "Cookies Policy",
      "component": "Footer Link"
    },
    {
      "link": {
        "id": "",
        "url": "",
        "linktype": "story",
        "fieldtype": "multilink",
        "cached_url": ""
      },
      "label": "Privacy Policy",
      "component": "Footer Link"
    },
    {
      "link": {
        "id": "",
        "url": "",
        "linktype": "story",
        "fieldtype": "multilink",
        "cached_url": ""
      },
      "label": "Security Policy",
      "component": "Footer Link"
    },
    {
      "link": {
        "id": "",
        "url": "",
        "linktype": "story",
        "fieldtype": "multilink",
        "cached_url": ""
      },
      "label": "SaaS License Agreement",
      "component": "Footer Link"
    },
    {
      "link": {
        "id": "",
        "url": "",
        "linktype": "story",
        "fieldtype": "multilink",
        "cached_url": ""
      },
      "label": "Personal data",
      "component": "Footer Link"
    }
  ]
}
