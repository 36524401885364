import React from 'react'
import { StoryblokTableType } from '../../../types/storyblokTypes';

type RTETableProps = {
  table: StoryblokTableType,
  notes: string
}

const RTETable = ({
                    notes,
                    table
                  }: RTETableProps) => {

  return (
    <div className="RTETable">
      <table>
        <thead>
        <tr>
          {table.thead.map((cell, index) => <th key={index}>{cell.value}</th>)}
        </tr>
        </thead>
        <tbody>
        {table.tbody.map((row, rowIndex) => <tr key={rowIndex}>
          {row.body.map((cell, cellIndex) => <td key={cellIndex}>{cell.value}</td>)}
        </tr>)}
        </tbody>
      </table>

      <div className="RTETable__notes">{notes}</div>
    </div>
  )
};

export default RTETable;
