import React from 'react'
import { StoryblokImageType } from '../../../types/storyblokTypes';
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import { render, StoryblokRichtext } from 'storyblok-rich-text-react-renderer-ts';

export type RichTextCardProps = {
  image: StoryblokImageType;
  title: string;
  text: StoryblokRichtext;
  tags: string[];
  tags2: string[];
  note: string;
}

const RichTextCard: React.FC<RichTextCardProps> = ({ image, text, tags2, tags, title, note }) => {

  return (
    <div className="c-richTextCard">
      <StoryblokImage image={image} className="c-richTextCard__image"/>
      <div className="c-richTextCard__textContainer">
        <h3 className="c-richTextCard__title">{title}</h3>
        <div className="c-richTextCard__text">{text ? render(text) : null}</div>
        <div className="c-richTextCard__tagsContainer">
          {tags.map(tag => <div className="c-richTextCard__tag c-richTextCard__tag--blue" key={tag}>{tag}</div>)}
        </div>
        <div className="c-richTextCard__tagsContainer">
          {tags2.map(tag => <div className="c-richTextCard__tag" key={tag}>{tag}</div>)}
        </div>
        <div className="c-richTextCard__note">{note}</div>
      </div>
    </div>
  )
};

export default RichTextCard