import React from 'react';

export const getMarketingConsent = () => {
  return (
    <>
      I {' '}
      <a href={'/marketing-consent.pdf'} target={'_blank'}>
        agree
      </a>{' '}
      to get Accurity data news
    </>
  )
}