import React from 'react'
import BackgroundLines from '../../backgroundLines/components/BackgroundLines'
import { renderBlogBody } from './renderBlogBody';

type BlogPostTextProps = {
  leadParagraph: string
  blogBody: string
}

const BlogPostText = ({ leadParagraph, blogBody }: BlogPostTextProps) => {
  return (
    <section className="blogPostText">
      <div className="blogPostContent container">
        <div className="blogPostText__leadParagraph">{leadParagraph}</div>
        {renderBlogBody(blogBody)}
      </div>
      <BackgroundLines
        images={{
          'right-top':
            'https://a.storyblok.com/f/91820/x/63e8afffd1/acc-blog-detail-lines-01.svg',
          'right-bottom':
            'https://a.storyblok.com/f/91820/x/2dea305840/acc-blog-detail-lines-03.svg',
          'left-bottom':
            'https://a.storyblok.com/f/91820/x/0ead41c3f1/acc-blog-detail-lines-02.svg',
        }}
      />
    </section>
  )
};

export default BlogPostText
