import React from 'react'
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import FiftyFiftyBlock, { FiftyFiftyBlockType, } from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlock';
import TestimonialSlider, { TestimonialSliderType } from '../../../components/testimonialsSlider/components/TestimonialSlider';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type SolutionsOverviewPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  solutionDetails: FiftyFiftyBlockType[];
  ctaBanner: { content: CtaBannerType };
  testimonialSlider: TestimonialSliderType[];
}

const SolutionsOverviewPage = ({ seoMeta, header, solutionDetails, ctaBanner, testimonialSlider }: SolutionsOverviewPageProps) => {
  return (
    <div className={'solutionsOverviewPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]}/>
      <div className={'container solutionsOverviewPage__contentContainer'}>
        {solutionDetails.map((block, index) => (
          <FiftyFiftyBlock
            key={index}
            {...block}
          />
        ))}
      </div>

      <CtaBanner
        banner={ctaBanner.content}
      />

      <div className={'container solutionsOverviewPage__contentContainer'}>
        <ErrorBoundary>
          <TestimonialSlider
            {...testimonialSlider[0]}
          />
        </ErrorBoundary>
        <BackgroundLines
          images={{
            'left': 'https://a.storyblok.com/f/91820/x/341d8f3260/acw-solution-overview-lines-01.svg',
            'right': 'https://a.storyblok.com/f/91820/x/970560b364/acw-solution-overview-lines-02.svg',
          }}
        />
      </div>
      <FooterContainer/>
    </div>
  )
};

export default SolutionsOverviewPage;
