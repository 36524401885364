import React from 'react'
import { StoryblokPricingSolution } from '../../../../types/storyblokTypes';
import Button from '../../../../components/buttons/components/Button';

export type SolutionTableProps = {
  tab: StoryblokPricingSolution;
}


const SolutionTableHeader = ({
                               tab
                             }: SolutionTableProps) => {

  return (
    <>
      <div className="c-st__row c-st__mostPopularTierRow">
        <div className="c-st__cell"/>
        <div className="c-st__cell">Most Popular</div>
        <div className="c-st__cell">Most Popular</div>
        <div className="c-st__cell">Most Popular</div>
      </div>
      <div className="c-st__row c-st__tierTitleRow">
        <div className="c-st__cell"/>
        <div className="c-st__cell">{tab.tier1Title}</div>
        <div className="c-st__cell">{tab.tier2Title}</div>
        <div className="c-st__cell">{tab.tier3Title}</div>
      </div>
      <div className="c-st__row c-st__tierDescriptionRow">
        <div className="c-st__cell"/>
        <div className="c-st__cell">{tab.tier1Description}</div>
        <div className="c-st__cell">{tab.tier2Description}</div>
        <div className="c-st__cell">{tab.tier3Description}</div>
      </div>
      <div className="c-st__row c-st__tierPriceRow">
        <div className="c-st__cell"/>
        <div className="c-st__cell">{tab.tier1Price}</div>
        <div className="c-st__cell">{tab.tier2Price}</div>
        <div className="c-st__cell">{tab.tier3Price}</div>
      </div>
      <div className="c-st__row c-st__ctaButtonRow">
        <div className="c-st__cell"/>
        <div className="c-st__cell">{tab.tier1ctaButton[0] && <Button {...tab.tier1ctaButton[0]}/>}</div>
        <div className="c-st__cell">{tab.tier2ctaButton[0] && <Button {...tab.tier2ctaButton[0]}/>}</div>
        <div className="c-st__cell">{tab.tier3ctaButton[0] && <Button {...tab.tier3ctaButton[0]}/>}</div>
      </div>
      <div className="c-st__row c-st__ctaButton2Row">
        <div className="c-st__cell"/>
        <div className="c-st__cell">{tab.tier1ctaButton2[0] && <Button {...tab.tier1ctaButton2[0]}/>}</div>
        <div className="c-st__cell">{tab.tier2ctaButton2[0] && <Button {...tab.tier2ctaButton2[0]}/>}</div>
        <div className="c-st__cell">{tab.tier3ctaButton2[0] && <Button {...tab.tier3ctaButton2[0]}/>}</div>
      </div>
    </>
  )
};

export default SolutionTableHeader;


