import React from 'react'
import { StoryblokIconLabelPairType } from '../../../types/storyblokTypes';
import StoryblokImage from '../../../components/storyblokImage/components/StoryblokImage';


const IconLabelPair = ({
                         label,
                         icon
                       }: StoryblokIconLabelPairType) => {


  return (
    <span className="c-iconLabelPair__wrapper">
      <StoryblokImage image={icon} className="c-iconLabelPair__icon"/>
      <span className="c-iconLabelPair__label">{label}</span>
    </span>
  )
};

export default IconLabelPair
