import React, { useState } from 'react'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import AccuritySeoMeta from '../../../components/AccuritySeoMeta'
import { OnDemandWebinarType } from '../../../types/storyblokTypes'
import FreeText from '../../../components/freeText/components/FreeText'
import WebinarHero from './WebinarHero';
import YoutubeOverlay from './YoutubeOverlay';
import CtaBanner from '../../../components/ctaBanner/components/CtaBanner';

const OnDemandWebinarPage = ({
                               webinarTitle,
                               component,
                               headerImage,
                               headerImageBigThumb,
                               presenters,
                               seoMeta,
                               tag,
                               text,
                               watchTime,
                               youtubeLink,
                               ctaBanner
                             }: OnDemandWebinarType) => {

  const [isYoutubeOpened, setIsYoutubeOpened] = useState(false);

  return (
    <div className="onDemandWebinarPage">
      <AccuritySeoMeta {...seoMeta} />

      <WebinarHero
        presenters={presenters}
        title={webinarTitle}
        headerImage={headerImage}
        tag={tag}
        date={watchTime}
        isOnDemand={true}
        onHeaderImageClick={() => setIsYoutubeOpened(true)}
      />

      <div className="container">
        <div className="onDemandWebinarPage__freeText">
          <FreeText
            richText={text}
            listStyle={'bullets'}
          />
        </div>
      </div>

      <CtaBanner
        banner={ctaBanner?.content}
      />

      <YoutubeOverlay
        link={youtubeLink}
        isOpen={isYoutubeOpened}
        closeYoutubeOverlay={() => setIsYoutubeOpened(false)}
      />

      <FooterContainer/>
    </div>
  )
};

export default OnDemandWebinarPage
