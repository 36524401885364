import React from 'react'
import TopNavContainer from '../../../navigation/components/TopNavContainer'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import AccuritySeoMeta, { AccuritySeoMetaType, } from '../../../components/AccuritySeoMeta'
import FreeText, { FreeTextType, } from '../../../components/freeText/components/FreeText'
import { FormLabels } from '../../../components/form/types'
import ZohoDemoSignUpForm from '../../../components/zohoForms/components/ZohoDemoSignUpForm'
import { StoryblokLogoStripeType } from '../../../types/storyblokTypes';
import { LogoStripe } from '../../../components/logoStripe/components/LogoStripe';
import DemoPresenterCarousel from '../../../components/demoPresenterCard/components/DemoPresenterCarousel';
import { DemoPresenterCardStoryblokType } from '../../../components/demoPresenterCard/components/DemoPresenterCard';

export type DemoPageProps = {
  seoMeta: AccuritySeoMetaType
  text: FreeTextType[]
  formLabels: FormLabels[]
  logoStripe: { content: StoryblokLogoStripeType };
  presentersTitle: string;
  presenters: { content: DemoPresenterCardStoryblokType }[];
}

const DemoPage = ({
                    seoMeta,
                    text,
                    formLabels,
                    logoStripe,
                    presenters,
                    presentersTitle
                  }: DemoPageProps) => {
  return (
    <div className="demoPage">
      <AccuritySeoMeta {...seoMeta} />
      <TopNavContainer hideTopBar={true}/>
      <div className="demoPage__contentContainer container">
        <div className="demoPage__freeTextColumn">
          <FreeText {...text[0]} />
        </div>
        <div className="demoPage__formColumn hashTarget" id="form">
          <ZohoDemoSignUpForm
            formLabels={formLabels[0]}
            formSource={'Accurity Demo Request'}
          />
        </div>
        <div className="demoPage__bottomBlocks">
          <div className="demoPage__trustedBy">
            {logoStripe?.content && <LogoStripe {...logoStripe.content} variant={'demo-page'}/>}
          </div>

          <div className="demoPage__presenters">
            <DemoPresenterCarousel
              title={presentersTitle}
              presenters={presenters?.map(q => q.content)}
            />
          </div>
        </div>
      </div>
      <FooterContainer/>
    </div>
  )
};

export default DemoPage
