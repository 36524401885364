import * as React from 'react';
import { TopNavStoryblokType } from '../../types/storyblokTypes';
import TopNav from '../components/TopNav';

export const TopNavPreview = (props: TopNavStoryblokType) => {
  return (
    <div className="topNavContainer topNavContainer--hasTopBar">
      <TopNav {...props} hideTopBar={false} inverted={false}/>
    </div>
  )
};