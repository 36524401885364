import * as React from 'react'
import classNames from 'classnames'

import { StoryblokImageType, StoryblokLinkType, } from '../../../types/storyblokTypes'
import FooterSocials from './FooterSocials'
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import FooterLinkBox from './FooterLinkBox';

export type FooterType = {
  logo: StoryblokImageType
  slogan: string
  link_boxes: LinkBoxType[]
  bottom_links: LinkWithLabel[]
  copyright: string
  contactTitle: string
  contactText: string
  isCompact: boolean
  facebookLink: StoryblokLinkType
  linkedInLink: StoryblokLinkType
  twitterLink: StoryblokLinkType
  youtubeLink: StoryblokLinkType
}

export type LinkBoxType = {
  header_label: string
  header_link: StoryblokLinkType
  links: LinkWithLabel[]
}

type LinkWithLabel = {
  label: string
  link: StoryblokLinkType
}

const Footer = ({
                  slogan,
                  logo,
                  bottom_links,
                  link_boxes,
                  copyright,
                  contactText,
                  contactTitle,
                  isCompact,
                  facebookLink,
                  linkedInLink,
                  twitterLink,
                  youtubeLink,
                }: FooterType) => {
  const renderComponentClassNames = () =>
    classNames({
      footer: true,
      'footer--compact': isCompact,
    });

  return (
    <div className={renderComponentClassNames()}>
      <div className="footer__curve"/>
      <div className="footer__content">
        <div className="container">
          <div className="footer__gridWrapper">
            <div className="footer__logoSloganWrapper">
              <StoryblokImage image={logo} className="footer__logo"/>
              <span className="footer__slogan">{slogan}</span>
            </div>

            {isCompact
              ? <div className="footer__contact">
                <div className="footer__linkBoxHeader">{contactTitle}</div>
                <div className="footer__contactText">{contactText}</div>
              </div>
              : link_boxes.map((box, i) => <FooterLinkBox box={box} key={i}/>)
            }
          </div>

          <FooterSocials
            facebook={facebookLink}
            linkedIn={linkedInLink}
            twitter={twitterLink}
            youtube={youtubeLink}
            showCapteraBadge={isCompact}
          />
          <div className="footer__divider"/>

          <div className="footer__bottomWrapper">
            <span className="footer__copyright">{copyright}</span>
            <span className="footer__bottomLinks">
              {bottom_links.map((link, idx) => (
                <a
                  href={link.link.url}
                  target="_blank"
                  className="footer__bottomLink"
                  key={idx}
                >
                  {link.label}
                </a>
              ))}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer
