import React from 'react';
import Head from './FilteringHead';
import Option from './FilterOption';
import { isFilterOptionEqual, ReturnValueFilteringHook } from '../types/types';

export type FilteringProps = {
  filters: ReturnValueFilteringHook['config'];
  activeFilters: ReturnValueFilteringHook['activeFilters'];
  onChange: ReturnValueFilteringHook['toggleFilterValue'];
  onReset: ReturnValueFilteringHook['resetFilters'];
};

export const Filtering: React.FC<FilteringProps> = ({ filters, onChange, activeFilters, onReset }: FilteringProps) => {

  return (
    <div className="c-filtering">
      <Head onReset={onReset} />
      <div className="c-filtering__filtersContainer">
        {Object.keys(filters).map(filterKey => {
          const filter = filters[filterKey];
          return <div className="c-filteringFilter" key={filterKey}>
            <div className="c-filteringFilter__label">{filter.label}</div>
            <ol className="c-filteringFilter__options">
              {filter.options.map((option) => {
                const isChecked = !!activeFilters[filterKey].find(candidate => isFilterOptionEqual(candidate, option));
                return <Option
                  checked={isChecked}
                  onChange={() => onChange(filterKey, option, !isChecked)}
                  label={option}
                  group={filter.label}
                  key={option}
                />
              })}
            </ol>
          </div>
        })}
      </div>
    </div>
  )
};
