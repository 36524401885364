import React from "react"

export const cutInLines = (string: string) => {
  const array = string?.split("<br />")

  const linedText = array?.map((str, i) => (
    <React.Fragment key={i}>
      {str}
      {array[i + 1] ? <br className="break" /> : ""}
    </React.Fragment>
  ))

  return linedText
}
