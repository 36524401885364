import React from 'react'

import AccuritySeoMeta, { AccuritySeoMetaType, } from '../../../components/AccuritySeoMeta'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock'
import CtaBanner, { CtaBannerType, } from '../../../components/ctaBanner/components/CtaBanner'
import CustomerStoryBanner, { CustomerStoryBannerType } from '../../../components/customerStoryBanner/components/CustomerStoryBanner'
import TestimonialForm from '../../../components/testimonialForm/components/TestimonialForm'
import { TestimonialType } from '../../../components/testimonialsSlider/components/Testimonial'
import { FormLabels } from '../../../components/form/types'
import Boxes from '../../../components/boxes/components/Boxes'
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

type ServicesPageProps = {
  seoMeta: AccuritySeoMetaType
  header: StorybookUniversalHeaderType[]
  title: string
  blocks: ThreeElementBlockType[]
  ctaBanner: { content: CtaBannerType }
  customerStory: { content: CustomerStoryBannerType }
  testimonialTitle: string
  testimonial: { content: TestimonialType }
  form: { content: FormLabels }
  formSource: string;
}

const ServicesPage = ({
                        seoMeta,
                        header,
                        title,
                        blocks,
                        ctaBanner,
                        customerStory,
                        testimonialTitle,
                        testimonial,
                        form,
                        formSource
                      }: ServicesPageProps) => {
  return (
    <div className="servicesPage lightGreyBcg">
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />
      <Boxes title={title} blocks={blocks}/>

      <CtaBanner
        banner={ctaBanner?.content}
      />

      {customerStory && (
        <div className="container callToAction__section">
          <CustomerStoryBanner {...customerStory.content} />
        </div>
      )}
      <TestimonialForm
        title={testimonialTitle}
        testimonial={testimonial}
        form={form}
        formSource={formSource}
      />
      <FooterContainer/>
    </div>
  )
};

export default ServicesPage
