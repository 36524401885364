import * as React from 'react'
import classNames from 'classnames'

export type TopNavDropdownWrapperProps = {
  isVisible: boolean;
  className?: string;
}

const TopNavDropdownWrapper = ({
                                 isVisible,
                                 children,
                                 className
                               }: React.PropsWithChildren<TopNavDropdownWrapperProps>) => {

  const mainClassName = classNames('topNavDropdown', className, {
    'd-flex': isVisible,
  });

  return (
    <div className={mainClassName}>
      <div className="topNavDropdown__arrow"/>
      {children}
    </div>
  )
};

export default TopNavDropdownWrapper
