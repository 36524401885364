import React from 'react'
import SolutionTableRowComponent, { SolutionTableRow } from './SolutionTableRow';

export type SolutionTableRowsProps = {
  title?: string;
  isCollapsible?: boolean;
  headerCells?: React.ReactNode[];
  rows?: SolutionTableRow[];
  onDemandColumnLabel?: string;
}

const SolutionTableRows = ({
                             title,
                             headerCells,
                             isCollapsible,
                             rows,
                             onDemandColumnLabel
                           }: SolutionTableRowsProps) => {

  return (
    <>
      <div className="c-st__row c-st__titleRow c-st__row--border">
        <div className="c-st__cell">
          {title && <h3>{title}</h3>}
        </div>
        <div className="c-st__cell"/>
        <div className="c-st__cell"/>
        <div className="c-st__cell"/>
      </div>

      <div className="c-st__rowsWrapper">
        {headerCells && headerCells.length > 0 && <div className="c-st__row c-st__row--header c-st__row--border">
          {headerCells.map((cell, index) => <div className="c-st__cell" key={index}>{cell}</div>)}
        </div>}

        {rows?.map((row, index) => <SolutionTableRowComponent key={index} row={row} isCollapsible={isCollapsible}/>)}

        {onDemandColumnLabel && <div className="c-st__stickyColumnWrapper">
          <div className="c-st__stickyColumn">
            {onDemandColumnLabel}
          </div>
        </div>}
      </div>
    </>
  )
};

export default SolutionTableRows;


