import React from 'react'

import StoryblokLink from '../../../components/storyblokLink/components/StoryblokLink'

import { StoryblokLinkType } from '../../../types/storyblokTypes'

export type TopNavDropdownMainProps = {
  label: string
  link: StoryblokLinkType
}

const NavDropdownMainLink = ({ label, link }: TopNavDropdownMainProps) => {
  return (
    <li className="topNavDropdown__menuItem">
      <StoryblokLink to={link} className="topNavDropdown__main">
        {label}
      </StoryblokLink>
    </li>
  )
}

export default NavDropdownMainLink
