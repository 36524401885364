import React from 'react'
import AccuritySeoMeta, { AccuritySeoMetaType, } from '../../../components/AccuritySeoMeta'
import CtaBanner, { CtaBannerType, } from '../../../components/ctaBanner/components/CtaBanner'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import { StoryblokButtonType } from '../../../types/storyblokTypes'
import SubHeading from '../../../components/subHeading/components/SubHeading'
import FreeText, { FreeTextType, } from '../../../components/freeText/components/FreeText'
import Button from '../../../components/buttons/components/Button'
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines'
import ThreeElementBlockContainer from '../../../components/threeElementBlock/components/ThreeElementBlockContainer'
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock'
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type BusinessGlossaryPageProps = {
  seoMeta: AccuritySeoMetaType
  header: StorybookUniversalHeaderType[]
  ctaBanner: { content: CtaBannerType }
  title: string
  useCases: FreeTextType[]
  titleFeatures: string
  upcomingFeatures: FreeTextType[]
  text: string
  ctaButton: StoryblokButtonType[]
  newFeaturesTitle: string
  newFeatures: ThreeElementBlockType[]
}

const BusinessGlossaryPage = ({
                                seoMeta,
                                header,
                                ctaBanner,
                                title,
                                useCases,
                                titleFeatures,
                                upcomingFeatures,
                                text,
                                ctaButton,
                                newFeatures,
                                newFeaturesTitle,
                              }: BusinessGlossaryPageProps) => {
  return (
    <div className={'businessGlossaryPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />

      {/*New Features*/}
      <section className={'businessGlossaryPage__newFeatures container'}>
        <SubHeading title={newFeaturesTitle}/>
        <ThreeElementBlockContainer
          blocksPerRow={4}
          blocks={newFeatures}
          centered
        />
      </section>

      <CtaBanner
        banner={ctaBanner.content}
        addMargin={true}
      />

      <div className={'container businessGlossaryPage__contentContainer'}>
        <div className={'businessGlossaryPage__useCasesSection'}>
          <SubHeading title={title}/>
          <div
            className={
              'businessGlossaryPage__freeTextContainer businessGlossaryPage__freeTextContainer--right'
            }
          >
            {useCases.map((useCase, index) => (
              <FreeText key={index} {...useCase} />
            ))}
          </div>
        </div>
        <div className={'businessGlossaryPage__upcomingFeaturesSection'}>
          <SubHeading title={titleFeatures}/>
          <div
            className={
              'businessGlossaryPage__freeTextContainer businessGlossaryPage__freeTextContainer--left'
            }
          >
            {upcomingFeatures.map((upcomingFeature, index) => (
              <FreeText key={index} {...upcomingFeature} />
            ))}
          </div>
        </div>
        <div className={'businessGlossaryPage__showMeMoreSection'}>
          <SubHeading paragraph={text}/>
          <Button {...ctaButton[0]} />
        </div>
      </div>
      <BackgroundLines
        images={{
          left:
            'https://a.storyblok.com/f/91820/x/3ba4840f08/acw-business-glossary-lines-01.svg',
          right:
            'https://a.storyblok.com/f/91820/x/15ffc3c9de/acw-business-glossary-lines-02.svg',
        }}
      />
      <FooterContainer/>
    </div>
  )
};

export default BusinessGlossaryPage
