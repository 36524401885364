import React from 'react'
import DemoPresenterCard, { DemoPresenterCardStoryblokType } from './DemoPresenterCard';
import Slider, { Settings } from 'react-slick';
import { SlickButtonFix } from '../../resourceCardContainer/components/ResourceCardsContainer';

export type DemoPresenterCarouselProps = {
  title: string
  presenters: DemoPresenterCardStoryblokType[];
}

const DemoPresenterCarousel = ({
                                 presenters = [],
                                 title
                               }: DemoPresenterCarouselProps) => {

  const settings: Settings = {
    dots: presenters.length > 1,
    arrows: presenters.length > 1,
    nextArrow: <SlickButtonFix>
      <div className="demoPresenterCarousel__rightArrow">
        <div className="demoPresenterCarousel__arrow"/>
      </div>
    </SlickButtonFix>,
    prevArrow: <SlickButtonFix>
      <div className="demoPresenterCarousel__leftArrow">
        <div className="demoPresenterCarousel__arrow"/>
      </div>
    </SlickButtonFix>,
  };

  return (
    <div className="demoPresenterCarousel">
      <h2 className="demoPresenterCarousel__title">{title}</h2>
      <Slider {...settings} className="demoPresenterCarousel__cardsContainer">
        {presenters.map((presenter, index) => <div className="demoPresenterCarousel__cardWrapper" key={index}>
          <DemoPresenterCard {...presenter}/>
        </div>)}
      </Slider>
    </div>
  );
};

export default DemoPresenterCarousel;