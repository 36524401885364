import { useFiltering } from '../hooks/useFiltering';

export type FilterOption = string;

export type FilterConfig = {
  label: string;
  options: FilterOption[];
  preselectedOptions?: FilterOption[];
  multiSelection?: boolean;
}

export type Filters = {
  [key: string]: FilterConfig;
}

export type ReturnValueFilteringHook = ReturnType<typeof useFiltering>;

export const isFilterOptionEqual = (a: FilterOption, b: FilterOption) => a === b;
