import React from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import scrollTo from 'gatsby-plugin-smoothscroll'

import { StoryblokLinkType } from '../../../types/storyblokTypes'
import { getUrlFromLink } from '../../../utils/linkUtils'

export type ButtonSeverity =
  | 'primary'
  | 'primary-outlined'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-small'
  | 'link'

export type ButtonProps = {
  severity: ButtonSeverity
  label?: string
  link?: StoryblokLinkType
  fullWidth?: boolean
  onClick?: () => void
  className?: string
  isScroll?: boolean
  isCallToAction?: boolean
}

const Button = ({
                  severity,
                  label,
                  link,
                  fullWidth,
                  onClick,
                  className,
                  isScroll,
                  isCallToAction
                }: ButtonProps) => {
  const url = link ? getUrlFromLink(link) : undefined;
  if (isCallToAction) {
    className = (className || '') + ' button--callToAction';
  }

  const handleClick = () => {
    if (onClick) {
      onClick()
    } else if (url) {
      // keeping only as a heritage
      navigate(url)
    }
  }

  const handleScrollClick = () => {
    if (isScroll && link?.url) scrollTo(link?.url)
  }

  let finalButton
  if (isScroll) {
    finalButton = (
      <div
        className={classNames(
          `button button--scroll button--${severity}`,
          { 'button--fullWidth': fullWidth },
          className
        )}
        onClick={() => handleScrollClick()}
      >
        {label}
      </div>
    )
  } else if (url) {
    finalButton = (
      <a
        href={url}
        className={classNames(
          `button button--${severity}`,
          { 'button--fullWidth': fullWidth },
          className
        )}
      >
        {label}
      </a>
    )
  } else {
    finalButton = (
      <div
        className={classNames(
          `button button--${severity}`,
          { 'button--fullWidth': fullWidth },
          className
        )}
        onClick={handleClick}
      >
        {label}
      </div>
    )
  }

  return finalButton
}

export default Button
