import React from 'react'

import StoryblokImage from '../../storyblokImage/components/StoryblokImage'

import { TestimonialType } from '../../testimonialsSlider/components/Testimonial'
import { FormLabels } from '../../form/types'
// @ts-ignore
import guyImage from '../../../images/testimonialForm-guy.png'
import ZohoBecomePartnerForm from '../../zohoForms/components/BecomePartnerForm';
import { BecomePartnerFormLabelsType } from '../../../types/storyblokTypes';
import ZohoContactUsForm from '../../zohoForms/components/ZohoContactUsForm';

type TestimonialFormProps = {
  title: string
  testimonial: { content: TestimonialType }
  form?: { content: FormLabels | BecomePartnerFormLabelsType }
  formSource?: string;
}

const TestimonialForm = ({
                           title,
                           testimonial,
                           form,
                           formSource
                         }: TestimonialFormProps) => {
  return (
    <section className="testimonialForm">
      <div className="container">
        <div className="testimonialForm__container">
          <div className="testimonialForm__testimonial">
            <h2 className="testimonialForm__title">{title}</h2>
            <p className="testimonialForm__text">
              {testimonial?.content?.text}
            </p>
            <p className="testimonialForm__name">
              {testimonial?.content?.name},
            </p>
            <p className="testimonialForm__position">
              {testimonial?.content?.jobTitle}
            </p>
            <StoryblokImage
              image={testimonial?.content?.logo}
              className="testimonialForm__logo"
            />
            <img
              src={guyImage}
              className="testimonialForm__guy"
              alt="accurity customer"
            />
          </div>
          {form && <div className="testimonialForm__form" id="form">
            {getFormComponent(form, formSource)}
          </div>}
        </div>
      </div>
    </section>
  )
};

const getFormComponent = (form: TestimonialFormProps['form'], formSource?: string) => {
  switch (form?.content?.component) {
    case 'Become Partner Form':
      return (
        <ZohoBecomePartnerForm
          formLabels={form.content}
          formSource={formSource || 'Become Partner Form'}
        />
      );
    case 'getInTouchForm':
      return (
        <ZohoContactUsForm
          successRedirectUrl={'/platform/services/thank-you/'}
          formLabels={form.content}
          formSource={formSource || 'Accurity Services'}
        />
      );
    default:
      return null;
  }
};

export default TestimonialForm
