import React from 'react'
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import { BlogAuthorType } from '../../../types/storyblokTypes';

type BlogPostAuthorBioProps = {
  authors: BlogAuthorType[]
}

const BlogPostAuthorBio = ({ authors }: BlogPostAuthorBioProps) => {
  return (
    <section className="container">
      <div className="blogPostAuthorBio">
        {authors.map((author, idx) => (
          <div className="blogPostAuthorBio__wrapper" key={idx}>
            <StoryblokImage
              image={author.profilePhoto}
              className={'blogPostAuthorBio__photo'}
            />
            <div className="blogPostAuthorBio__nameAndBioWrapper">
              <div className="blogPostAuthorBio__name">{author.name}</div>
              <div className="blogPostAuthorBio__bio">{author.bio}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
};

export default BlogPostAuthorBio;
