import React from "react"
import { navigate } from "gatsby"
import TopNavContainer from "../../../navigation/components/TopNavContainer"
import FooterContainer from "../../../components/footer/components/FooterContainer"
import Button from "../../../components/buttons/components/Button"
import BackgroundLines from "../../../components/backgroundLines/components/BackgroundLines"

export type Page404Props = {
  text: string
  buttonLabel: string
}

const Page404 = ({ text, buttonLabel }: Page404Props) => {
  const homeLink = {
    id: "",
    url: "",
    linktype: "story",
    cached_url: "/",
  }

  return (
    <div className={"page404"}>
      <TopNavContainer />
      <div className="container page404__contentContainer">
        <h1 className="page404__title">404</h1>
        <p className="page404__text">{text}</p>
        <Button severity={"primary"} label={buttonLabel} link={homeLink} />
      </div>
      <BackgroundLines
        images={{
          right:
            "https://a.storyblok.com/f/91820/x/a2e5d9f2ac/404-page-lines.svg",
        }}
      />
      <FooterContainer />
    </div>
  )
}

export default Page404
