import React, { useState } from 'react'
import classNames from 'classnames';
// @ts-ignore
import ArrowIcon from '../../../../images/icons/arrow.svg';
import ReactTooltip from 'react-tooltip';
import SolutionTableTooltip from './SolutionTableTooltip';

export type SolutionTableRow = {
  cells: {
    value: React.ReactNode,
    tooltip?: string
  }[];
  children?: SolutionTableRow[];
  expanded?: boolean;
}

export type SolutionTableRowProps = {
  row: SolutionTableRow;
  isCollapsible?: boolean;
}

const SolutionTableRowComponent = ({
                                     isCollapsible,
                                     row
                                   }: SolutionTableRowProps) => {

  const [isExpanded, setIsExpanded] = useState(row.expanded || false);
  const hasChildren = row.children && row.children.length > 0;

  const handleExpand = () => {
    if (row.children) {
      setIsExpanded(!isExpanded);
      setTimeout(() => ReactTooltip.rebuild(), 10);
    }
  };

  const getRowClassName = () => classNames('c-st__row c-st__row--border', {
    'c-st__row--expandable': isCollapsible && hasChildren,
    'c-st__row--expanded': isExpanded
  });

  return (
    <>
      <div className={getRowClassName()} onClick={handleExpand}>
        <div className="c-st__cell">
          {isCollapsible && <span className="c-st__rowExpandIconContainer">
            {hasChildren && <ArrowIcon className="c-st__rowExpandIcon"/>}
          </span>}
          <span>
            {row.cells[0].value}
            <SolutionTableTooltip tooltip={row.cells[0].tooltip}/>
          </span>
        </div>
        {row.cells.slice(1).map((cell, index) => <div className="c-st__cell" key={index}>
          {cell.value}
          <SolutionTableTooltip tooltip={cell.tooltip}/>
        </div>)}
      </div>
      {isExpanded && row.children && row.children.map((childRow, index) => <SolutionTableRowComponent
        isCollapsible={isCollapsible}
        key={index}
        row={childRow}
      />)}
    </>
  )
};

export default SolutionTableRowComponent;


