import React from 'react'
import { UniversalHeader } from '../../components/header/components/UniversalHeader';
import ResourceCardContainer from '../../components/resourceCardContainer/components/ResourceCardsContainer';
import AccuritySeoMeta from '../../components/AccuritySeoMeta';
import FreeText from '../../components/freeText/components/FreeText';
import { InfographicType } from '../../types/storyblokTypes';
import FooterContainer from '../../components/footer/components/FooterContainer';
import StoryblokImage from '../../components/storyblokImage/components/StoryblokImage';
import { isEmpty } from '../../utils/richTextUtils';

const InfographicPage = ({
                           blogCards,
                           blogCardsTitle,
                           header,
                           seoMeta,
                           text,
                           bodyImage
                         }: InfographicType) => {
  return (
    <div className={'infographicPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />
      {(!isEmpty(text as any) || (bodyImage && bodyImage.filename)) && <div className="infographicPage__freeText container sectionMargins">
        {text && <FreeText
          richText={text}
          listStyle={'bullets'}
        />}

        {bodyImage && <StoryblokImage
          image={bodyImage}
          className={'infographicPage__freeTextImage'}
        />}
      </div>}
      <section>
        <ResourceCardContainer
          resources={blogCards}
          title={blogCardsTitle}
        />
      </section>
      <FooterContainer/>
    </div>
  )
};

export default InfographicPage
