import * as React from "react"

export const useIsMenuFloating = (noBar?: boolean) => {
  const [isMenuFloating, setIsMenuFloating] = React.useState(false)
  const isMenuFloatingRef = React.useRef(isMenuFloating)

  React.useEffect(() => {
    isMenuFloatingRef.current = isMenuFloating
  }, [isMenuFloating])

  const onScrollResize = React.useCallback(() => {
    const breakpoint = window.innerWidth < 1200 || noBar ? 0 : 49
    const shouldBeFloating = window.pageYOffset > breakpoint
    if (shouldBeFloating != isMenuFloatingRef.current) {
      setIsMenuFloating(shouldBeFloating)
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener("scroll", onScrollResize)
    window.addEventListener("resize", onScrollResize)
    return () => {
      window.removeEventListener("scroll", onScrollResize)
      window.removeEventListener("resize", onScrollResize)
    }
  }, [])

  return isMenuFloating
}
