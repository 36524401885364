import React from 'react';
import classNames from 'classnames';
import { RoadmapCardType } from '../types';

export type RoadmapCardProps = {} & RoadmapCardType;

const RoadmapCard = (props: RoadmapCardProps) => {
  return (
    <div className={classNames('roadmapCard')}>

      <div className={classNames('roadmapCard__category')}>
        {props.category}
      </div>

      <h3 className={classNames('roadmapCard__title')}>
        {props.title}
      </h3>

      <p className={classNames('roadmapCard__description')}>
        {props.description}
      </p>

      <ul className="roadmapCard__tags">
        {props.tiers.map(tier => <li>{tier}</li>)}
      </ul>
    </div>
  );
};

export default RoadmapCard;
