import React from 'react';
import CtaBanner, { CtaBannerType } from './CtaBanner';

const CtaBannerPreview = (banner: CtaBannerType) => {

  return (
    <CtaBanner banner={banner}/>
  );
};

export default CtaBannerPreview;
