import React from 'react'

import TopNavContainer from '../../../navigation/components/TopNavContainer'
import Button from '../../buttons/components/Button'
import StoryblokImage from '../../storyblokImage/components/StoryblokImage'
import { StorybookUniversalHeaderType } from '../../header/components/UniversalHeader';
import { render } from 'storyblok-rich-text-react-renderer-ts';

type HeroProps = {} & StorybookUniversalHeaderType;

const Hero = ({
                ctaButtons,
                headline,
                leadParagraph,
                headerMedia
              }: HeroProps) => {
  const buttons = ctaButtons?.map((button, idx) => (
    <React.Fragment key={idx}>
      {button?.label && (
        <Button
          label={button.label}
          severity={button.severity}
          link={button.link ? button.link : undefined}
          isScroll={button.isScroll}
        />
      )}
    </React.Fragment>
  ));

  return (
    <section className="c-hero">
      <TopNavContainer inverted={true} reducedMenu={false}/>
      <div className="container">
        <div className="c-hero__content">
          <div className="c-hero__text">
            <h1 className="c-hero__headline">{headline}</h1>
            <p className="c-hero__paragraph">{render(leadParagraph)}</p>
            <div className="c-hero__ctaButtons">{buttons}</div>
          </div>
          {headerMedia && headerMedia.filename && (
            <div className="c-hero__imageWrapper">
              <StoryblokImage image={headerMedia} className="c-hero__image"/>
            </div>
          )}
        </div>
      </div>
    </section>
  )
};

export default Hero
