import React from 'react'
import StoryblokImage from '../../storyblokImage/components/StoryblokImage'
import { StoryblokImageType, StoryblokLinkType } from '../../../types/storyblokTypes'
import ReactMarkdown from 'react-markdown'
import { render } from 'storyblok-rich-text-react-renderer-ts'
import classNames from 'classnames'
import checkMark from '../../../images/checkMark.svg'
import crossMark from '../../../images/crossMark.svg'

export type FreeTextType = {
  title?: string
  h2Title?: string
  leadParagraph?: string
  richText?: string
  mediaAsset?: StoryblokImageType
  ctaButton?: string
  ctaLink?: StoryblokLinkType
  markdown?: string
  listStyle?: 'crosses' | 'ticks' | 'bullets'
  className?: string;
}

const FreeText = ({
                    title,
                    h2Title,
                    leadParagraph,
                    richText,
                    mediaAsset,
                    ctaButton,
                    ctaLink,
                    markdown,
                    listStyle,
                    className
                  }: FreeTextType) => {
  const renderRichText = (richBox: any) => {
    const text = render(richBox, {
      nodeResolvers: {
        list_item: children => (
          <li>
            {listStyle === 'ticks' && (
              <img
                src={checkMark}
                className="freeText__checkIcon"
                alt="checkmark"
              />
            )}
            {listStyle === 'crosses' && (
              <img
                src={crossMark}
                className="freeText__crossIcon"
                alt="crossMark"
              />
            )}
            {children}
          </li>
        ),
      },
    })

    return text
  }
  const renderedText = richText ? renderRichText(richText) : null

  const renderClasses = () =>
    classNames('freeText', className, {
      'freeText--ticks': listStyle === 'ticks',
      'freeText--crosses': listStyle === 'crosses',
      'freeText--bullets': listStyle === 'bullets',
    })

  return (
    <div className={renderClasses()}>
      {title ? <h1 className="freeText__title">{title}</h1> : null}
      {h2Title ? <h2 className="freeText__h2Title">{h2Title}</h2> : null}
      {leadParagraph ? (
        <p className="freeText__leadParagraph">{leadParagraph}</p>
      ) : null}
      {mediaAsset?.filename ? (
        <div className="freeText__mediaAssetContainer">
          <StoryblokImage image={mediaAsset}/>
        </div>
      ) : null}
      {richText ? (
        <div className="freeText__richText">{renderedText}</div>
      ) : null}
      {markdown ? (
        <ReactMarkdown className={'freeText__markdown'}>
          {markdown}
        </ReactMarkdown>
      ) : null}
    </div>
  )
}

export default FreeText
