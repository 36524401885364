import React from 'react'
import Button from '../../../components/buttons/components/Button'
import fetchingGif from '../../../images/fetching.gif'

export type SubmitButtonProps = {
  doSubmit: () => void;
  isFetching: boolean;
  label?: string;
};

const SubmitButton = ({ doSubmit, isFetching, label }: SubmitButtonProps) => {

  if (isFetching) {
    return (
      <div className="form__fetchingBox">
        <img src={fetchingGif}/>
      </div>
    );
  } else {
    return (
      <Button
        severity={'primary'}
        label={label}
        fullWidth={true}
        onClick={doSubmit}
      />
    );
  }
};

export default SubmitButton;
