import React from 'react'
import TextField from '../../../components/form/components/TextField'
import FormRow from '../../../components/form/components/FormRow'
import CheckBox from '../../../components/form/components/CheckBox'
import { useZohoFormLogic, ZohoFormFields } from '../../form/zohoLogic'
import { BecomePartnerFormLabelsType } from '../../../types/storyblokTypes'
import ReactMarkdown from 'react-markdown'
import SubmitButton from '../../form/components/SubmitButton';
import TextArea from '../../form/components/TextArea';
import MultipleCheckBoxes, { OPTIONS_SEPARATOR } from '../../form/components/MultipleCheckBoxes';
import { ZOHO_FORM_IDS } from '../zohoConfig';

const ZOHO_PARTNERS_SUBMIT_URL =
  `https://form.accurity.ai/simplitygroup/form/${ZOHO_FORM_IDS.PARTNERS}/htmlRecords/submit`;

export type BecomePartnerFormType = {
  firstName: string
  lastName: string
  company: string
  email: string
  country: string
  partnershipType: string
  interest: string
  marketing: boolean
}

export type ZohoWhitepaperFormProps = {
  formSource: string;
  formLabels: BecomePartnerFormLabelsType;
}

const ZohoBecomePartnerForm = ({
                                 formSource,
                                 formLabels
                               }: ZohoWhitepaperFormProps) => {

  const zohoFields: ZohoFormFields<BecomePartnerFormType> = {
    firstName: {
      zohoFieldName: 'SingleLine7',
      label: formLabels?.firstNameLabel,
      initialValue: '',
      required: true,
    },
    lastName: {
      zohoFieldName: 'SingleLine8',
      label: formLabels?.lastNameLabel,
      initialValue: '',
      required: true,
    },
    company: {
      zohoFieldName: 'SingleLine9',
      label: formLabels?.companyLabel,
      initialValue: '',
      required: true,
    },
    email: {
      zohoFieldName: 'Email',
      label: formLabels?.emailLabel,
      initialValue: '',
      required: true,
      runEmailValidation: true,
    },
    country: {
      zohoFieldName: 'SingleLine10',
      label: formLabels?.countryLabel,
      initialValue: '',
      required: true,
    },
    partnershipType: {
      zohoFieldName: 'SingleLine11',
      label: formLabels?.partnerTypeLabel,
      initialValue: '',
      required: true,
      customValidation: {
        errorMessage: formLabels.partnerTypeErrorMessage,
        validationFn: (value: string) => value.split(OPTIONS_SEPARATOR).filter(q => !!q).length > 0
      }
    },
    interest: {
      zohoFieldName: 'MultiLine',
      label: formLabels?.interestLabel,
      initialValue: '',
    },
    marketing: {
      zohoFieldName: 'DecisionBox',
      initialValue: false,
      required: false,
      label: formLabels?.marketingLabel,
    },
  };

  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
  } = useZohoFormLogic<BecomePartnerFormType>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_PARTNERS_SUBMIT_URL,
    successRedirectUrl: formLabels?.successRedirectUrl,
    submitErrorMessage: formLabels?.submitErrorMessage || '',
    zohoFormFields: zohoFields,
  });

  return (
    <div className="form__container">
      <h3 className="form__headline">{formLabels?.titleForm}</h3>
      <FormRow>
        <TextField
          name="firstName"
          label={formLabels?.firstNameLabel}
          value={values.firstName}
          setValue={setValue}
          error={errors.firstName}
          clearError={clearError}
        />
        <TextField
          name="lastName"
          label={formLabels?.lastNameLabel}
          value={values.lastName}
          setValue={setValue}
          error={errors.lastName}
          clearError={clearError}
        />
      </FormRow>
      <TextField
        name="company"
        label={formLabels?.companyLabel}
        value={values.company}
        setValue={setValue}
        error={errors.company}
        clearError={clearError}
      />
      <TextField
        name="email"
        label={formLabels?.emailLabel}
        value={values.email}
        setValue={setValue}
        error={errors.email}
        clearError={clearError}
      />
      <TextField
        name="country"
        label={formLabels?.countryLabel}
        value={values.country}
        setValue={setValue}
        error={errors.country}
        clearError={clearError}
      />
      <MultipleCheckBoxes
        name={'partnershipType'}
        value={values.partnershipType || ''}
        label={formLabels.partnerTypeLabel}
        setValue={setValue}
        options={formLabels?.partnerTypes.split('\n')}
        error={errors.partnershipType}
        clearError={clearError}
      />
      <TextArea
        name="interest"
        label={formLabels?.interestLabel}
        value={values.interest}
        setValue={setValue}
        error={errors.interest}
        clearError={clearError}
      />
      <div className="form__checkBoxContainer">
        <CheckBox
          name="marketing"
          label={
            <ReactMarkdown
              renderers={{
                link: (props: any) => <a href={props.href} target="_blank">{props.children}</a>
              }}
            >
              {zohoFields.marketing.label || ''}
            </ReactMarkdown>
          }
          value={values.marketing}
          setValue={setValue}
        />
      </div>
      <div className="form__buttonContainer">
        <SubmitButton
          isFetching={isFetching}
          label={formLabels?.submitLabel}
          doSubmit={doSubmit}
        />
        {!!submitError && <p className="form__submit-error">{submitError}</p>}
      </div>
    </div>
  )
};

export default ZohoBecomePartnerForm
