import React from 'react'
import classNames from 'classnames'
import { StoryblokLinkType, } from '../../types/storyblokTypes'
import { useIsMenuFloating } from './useIsMenuFloating'
import TopNavLogo from './TopNavLogo';

export type TopNavLandingProps = {
  logo_link: StoryblokLinkType
  inverted?: boolean
}

const LandingTopNav = ({
                         logo_link,
                         inverted,
                       }: TopNavLandingProps) => {
  // set noBar as parameter
  const isMenuFloating = useIsMenuFloating(true);

  const renderClassNames = () =>
    classNames({
      topNav: true,
      'topNav--landingPage': true,
      'topNav--floating': isMenuFloating,
      'topNav--inverted': inverted && !isMenuFloating,
    });

  return (
    <div className={renderClassNames()}>
      <div className="topNav__inner container">
        <TopNavLogo logo_link={logo_link}/>
      </div>
    </div>
  )
};

export default LandingTopNav
