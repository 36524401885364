import React from 'react'
import classNames from 'classnames';
import ThreeElementBlock, { ThreeElementBlockType } from './ThreeElementBlock';

export type ThreeElementBlockContainerType = {
  blocksPerRow?: number;
  blocks: ThreeElementBlockType[];
  centered?: boolean;
}

const ThreeElementBlockContainer = ({ blocksPerRow, blocks, centered }: ThreeElementBlockContainerType) => {

  let cssModifier;
  if (blocksPerRow === 4) {
    cssModifier = 'threeElementBlockContainer--fourInRow';
  } else if (blocksPerRow === 3) {
    cssModifier = 'threeElementBlockContainer--threeInRow';
  }

  let centerModifier;
  if ( centered ) centerModifier = 'threeElementBlockContainer--centered';

  return (
    <>
      <div className={classNames('threeElementBlockContainer', cssModifier, centerModifier)}>
        {blocks?.map((block, index) =>
          <ThreeElementBlock
            key={index}
            { ...block }
          />
        )}
      </div>
    </>
  );
};

export default ThreeElementBlockContainer;
