import React from 'react'
import { StoryblokButtonType, StoryblokImageType } from '../../../types/storyblokTypes'
import Button from '../../buttons/components/Button';

export type CtaBannerPOCType = {
  component: 'CTA Banner POC';
  title?: string;
  paragraph?: string;
  price?: string;
  ctaButton: StoryblokButtonType[];
  image?: StoryblokImageType;
  notes?: string;
}

const CtaBannerPOC = ({
                        title,
                        paragraph,
                        ctaButton,
                        notes,
                        image,
                        price
                      }: CtaBannerPOCType) => {

  const notesParts = notes?.split('\n');

  return (
    <div className="ctaBannerPOC">
      <div className="ctaBannerPOC__image" style={{ 'backgroundImage': `url("${image?.filename}")` }}/>
      <div className="ctaBannerPOC__textContent">
        <div className="ctaBannerPOC__leftTextContent">
          {title && <h3 className="ctaBannerPOC__title">{title}</h3>}
          {paragraph && <div className="ctaBannerPOC__text">{paragraph}</div>}
          {price && <div className="ctaBannerPOC__price">{price}</div>}
        </div>
        <div className="ctaBannerPOC__rightTextContent">
          {notesParts && <ul className="ctaBannerPOC__notes">
            {notesParts.map((note, idx) => <li key={idx}>{note}</li>)}
          </ul>}
        </div>
        {ctaButton[0] && <Button {...ctaButton[0]} className={'ctaBannerPOC__ctaButton'}/>}
      </div>
    </div>
  )
};

export default CtaBannerPOC
