import React from 'react';
import { render } from 'storyblok-rich-text-react-renderer-ts'

export type SubHeadingType = {
  title?: string;
  paragraph?: any;
}

const SubHeading = ({ title, paragraph }: SubHeadingType) => {
  const text = paragraph ? <div className="subHeading__text">{render(paragraph)}</div> : null

  return (
    <div className="subHeading__container">
      <div className={'subHeading'}>
        {title ? <h2>{title}</h2> : null}
        {text}
      </div>
    </div>
  );
};

export default SubHeading;
