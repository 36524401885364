import React from 'react'
import { StoryblokButtonType, StoryblokImageType, } from '../../../types/storyblokTypes'
import StoryblokImage from '../../../components/storyblokImage/components/StoryblokImage';
import FreeText from '../../../components/freeText/components/FreeText';
import Button from '../../../components/buttons/components/Button';

export type FeaturesProps = {
  featuresTitle: string;
  featuresSubtitle: string;
  featuresDescription: string;
  featuresImage: StoryblokImageType;
  featuresCtaButton: StoryblokButtonType[];
}

const Features = ({
                    featuresTitle,
                    featuresSubtitle,
                    featuresDescription,
                    featuresImage,
                    featuresCtaButton
                  }: FeaturesProps) => {

  return (
    <div className="lp-features sectionMargins--compact">
      <div className="lp-features__paper whiteBcg">
        <div className="container">
          <h4 className="lp-features__title">{featuresTitle}</h4>
          <div className="lp-features__subtitle">{featuresSubtitle}</div>
          <div className="lp-features__imageAndTextWrapper">
            <div className="lp-features__imageWrapper">
              <StoryblokImage image={featuresImage} className="lp-features__image"/>
            </div>
            <div className="lp-features__textWrapper">
              <FreeText className="lp-features__richText" richText={featuresDescription} listStyle={'bullets'}/>
              {featuresCtaButton[0] ? <Button className="lp-features__ctaButton" {...featuresCtaButton[0]} /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Features
