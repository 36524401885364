import React from 'react'
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock'
import Hero from '../../../components/hero/components/Hero'
import TabSwitcher from '../../../components/tabs/components/TabSwitcher'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import AccuritySeoMeta, { AccuritySeoMetaType, } from '../../../components/AccuritySeoMeta'
import CtaBanner, { CtaBannerType, } from '../../../components/ctaBanner/components/CtaBanner'
import { ReferenceLogo } from '../../../components/referenceLogo/components/ReferenceLogosContainer'
import Boxes from '../../../components/boxes/components/Boxes'
import Clients from '../../../components/clients/components/Clients'
import Posts from '../../../components/posts/components/Posts'
import Awards from '../../../components/awards/components/Awards'
import Rating from '../../../components/rating/components/Rating'

import { BlogType, StoryblokImageType, StoryblokLinkType, TabItemType, } from '../../../types/storyblokTypes'
import { StorybookUniversalHeaderType } from '../../../components/header/components/UniversalHeader';

export type HomePageProps = {
  header: StorybookUniversalHeaderType[]
  seoMeta: AccuritySeoMetaType
  referenceLogosTitle: string
  referenceLogosText: string
  referenceLogos: { content: ReferenceLogo }[]
  benefitsTitle: string
  benefitBlocks: ThreeElementBlockType[]
  ctaBanner: { content: CtaBannerType }
  ctaBanner2: { content: CtaBannerType }
  tabItems: TabItemType[]
  postsTitle: string
  articles: { content: BlogType; full_slug: string }[]
  postsCtaLabel: string
  awardsTitle: string
  awardLogos: { image: StoryblokImageType }[]
  ratingLogo: StoryblokImageType
  ratingValue: string
  ratingStars: StoryblokImageType
  ratingDate: string
  ratingTitle: string
  ratingKeyValue: { key: string; value: string }[]
  ratingText: string
  ratingCtaLabel: string
  ratingCtaLink: StoryblokLinkType
}

const HomePage = ({
                    benefitBlocks,
                    benefitsTitle,
                    ctaBanner,
                    ctaBanner2,
                    header,
                    referenceLogos,
                    referenceLogosText,
                    referenceLogosTitle,
                    seoMeta,
                    tabItems,
                    postsTitle,
                    articles,
                    postsCtaLabel,
                    awardsTitle,
                    awardLogos,
                    ratingLogo,
                    ratingValue,
                    ratingStars,
                    ratingDate,
                    ratingTitle,
                    ratingKeyValue,
                    ratingText,
                    ratingCtaLabel,
                    ratingCtaLink,
                  }: HomePageProps) => {
  return (
    <div className={`homePage lightGreyBcg`}>
      <AccuritySeoMeta {...seoMeta} />
      <Hero {...header[0]} />
      <div className={'homePage__contentContainer'}>
        <TabSwitcher tabItems={tabItems}/>
        <Clients
          title={referenceLogosTitle}
          text={referenceLogosText}
          logos={referenceLogos}
        />
        <Boxes title={benefitsTitle} blocks={benefitBlocks}/>
        <CtaBanner
          banner={ctaBanner2?.content}
        />
        <Posts title={postsTitle} articles={articles} label={postsCtaLabel}/>
        <Awards title={awardsTitle} logos={awardLogos}/>
        <Rating
          logo={ratingLogo}
          value={ratingValue}
          stars={ratingStars}
          date={ratingDate}
          title={ratingTitle}
          keyValue={ratingKeyValue}
          text={ratingText}
          ctaLabel={ratingCtaLabel}
          ctaLink={ratingCtaLink}
        />
        <CtaBanner
          banner={ctaBanner?.content}
        />
      </div>
      <FooterContainer/>
    </div>
  )
};

export default HomePage
