import React from 'react'

const UncheckedSvgContent: React.FC = () => (
  <g fill="none" stroke="#0c2340" strokeWidth="1">
    <rect width="16" height="16" rx="2" stroke="none"/>
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="none"/>
  </g>
);

const CheckedSvgContent: React.FC<{ color: string }> = ({ color }) => (
  <>
    <g fill={color} stroke={color} strokeWidth="1">
      <rect width="16" height="16" rx="2" stroke="none"/>
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="none"/>
    </g>
    <path d="M3311.824,792.266l2.743,3.317,2.073-2.506,2.889-3.494" transform="translate(-3307.324 -785.082)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
  </>
);

type CheckboxIconProps = {
  isChecked: boolean,
  color?: string,
  className?: string
};

export const CheckboxIcon: React.FC<CheckboxIconProps> = ({
                                                            color = '#5d7ce5',
                                                            isChecked,
                                                            className
                                                          }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={className}>
      {isChecked
        ? <CheckedSvgContent color={color}/>
        : <UncheckedSvgContent/>}
    </svg>
  )
};