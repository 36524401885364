import React from 'react';
import RichTextResolver from 'storyblok-js-client/dist/rich-text-resolver.es'

import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import ZohoContactUsForm from '../../../components/zohoForms/components/ZohoContactUsForm';
import LocationBlock, { LocationBlockType } from '../../../components/locationBlock/components/LocationBlock';
import { FormLabels } from '../../../components/form/types';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';
import { CONTACT_US_THANK_YOU_PAGE_URL } from '../../../rest/endpoints';
import { useHasSearchParam } from '../../../hooks/useHasSearchParam';

const resolver = new RichTextResolver();

export type ContactUsPageProps = {
  header: StorybookUniversalHeaderType[];
  seoMeta: AccuritySeoMetaType;
  locationBlocks: { content: LocationBlockType }[];
  formLabels: FormLabels[];
  companyName: string,
  companyInfo: any,
}

const ContactUsPage = ({ header, seoMeta, locationBlocks, formLabels, companyName, companyInfo }: ContactUsPageProps) => {
  const html = companyInfo ? resolver.render(companyInfo) : '';
  const isFromPricing = useHasSearchParam('fromPricing');

  return (
    <div className={'contactUsPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />
      <div className="container contactUsPage__contentContainer">
        <div className="contactUsPage__locationBlocksContainer">
          {locationBlocks.map((locationBlock, idx) => (
            <LocationBlock
              key={idx}
              {...locationBlock.content}
            />
          ))}
          <div className="contactUsPage__company">
            <p className="contactUsPage__companyName">
              {companyName}
            </p>
            <div dangerouslySetInnerHTML={{ __html: html }}/>
          </div>
        </div>
        <div className="contactUsPage__formColumn hashTarget" id="form">
          <ZohoContactUsForm
            formLabels={formLabels[0]}
            successRedirectUrl={CONTACT_US_THANK_YOU_PAGE_URL}
            formSource={isFromPricing ? 'Accurity Contact Us - Pricing' : 'Accurity Contact Us'}
          />
        </div>
      </div>
      <BackgroundLines images={{ 'right': 'https://a.storyblok.com/f/91820/x/92551fc4a2/acw-company-get-in-touch-lines-01.svg' }}/>
      <FooterContainer/>
    </div>
  );
};

export default ContactUsPage;
