import React from 'react';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import TestimonialSlider, { TestimonialSliderType } from '../../../components/testimonialsSlider/components/TestimonialSlider';
import FiftyFiftyBlock, { FiftyFiftyBlockType } from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlock';
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import ReferenceLogosContainer, { ReferenceLogo } from '../../../components/referenceLogo/components/ReferenceLogosContainer';
import SubHeading from '../../../components/subHeading/components/SubHeading';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type CompanyPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  paragraph: string;
  testimonials: TestimonialSliderType[];
  title: string;
  referenceLogos: { content: ReferenceLogo }[];
  title2: string;
  businessLines: FiftyFiftyBlockType[];
  ctaBanner: { content: CtaBannerType };
}

/**
 * @deprecated Use a CompanyPageV2 instead
 */
const CompanyPage = ({ seoMeta, header, paragraph, testimonials, title, referenceLogos, title2, businessLines, ctaBanner }: CompanyPageProps) => {
  return (
    <div className={'companyPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />
      <div className={'companyPage__contentContainer'}>
        <div className="container">
          <p className={'companyPage__leadParagraph'}>{paragraph}</p>
          <ErrorBoundary placeholderStyle={{ width: '100px', height: '100px' }}>
            <TestimonialSlider
              {...testimonials[0]}
            />
          </ErrorBoundary>
          <div className={'companyPage__referenceLogoSection'}>
            <SubHeading title={title}/>
            <ReferenceLogosContainer referenceLogos={referenceLogos}/>
          </div>
        </div>
        <div className="companyPage__businessLines">
          <div className="container">
            <span>{/*Span is used to changed altering of 50-50 block*/}</span>
            <SubHeading title={title2}/>
            {businessLines.map((block, index) => (
              <FiftyFiftyBlock
                key={index}
                {...block}
              />
            ))}
          </div>
          <BackgroundLines
            images={{
              'top-left': 'https://a.storyblok.com/f/91820/x/0c43ca4021/acw-company-abs-lines-02.svg',
              'top-right': 'https://a.storyblok.com/f/91820/x/19fc95a57d/acw-company-abs-lines-01.svg',
              'bottom-left': 'https://a.storyblok.com/f/91820/x/ad6eeef07d/acw-company-abs-lines-04.svg',
              'bottom-right': 'https://a.storyblok.com/f/91820/x/2afb3080e1/acw-company-abs-lines-03.svg',
            }}
          />
        </div>

        <CtaBanner
          banner={ctaBanner?.content}
        />

      </div>
      <FooterContainer/>
    </div>
  );
};

export default CompanyPage;
