import React from 'react'
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock';
import Boxes from '../../../components/boxes/components/Boxes';

export type BenefitsProps = {
  benefitsTitle: string;
  benefitsSubtitle: string;
  benefitsCards: ThreeElementBlockType[];
}

const Benefits = ({
                      benefitsTitle,
                      benefitsSubtitle,
                      benefitsCards
                    }: BenefitsProps) => {
  return (
    <div className="lp-benefits sectionMargins--compact">
      <Boxes
        title={benefitsTitle}
        subTitle={benefitsSubtitle}
        blocks={benefitsCards}
        style={'dark'}
      />
    </div>
  )
};

export default Benefits;
