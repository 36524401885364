import * as React from 'react'
import { useRef } from 'react'
import Slider, { Settings } from 'react-slick'
import { ResourceCardType } from '../types';
import { createResourceCardElement } from '../utils';

export type ResourceCardsContainerProps = {
  title?: string;
  resources: ResourceCardType[];
  showArrows?: boolean;
};

const ResourceCardsContainer = ({ resources, title, showArrows = true }: ResourceCardsContainerProps) => {

  const slider = useRef<Slider>(null);

  const settings: Settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: showArrows && resources.length > 3,
    nextArrow: <SlickButtonFix>
      <div className="resourceCardsContainer__rightArrow">
        <div className="resourceCardsContainer__arrow"/>
      </div>
    </SlickButtonFix>,
    prevArrow: <SlickButtonFix>
      <div className="resourceCardsContainer__leftArrow">
        <div className="resourceCardsContainer__arrow"/>
      </div>
    </SlickButtonFix>,
    responsive: [
      { breakpoint: 575, settings: { slidesToShow: 1, arrows: showArrows && resources.length > 1 } },
      { breakpoint: 1200, settings: { slidesToShow: 2, arrows: showArrows && resources.length > 2 } }
    ]
  };

  return (
    <div className="resourceCards ">
      <div className="resourceCardsContainer container">
        <h2 className="resourceCardsContainer__title">{title}</h2>
        <Slider {...settings} ref={slider} className="resourceCardsContainer__cardsWrapper">
          {resources.map(createResourceCardElement)}
        </Slider>
      </div>
    </div>
  );
};

// workaround to avoid unknown attribute error on html element, see: https://github.com/akiran/react-slick/issues/1195#issuecomment-390383615
export const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }: any) => (
  <span {...props}>{children}</span>
);

export default ResourceCardsContainer;
