import { StorybookFormField } from '../../../types/storyblokTypes';
import TextField from '../../form/components/TextField';
import CheckBox from '../../form/components/CheckBox';
import ReactMarkdown from 'react-markdown';
import React from 'react';
import { ZohoFormHandlers } from '../../form/zohoLogic';
import TextArea from '../../form/components/TextArea';

export const renderField = (
  field: StorybookFormField,
  key: number,
  formHandlers: ZohoFormHandlers<any>
) => {

  switch (field.fieldType) {
    case 'text':
    case 'email':
      return (
        <TextField
          name={field.zohoFieldName}
          label={field.label + (field.required ? '*' : '')}
          value={formHandlers.values[field.zohoFieldName]}
          setValue={formHandlers.setValue}
          error={formHandlers.errors[field.zohoFieldName]}
          clearError={formHandlers.clearError}
          key={key}
        />
      );
    case 'textarea':
      return (
        <TextArea
          name={field.zohoFieldName}
          label={field.label + (field.required ? '*' : '')}
          value={formHandlers.values[field.zohoFieldName]}
          setValue={formHandlers.setValue}
          error={formHandlers.errors[field.zohoFieldName]}
          clearError={formHandlers.clearError}
          key={key}
        />
      );
    case 'checkbox':
      return (
        <div className="form__checkBoxContainer" key={key}>
          <CheckBox
            name={field.zohoFieldName}
            label={
              <ReactMarkdown renderers={{ link: (props: any) => <a href={props.href} target="_blank">{props.children}</a> }}>
                {field.label}
              </ReactMarkdown>
            }
            value={formHandlers.values[field.zohoFieldName]}
            setValue={formHandlers.setValue}
            error={formHandlers.errors[field.zohoFieldName]}
            clearError={formHandlers.clearError}
          />
        </div>
      );
    default:
      return null;
  }
};
