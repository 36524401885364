import React from 'react'
import { StoryblokImageType, StoryblokLinkType, WhitepaperFormLabelsType } from '../../../types/storyblokTypes';
import StoryblokImage from '../../../components/storyblokImage/components/StoryblokImage';
import TopNavContainer from '../../../navigation/components/TopNavContainer';
import ZohoWhitepaperForm from '../../../components/zohoForms/components/WhitepaperForm';

export type whitepaperHeroProps = {
  tag: string;
  title: string;
  text: string;
  headerImage: StoryblokImageType;
  formLabels: WhitepaperFormLabelsType;
  formSource: string;
  docPath: StoryblokLinkType;
  docName: string;
}

const WhitepaperHero = ({
                          headerImage,
                          tag,
                          text,
                          title,
                          formLabels,
                          formSource,
                          docName,
                          docPath
                        }: whitepaperHeroProps) => {

  return (
    <div className="whitepaperHero">
      <TopNavContainer inverted={true}/>

      <div className="container whitepaperHero__container">
        <div className="whitepaperHero__leftColumn">
          <div className="whitepaperHero__tag">{tag}</div>
          <h1 className="whitepaperHero__title">{title}</h1>
          <div className="whitepaperHero__text">{text}</div>
          <div className="whitepaperHero__imageWrapper">
            <StoryblokImage
              image={headerImage}
              className={'whitepaperHero__image'}
            />
          </div>
        </div>
        <div className="whitepaperHero__formWrapper hashTarget" id="form">
          <ZohoWhitepaperForm
            formLabels={formLabels}
            formSource={formSource}
            docName={docName}
            docPath={docPath}
          />
        </div>
      </div>

    </div>
  )
};

export default WhitepaperHero;
