import React from 'react'
import { StoryblokImageType, } from '../../../types/storyblokTypes'
import StoryblokImage from '../../../components/storyblokImage/components/StoryblokImage';

export type RatingProps = {
  ratingTitle: string;
  ratingValue: string;
  ratingText: string;
  ratingAuthor: { key: string; value: string }[];
  ratingLogo: StoryblokImageType;
  ratingStars: StoryblokImageType;
}

const Rating = ({
                    ratingAuthor,
                    ratingLogo,
                    ratingStars,
                    ratingText,
                    ratingTitle,
                    ratingValue,
                  }: RatingProps) => {
  return (
    <div className="lp-rating sectionMargins--compact">
      <div className="container">
        <h3 className="lp-rating__title">{ratingTitle}</h3>
        <div className="lp-rating__box">
          <StoryblokImage image={ratingStars} className="lp-rating__stars"/>
          <div className="lp-rating__value">{ratingValue}</div>
          <div className="lp-rating__text">{ratingText}</div>
          <div className="lp-rating__author">
            <div className="lp-rating__authorName">{ratingAuthor[0]?.key}</div>
            <div className="lp-rating__authorPosition">{ratingAuthor[0]?.value}</div>
          </div>
          <div className="lp-rating__source">
            <i>Source: </i>
            <StoryblokImage image={ratingLogo} className="lp-rating__sourceLogo"/>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Rating
