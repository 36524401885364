import { Block, DocumentNode, ListItemNode, ParagraphNode, TextNode } from '@marvr/storyblok-rich-text-types';

const isEmptyTextNodes = (nodes: TextNode[]) => {
  return !nodes || nodes.every(q => !q.text);
};

const isEmptyParagraphNodes = (nodes: ParagraphNode[]) => {
  return !nodes || nodes.every(q => isEmptyTextNodes(q.content));
};

const isEmptyListNodes = (nodes: ListItemNode[]) => {
  return !nodes || nodes.every(q => isEmptyParagraphNodes(q.content));
};

export const isEmpty = (doc: DocumentNode) => {
  return doc.content.every(node => {
    if (node.type === Block.CODE || node.type === Block.HEADING || node.type === Block.PARAGRAPH) {
      return isEmptyTextNodes(node.content)
    } else if (node.type === Block.HR) {
      return false
    } else if (node.type === Block.IMAGE) {
      return false
    } else if (node.type === Block.OL_LIST || node.type === Block.UL_LIST) {
      return isEmptyListNodes(node.content);
    } else if (node.type === Block.QUOTE) {
      return isEmptyParagraphNodes(node.content)
    }
  })
};