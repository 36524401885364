import React, { useState } from "react"
import { render } from "storyblok-rich-text-react-renderer-ts"
import classNames from "classnames"

import StoryblokImage from "../../storyblokImage/components/StoryblokImage"
import StoryblokLink from "../../storyblokLink/components/StoryblokLink"

import { TabItemType } from "../../../types/storyblokTypes"

type TabSwitcherType = {
  tabItems: TabItemType[]
}

const TabSwitcher = ({ tabItems }: TabSwitcherType) => {
  const [currentSlide, setSlide] = useState(tabItems[0]._uid)

  const renderTabClassNames = (itemId: string) =>
    classNames("c-tabSwitcher__tabItem", {
      "c-tabSwitcher__tabItem--hidden": itemId != currentSlide,
    })

  const renderNavClassNames = (itemId: string) =>
    classNames("c-tabSwitcher__navItem", {
      "c-tabSwitcher__navItem--selected": itemId === currentSlide,
    })

  const navItems = tabItems.map((item, i) => (
    <li
      className={renderNavClassNames(item._uid)}
      key={`tab-item-${i}`}
      onClick={() => setSlide(item._uid)}
    >
      <span className="c-tabSwitcher__navLabelWrapper">
        <span className="c-tabSwitcher__navLabel hover-bcgUnderline-tab">
          {item.tabNavigationText}
        </span>
      </span>
    </li>
  ))

  const tabContent = tabItems.map((item, i) => (
    <div className={renderTabClassNames(item._uid)} key={`tab-content-${i}}`}>
      <h2 className="c-tabSwitcher__head">{item?.tabNavigationText}</h2>
      <div className="c-tabSwitcher__tabItem-imageWrapper">
        <StoryblokImage
          image={item?.tabImage}
          className="c-tabSwitcher__tabItem-image"
        />
      </div>
      <div className="c-tabSwitcher__tabItem-description">
        {render(item.tabDescription)}
        {item?.tabLink && item?.tabLinkLabel && (
          <StoryblokLink
            to={item?.tabLink}
            className="c-tabSwitcher__tabItem-link"
          >
            {item?.tabLinkLabel}
          </StoryblokLink>
        )}
      </div>
    </div>
  ))

  return (
    <section className="c-tabSwitcher">
      <div className="container">
        <ul className="c-tabSwitcher__navigation">{navItems}</ul>
        <div className="c-tabSwitcher__content">{tabContent}</div>
      </div>
    </section>
  )
}

export default TabSwitcher
