import React, { useState } from 'react';
import classNames from 'classnames';
import { render } from 'storyblok-rich-text-react-renderer-ts'
import { FaqSectionItemType, FaqSectionType } from '../types';
import Accordion from 'react-bootstrap/Accordion';

export type FaqProps = {
  title: string;
  sections: FaqSectionType[];
};

const Faq = ({ title, sections }: FaqProps) => {
  const [openedSection, setOpenedSection] = useState<string | undefined>('0');
  const [openedQuestion, setOpenedQuestion] = useState<string>();

  const renderQuestions = (questions: FaqSectionItemType[], sectionIdx: number) => (
    <div>
      <Accordion className="faq__questions" activeKey={openedQuestion} onSelect={(e) => setOpenedQuestion(e || undefined)}>
        {questions.map((question, questionIdx) => {
          const cardIdx = `${sectionIdx}_${questionIdx}`;
          return (
            <div className={classNames('faq__questionCard', { 'faq__questionCard--expanded': cardIdx === openedQuestion })} key={cardIdx}>
              <Accordion.Toggle as={'div'} className="faq__question" eventKey={cardIdx}>
                {question.title}
                <div className="faq__collapseArrow"/>
              </Accordion.Toggle>
              <Accordion.Collapse className="faq__answer" eventKey={cardIdx}>
                <div>
                  {render(question.text)}
                </div>
              </Accordion.Collapse>
            </div>
          )
        })}
      </Accordion>
    </div>
  );

  return (
    <div className="faq container">
      <h2 className="faq__title">{title}</h2>

      <Accordion className="faq__accordion" activeKey={openedSection + ''} onSelect={(e) => setOpenedSection(e || undefined)}>
        {sections.map((section, sectionIdx) => {
          const eventKey = sectionIdx + '';
          return (
            <div className={classNames('faq__sectionCard', { 'faq__sectionCard--expanded': eventKey === openedSection })} key={sectionIdx}>
              <Accordion.Toggle as={'h3'} className="faq__sectionTitle" eventKey={eventKey}>
                {section.title}
                <div className="faq__collapseArrow"/>
              </Accordion.Toggle>
              <Accordion.Collapse className="faq__sectionContent" eventKey={eventKey}>
                {renderQuestions(section.items, sectionIdx)}
              </Accordion.Collapse>
            </div>
          )
        })}
      </Accordion>
    </div>
  );
};

export default Faq;
