import { useEffect, useState } from 'react';

export const useActiveButtonState = () => {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isActive) {
      timer = setTimeout(() => setActive(false), 600);
    }

    // cleanup
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [isActive]);

  return [isActive, setActive] as const;
};