import { useEffect, useState } from 'react';

const url = 'https://api.country.is/';

export const useCountryCode = () => {
  const [country, setCountry] = useState<string | undefined>();
  useEffect(() => {
    fetch(url, { method: 'GET' })
      .then(response => response.json())
      .then(responseBody => setCountry(responseBody.country.toLowerCase()))
  }, []);
  return country;
};
