import React from 'react'
import { FiftyFiftyBlockV2 } from './FiftyFiftyBlockV2';
import { FiftyFiftyBlockV2Type } from '../../../types/storyblokTypes';
import classNames from 'classnames';

export type FiftyFiftyContainerProps = {
  title: string;
  cards: FiftyFiftyBlockV2Type[];
  reverseOrder?: boolean;
};

const FiftyFiftyContainer = ({
                               cards,
                               title,
                               reverseOrder = false
                             }: FiftyFiftyContainerProps) => {
  if (!cards?.length) {
    return null;
  }

  return (
    <div className="c-fiftyFiftyContainer container sectionMargins">
      <h2 className="sectionTitle">{title}</h2>
      <div className={classNames('c-fiftyFiftyContainer__cardsWrapper', {
        'c-fiftyFiftyContainer__cardsWrapper--reverse': reverseOrder
      })}>
        {cards?.map((block, index) => (
          <FiftyFiftyBlockV2 key={index} {...block}/>
        ))}
      </div>
    </div>
  );
};

export default FiftyFiftyContainer
