import React from 'react'
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta'
import SubHeading from '../../../components/subHeading/components/SubHeading';
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock';
import { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import TestimonialSlider, { TestimonialSliderType } from '../../../components/testimonialsSlider/components/TestimonialSlider';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import ReferenceLogosContainer, { ReferenceLogo } from '../../../components/referenceLogo/components/ReferenceLogosContainer';
import ThreeElementBlockContainer from '../../../components/threeElementBlock/components/ThreeElementBlockContainer';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';
import FiftyFiftyBlock, { FiftyFiftyBlockType } from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlock';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { LandingPageCtaBanner } from '../components/LandingPageCtaBanner';
import CustomerStoryBanner, { CustomerStoryBannerType } from '../../../components/customerStoryBanner/components/CustomerStoryBanner';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

type LandingPageV2Props = {
  seoMeta: AccuritySeoMetaType,
  header: StorybookUniversalHeaderType[],
  title: string,
  paragraph: string,
  referenceLogos: { content: ReferenceLogo }[];
  referenceLogosTitle: string;
  referenceLogosText: string;
  useCaseTitle: string;
  useCaseOrBenefit: ThreeElementBlockType[];
  ctaBanner: { content: CtaBannerType };
  featuresTitle: string;
  featuresBlocks: FiftyFiftyBlockType[];
  testimonials: TestimonialSliderType[];
  ctaBanner2: { content: CtaBannerType };
  customerStory: { content: CustomerStoryBannerType };
}

const LandingPageV2 = ({
                         seoMeta,
                         header,
                         title,
                         paragraph,
                         referenceLogosTitle,
                         referenceLogosText,
                         referenceLogos,
                         useCaseTitle,
                         useCaseOrBenefit,
                         ctaBanner,
                         featuresTitle,
                         featuresBlocks,
                         testimonials,
                         ctaBanner2,
                         customerStory
                       }: LandingPageV2Props) => {
  return (
    <div className="landingPageV2">
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />

      <section className="landingPageV2__subHeading">
        <div className="container">
          <SubHeading
            title={title}
            paragraph={paragraph}
          />
        </div>
        <BackgroundLines
          images={{
            'right': 'https://a.storyblok.com/f/91820/x/341d8f3260/acw-solution-overview-lines-01.svg',
          }}
        />
      </section>

      <section className={'companyPage__referenceLogos container'}>
        <SubHeading
          title={referenceLogosTitle}
          paragraph={referenceLogosText}
        />
        <ReferenceLogosContainer referenceLogos={referenceLogos}/>
        {customerStory && (
          <div className="landingPageV2__customerStoryWrapper">
            <CustomerStoryBanner {...customerStory.content} />
          </div>
        )}
      </section>

      <section className={'landingPageV2__useCases container'}>
        <SubHeading title={useCaseTitle}/>
        <ThreeElementBlockContainer
          blocksPerRow={4}
          blocks={useCaseOrBenefit}
          centered
        />
      </section>

      <LandingPageCtaBanner ctaBanner={ctaBanner} classname={'landingPageV2__ctaBanner'} />

      <section className={'landingPageV2__features container'}>
        <SubHeading title={featuresTitle}/>
        {featuresBlocks.map((block, index) => (
          <FiftyFiftyBlock
            key={index}
            {...block}
          />
        ))}
      </section>

      <section className="landingPageV2__testimonial container">
        <ErrorBoundary placeholderStyle={{ width: '100px', height: '100px' }}>
          <TestimonialSlider
            {...testimonials[0]}
          />
        </ErrorBoundary>
      </section>

      <LandingPageCtaBanner ctaBanner={ctaBanner2} classname={'landingPageV2__ctaBanner'}/>

      <FooterContainer/>
    </div>
  )
};

export default LandingPageV2
