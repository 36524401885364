import { useCallback, useState } from 'react';

type PaginationParams = {
  pageSize?: number;
  firstPageSize?: number;
}

export const usePagination = <T>(items: T[], params?: PaginationParams) => {
  const { firstPageSize, pageSize } = {
    pageSize: 10,
    firstPageSize: 10,
    ...params
  };

  const [page, setPage] = useState(0);

  const loadMore = useCallback(() => {
    setPage(current => current + 1);
  }, [setPage]);

  const shownItems = [
    ...items.slice(0, firstPageSize),
    ...items.slice(firstPageSize, firstPageSize + page * pageSize)
  ];

  return {
    isLoadMoreButtonVisible: items.length > shownItems.length,
    loadMore,
    shownItems,
    progress: Math.ceil((shownItems.length / items.length) * 100)
  };
};