import { useZohoFormLogic, ZohoFormFields } from '../../form/zohoLogic';

const NEWSLETTER_SIGN_UP_SUBMIT_URL =
  'https://form.accurity.ai/simplitygroup/form/AccurityNewslettersignup/formperma/-0vQak049Qu1vbBpbCXNAm51Gchr9hNFkkYkqO2vFKU/htmlRecords/submit';

type NewsletterSignupFields = {
  email: string;
}

const zohoNewsletterFormFields: ZohoFormFields<NewsletterSignupFields> = {
  email: {
    zohoFieldName: 'Email',
    initialValue: '',
    required: true,
    runEmailValidation: true
  }
};

export const useNewsletterSignup = (formIdentifier: string, successRedirectUrl: string) => {
  const {
    values,
    setValue,
    doSubmit,
    isFetching
  } = useZohoFormLogic<NewsletterSignupFields>({
    formIdentifier: formIdentifier,
    zohoSubmitUrl: NEWSLETTER_SIGN_UP_SUBMIT_URL,
    successRedirectUrl,
    zohoFormFields: zohoNewsletterFormFields
  });

  const email = values.email as string;
  const setEmail = (newEmail: string) => setValue('email', newEmail);

  return {
    email,
    setEmail,
    doSubmit,
    isFetching
  }
};
