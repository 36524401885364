import React, { SyntheticEvent, useState } from 'react';

export type TextAreaProps = {
  name: string;
  label?: string;
  value?: string;
  setValue: (name: string, value: string) => void;
  error?: string;
  clearError?: (name: string) => void;
}

const TextArea = ({ name, label, value = '', setValue, error, clearError }: TextAreaProps) => {
  const [isFocused, setFocused] = useState(false);
  const hasError = !!error;

  const handleChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    if (error && clearError) {
      clearError(name);
    }
    setValue(name, e.currentTarget.value);
  };

  return (
    <div className={`textArea ${isFocused ? 'textArea--focused' : ''} ${hasError && !isFocused ? 'textArea--error' : ''}`}>
      <span className={'textArea__label'}>
        {label}
      </span>
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      {hasError ?
        <span className={'textArea__error'}>
          {error}
        </span>
        : null
      }
    </div>
  );
};

export default TextArea;
