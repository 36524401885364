import { StoryblokLinkType } from '../types/storyblokTypes';

type PromotedItemConfig = {
  link: StoryblokLinkType;
  image: string;
  headline: string;
}

// if you change this, change value also in ./styles/navigationVariables.less (@nav-breakpoint)
const DESKTOP_VIEW_BREAKPOINT = 1240;

export const isDesktopView = () => typeof window !== 'undefined'
  ? window.innerWidth >= DESKTOP_VIEW_BREAKPOINT
  : true; // for SSR