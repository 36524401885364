import * as React from 'react'
import { StoryblokLinkType, } from '../../types/storyblokTypes'
import StoryblokLink from '../../components/storyblokLink/components/StoryblokLink'
// @ts-ignore
import UserIcon from '../../images/icons/login-user.svg'

export type TopBarProps = {
  loginButtonLink: StoryblokLinkType
  loginButtonLabel: string
  topBarText: string
  topBarLabel: string
  topBarLink: StoryblokLinkType
}

const TopBar = ({
                  loginButtonLink,
                  loginButtonLabel,
                  topBarText,
                  topBarLabel,
                  topBarLink,
                }: TopBarProps) => {

  return (
    <div className="topBar">
      <div className="topBar__inner container">
        <div className="topBar__message">
          <span className="topBar__text">{topBarText}</span>
          <StoryblokLink to={topBarLink} className="topBar__link">
            {topBarLabel}
          </StoryblokLink>
        </div>

        <div className="topBar__login">
          <StoryblokLink
            to={loginButtonLink}
            className="topBar__loginLink hover-bcgUnderline"
          >
            <UserIcon className="topBar__logoLink-icon"/>
            <span className="topBar__loginLink-label">
              {loginButtonLabel}
          </span>
          </StoryblokLink>
        </div>
      </div>
    </div>
  )
};

export default TopBar
