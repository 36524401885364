const link = {
  'id': '',
  'url': 'http://www.accurity.eu/',
  'linktype': 'story',
  'fieldtype': 'multilink',
  'cached_url': 'http://www.accurity.eu/'
}

export const navbarData = {
  'logo': {
    'id': 1871426,
    'alt': '',
    'name': '',
    'focus': null,
    'title': '',
    'filename': 'https://a.storyblok.com/f/91820/x/7404b8d494/acc-logotype-white-color.svg',
    'copyright': '',
    'fieldtype': 'asset'
  },
  'component': 'TopNav',
  'logo_link': {
    'id': '',
    'url': '/',
    'linktype': 'url',
    'fieldtype': 'multilink',
    'cached_url': '/'
  },
  'menu_items': [
    {
      'link': link,
      'label': 'Solutions',
      'style': '',
      'button': [
        {
          'link': link,
          'label': 'GET YOUR DEMO',
          'isScroll': false,
          'severity': 'primary-outlined',
          'component': 'Button',
        }
      ],
      'component': 'TopNav Menu Item',
      'promotedItem': null,
      'submenu_items': [
        {
          'link': link,
          'label': 'Solutions Overview',
          'component': 'Top Nav Submenu Main Link',
        },
        {
          'links': [
            {
              'link': link,
              'label': 'Business Glossary and Data Catalog',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Data Quality and Data Observability',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Business Data Model and Data Catalog',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Business Data Lineage',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Reference Data Management',
              'component': 'Footer Link',
            }
          ],
          'component': 'Top Nav Submenu Small Items',
        },
        {
          'link': link,
          'label': 'Media',
          'component': 'Top Nav Submenu Main Link',
        },
        {
          'link': link,
          'label': 'Financial Services',
          'component': 'Top Nav Submenu Main Link',
        }
      ],
      'promotedCtaLabel': '',
    },
    {
      'link': link,
      'label': 'Platform',
      'style': '',
      'button': [
        {
          'link': link,
          'label': 'GET YOUR DEMO',
          'isScroll': false,
          'severity': 'primary-outlined',
          'component': 'Button',
        }
      ],
      'component': 'TopNav Menu Item',
      'promotedItem': null,
      'submenu_items': [
        {
          'link': link,
          'label': 'Platform Overview',
          'component': 'Top Nav Submenu Main Link',
        },
        {
          'links': [
            {
              'link': link,
              'label': 'All Features',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Latest Features',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Roadmap',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Deployment Models',
              'component': 'Footer Link',
            }
          ],
          'component': 'Top Nav Submenu Small Items',
        },
        {
          'link': link,
          'label': 'Professional Services',
          'component': 'Top Nav Submenu Main Link',
        }
      ],
      'promotedCtaLabel': '',
    },
    {
      'link': link,
      'label': 'Pricing',
      'style': '',
      'button': [],
      'component': 'TopNav Menu Item',
      'promotedItem': null,
      'submenu_items': [],
      'promotedCtaLabel': '',
    },
    {
      'link': link,
      'label': 'Partners',
      'style': '',
      'button': [
        {
          'link': link,
          'label': '',
          'isScroll': false,
          'severity': 'primary',
          'component': 'Button',
        }
      ],
      'component': 'TopNav Menu Item',
      'promotedItem': null,
      'submenu_items': [],
      'promotedCtaLabel': '',
    },
    {
      'link': link,
      'label': 'Resources',
      'style': '',
      'button': [],
      'component': 'TopNav Menu Item',
      'promotedItem': {
        'name': 'Data-Driven Culture Infographic',
        'created_at': '2022-10-17T13:07:43.274Z',
        'published_at': '2022-11-19T20:31:51.082Z',
        'alternates': [],
        'id': 204622118,
        'uuid': 'ab83905f-a210-4a80-918c-e6dfbfb7e69d',
        'content': {
          'tag': '',
          'link': link,
          'text': {
            'type': 'doc',
            'content': [
              {
                'type': 'paragraph'
              }
            ]
          },
          'label': '',
          'header': [
            {
              'tag': 'Infographic',
              'headline': 'The State of Data-Driven Culture for Better Business Decisions',
              'component': 'UniversalHeader',
              'background': 'background-4',
              'ctaButtons': [
                {
                  'link': link,
                  'label': 'GET THE INFOGRAPHIC',
                  'target': '_blank',
                  'isScroll': false,
                  'severity': 'primary',
                  'component': 'Button',
                }
              ],
              'headerMedia': {
                'id': 6354707,
                'alt': '',
                'name': '',
                'focus': '',
                'title': '',
                'filename': 'https://a.storyblok.com/f/91820/944x638/82122d0575/statista-infographic-data-driven-culture-header-img.jpg',
                'copyright': '',
                'fieldtype': 'asset',
                'is_external_url': false
              },
              'headerStyle': [
                'aligned-to-edge'
              ],
              'leadParagraph': {
                'type': 'doc',
                'content': [
                  {
                    'type': 'paragraph',
                    'content': [
                      {
                        'text': 'From this infographic you will learn:',
                        'type': 'text'
                      }
                    ]
                  },
                  {
                    'type': 'bullet_list',
                    'content': [
                      {
                        'type': 'list_item',
                        'content': [
                          {
                            'type': 'paragraph',
                            'content': [
                              {
                                'text': 'what are the drivers for a data-driven culture',
                                'type': 'text'
                              }
                            ]
                          }
                        ]
                      },
                      {
                        'type': 'list_item',
                        'content': [
                          {
                            'type': 'paragraph',
                            'content': [
                              {
                                'text': 'what are the benefits of a data culture',
                                'type': 'text'
                              }
                            ]
                          }
                        ]
                      },
                      {
                        'type': 'list_item',
                        'content': [
                          {
                            'type': 'paragraph',
                            'content': [
                              {
                                'text': 'which industries are leading the way',
                                'type': 'text'
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
            }
          ],
          'target': '_self',
          'seoMeta': {
            'title': 'The State of Data-Driven Culture | Accurity Infographic',
            'plugin': 'accurity_meta',
            'ogImage': 'https://a.storyblok.com/f/91820/1200x630/2366c63b50/statista-infographic-data-driven-culture-og-image.png',
            'priority': 0.7,
            'changefreq': 'daily',
            'description': 'Get your free “The State of Data-Driven Culture for Better Business Decisions” infographic. Download now…'
          },
          'headline': '',
          'isScroll': false,
          'severity': 'primary',
          'blogCards': [
            'adfafad0-fca4-4410-b753-f49ebb5f619f',
            'b4b5792b-8476-4f93-b36a-3bd853e9e88b',
            '1b1e9c5a-c7e6-4690-8f70-69de5bf45b9a',
            '8097f401-5367-4ba1-a457-55df4959cb6e'
          ],
          'bodyImage': {
            'id': null,
            'alt': null,
            'name': '',
            'focus': null,
            'title': null,
            'filename': null,
            'copyright': null,
            'fieldtype': 'asset',
            'is_external_url': false
          },
          'component': 'Infographic Page',
          'background': 'background-1',
          'ctaButtons': [],
          'headerMedia': {
            'id': null,
            'alt': null,
            'name': '',
            'focus': null,
            'title': null,
            'filename': '',
            'copyright': null,
            'fieldtype': 'asset'
          },
          'headerStyle': [],
          'cardCtaLabel': 'SEE THE INFOGRAPHIC',
          'leadParagraph': {
            'type': 'doc',
            'content': [
              {
                'type': 'paragraph'
              }
            ]
          },
          'blogCardsTitle': 'Blog posts',
          'headerImageThumb': {
            'id': 6378696,
            'alt': '',
            'name': '',
            'focus': '',
            'title': '',
            'filename': 'https://a.storyblok.com/f/91820/524x307/465b011671/statista-infographic-drop-down-img.jpg',
            'copyright': '',
            'fieldtype': 'asset',
            'is_external_url': false
          },
        },
        'slug': 'data-driven-culture-infographic',
        'full_slug': 'resources/data-driven-culture-infographic',
        'default_full_slug': null,
        'sort_by_date': null,
        'position': -30,
        'tag_list': [],
        'is_startpage': false,
        'parent_id': 55042431,
        'meta_data': null,
        'group_id': 'e10db9c0-d7e5-4e11-a165-bd95e3983056',
        'first_published_at': '2022-11-15T08:45:37.347Z',
        'release_id': null,
        'lang': 'default',
        'path': null,
        'translated_slugs': []
      },
      'submenu_items': [
        {
          'link': link,
          'label': 'Resources Overview',
          'component': 'Top Nav Submenu Main Link',
        },
        {
          'links': [
            {
              'link': link,
              'label': 'Blog',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Whitepapers and e-books',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Infographics',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Customer Stories',
              'component': 'Footer Link',
            },
            {
              'link': link,
              'label': 'Newsletter Sign-up',
              'component': 'Footer Link',
            }
          ],
          'component': 'Top Nav Submenu Small Items',
        }
      ],
      'promotedCtaLabel': 'GET THE INFOGRAPHIC',
    },
    {
      'link': link,
      'label': 'Company',
      'style': '',
      'button': [],
      'component': 'TopNav Menu Item',
      'promotedItem': null,
      'submenu_items': [],
      'promotedCtaLabel': '',
    },
    {
      'link': link,
      'label': 'Try for free',
      'component': 'TopNav Three Cards Item',
      'cards': [
        {
          'component': 'TopNav Menu Card',
          'headline': 'Business Glossary and Data Catalog',
          'description': 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam',
          'style': 'normal',
          'button': [
            {
              'link': link,
              'label': 'CTA BUTTON',
              'isScroll': false,
              'severity': 'primary',
              'component': 'Button',
            }
          ],
        },
        {
          'component': 'TopNav Menu Card',
          'headline': 'Data quality and data observability',
          'description': 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr,',
          'style': 'normal',
          'button': [
            {
              'link': link,
              'label': 'CTA BUTTON',
              'isScroll': false,
              'severity': 'primary',
              'component': 'Button',
            }
          ],
        },
        {
          'component': 'TopNav Menu Card',
          'headline': 'Schedule a demo',
          'description': 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam',
          'style': 'dark',
          'button': [
            {
              'link': link,
              'label': 'CTA BUTTON',
              'isScroll': false,
              'severity': 'tertiary',
              'component': 'Button',
            }
          ],
        }
      ]
    }
  ],
  'topBarLink': link,
  'topBarText': 'NEW E-BOOK: A Fundamental Guide to Data Lineage',
  'topBarLabel': 'GET YOUR COPY',
  'loginButtonLink': link,
  'loginButtonLabel': 'Log In',
  'primaryButtonLink': link,
  'primaryButtonLabel': 'GET YOUR DEMO',
};
