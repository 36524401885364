import React from 'react'
import { FiftyFiftyBlockV2Type, } from '../../../types/storyblokTypes'
import { FiftyFiftyBlockV2 } from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlockV2';

type FiftyFiftyProps = {
  fiftyFiftyBlocks: FiftyFiftyBlockV2Type[];
}

const FiftyFifty = ({
                      fiftyFiftyBlocks
                    }: FiftyFiftyProps) => {

  if (!fiftyFiftyBlocks || fiftyFiftyBlocks.length === 0) {
    return null;
  }

  return (
    <div className="lp-fiftyFifty">
      <div className="lp-fiftyFifty__paper whiteBcg">
        <div className="container">
          {fiftyFiftyBlocks.map((block, idx) => <FiftyFiftyBlockV2 {...block} key={idx}/>)}
        </div>
      </div>
    </div>
  )
};

export default FiftyFifty
