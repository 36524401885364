import React from 'react'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import AccuritySeoMeta from '../../../components/AccuritySeoMeta'
import { WebinarType } from '../../../types/storyblokTypes'
import FreeText from '../../../components/freeText/components/FreeText'
import ZohoUniversalForm from '../../../components/zohoForms/components/UniversalForm';
import WebinarHero from './WebinarHero';

const WebinarPage = ({
                       date,
                       webinarTitle,
                       component,
                       form,
                       formSource,
                       headerImage,
                       headerImageBigThumb,
                       presenters,
                       seoMeta,
                       tag,
                       text,
                     }: WebinarType) => {
  return (
    <div className="webinarPage">
      <AccuritySeoMeta {...seoMeta} />

      <div className="webinarPage__stickyFormContainer">
        <WebinarHero
          presenters={presenters}
          title={webinarTitle}
          headerImage={headerImage}
          tag={tag}
          date={date}
        />

        <div className="container">
          <div className="webinarPage__freeText">
            <FreeText
              richText={text}
              listStyle={'bullets'}
            />
          </div>
        </div>

        <div className="webinarPage__stickyFormWrapper">
          <div className="container">
            <ZohoUniversalForm
              {...form.content}
              formSource={formSource}
            />
          </div>
        </div>
      </div>

      <FooterContainer/>
    </div>
  )
};

export default WebinarPage
