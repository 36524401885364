import React from 'react';
import TopNavContainer from '../../../navigation/components/TopNavContainer';
import { StoryblokButtonType, StoryblokImageType } from '../../../types/storyblokTypes';
import Button from '../../buttons/components/Button';
import classNames from 'classnames';
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import { render } from 'storyblok-rich-text-react-renderer-ts'

export enum StorybookHeaderBackgroundEnum {
  BACKGROUND_1 = 'background-1',
  BACKGROUND_2 = 'background-2',
  BACKGROUND_3 = 'background-3',
  BACKGROUND_4 = 'background-4',
  BACKGROUND_5_ILLUSTRATION = 'background-5-illustration',
}

export enum StorybookHeaderStyleEnum {
  ALIGNED_TO_EDGE = 'aligned-to-edge',
  RATIO_5_7 = 'ratio-5-7',
  LANDING_MEDIA = 'landing-media',
}

export type StorybookUniversalHeaderType = {
  headline: string;
  headerMedia: StoryblokImageType;
  leadParagraph?: string;
  tag?: string;
  ctaButtons?: StoryblokButtonType[];
  headerStyle?: StorybookHeaderStyleEnum[];
  background?: StorybookHeaderBackgroundEnum;
}

type UniversalHeaderProps = {
  rightContent?: React.ReactNode;
  reducedMenu?: boolean;
  leadParagraphPlain?: string;
  className?: string;
  customLeftElement?: React.ReactNode;
} & StorybookUniversalHeaderType;

export const UniversalHeader = ({
                                  ctaButtons,
                                  headerMedia,
                                  headline,
                                  leadParagraph,
                                  rightContent,
                                  tag,
                                  background = StorybookHeaderBackgroundEnum.BACKGROUND_1,
                                  headerStyle = [],
                                  reducedMenu,
                                  leadParagraphPlain,
                                  className,
                                  customLeftElement
                                }: UniversalHeaderProps) => {

  const ctaButtonsElms = (ctaButtons || []).map(button => (
    <Button
      key={button.label}
      label={button.label}
      severity={button.severity}
      link={button.link ? button.link : undefined}
      isScroll={button.isScroll}
    />
  ));

  const getHeaderClasses = () => classNames('c-universalHeader',
    className, {
    'c-universalHeader--background-1': background === StorybookHeaderBackgroundEnum.BACKGROUND_1,
    'c-universalHeader--background-2': background === StorybookHeaderBackgroundEnum.BACKGROUND_2,
    'c-universalHeader--background-3': background === StorybookHeaderBackgroundEnum.BACKGROUND_3,
    'c-universalHeader--background-4': background === StorybookHeaderBackgroundEnum.BACKGROUND_4,
    'c-universalHeader--background-5-illustration': background === StorybookHeaderBackgroundEnum.BACKGROUND_5_ILLUSTRATION,
    'c-universalHeader--ratio-5-7': headerStyle.includes(StorybookHeaderStyleEnum.RATIO_5_7),
    'c-universalHeader--aligned-to-edge': headerStyle.includes(StorybookHeaderStyleEnum.ALIGNED_TO_EDGE),
    'c-universalHeader--landing-media': headerStyle.includes(StorybookHeaderStyleEnum.LANDING_MEDIA),
  });

  const getRightContent = () => {
    return rightContent
      ? rightContent
      : <StoryblokImage image={headerMedia} className="c-universalHeader__headerMedia"/>;
  };


  return (
    <div className={getHeaderClasses()}>
      <TopNavContainer
        inverted={true}
        reducedMenu={reducedMenu}
      />
      <div className="c-universalHeader__container container">
        <div className="c-universalHeader__leftContent">
          {tag && <span className="c-universalHeader__tag">{tag}</span>}
          {headline && <h1 className="c-universalHeader__headline">{headline}</h1>}
          {(leadParagraph || leadParagraphPlain) && <div className="c-universalHeader__leadParagraph">
            {leadParagraph ? render(leadParagraph) : leadParagraphPlain}
          </div>}
          {customLeftElement}
          {ctaButtonsElms.length > 0 && <div className="c-universalHeader__ctaButtonsContainer">
            {ctaButtonsElms}
          </div>}
        </div>
        <div className="c-universalHeader__rightContent">
          {getRightContent()}
        </div>
      </div>
    </div>
  );
};
