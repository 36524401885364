import React from 'react'
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import FiftyFiftyBlock, { FiftyFiftyBlockType } from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlock';
import CustomerStoryBanner, { CustomerStoryBannerType } from '../../../components/customerStoryBanner/components/CustomerStoryBanner';
import TestimonialSlider, { TestimonialSliderType } from '../../../components/testimonialsSlider/components/TestimonialSlider';
import { FullWidthCtaBannerType } from '../../../components/ctaBanner/components/FullWidthCtaBanner';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type AccuritySolutionPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  useCases: FiftyFiftyBlockType[];
  useCasesTitle: string;
  ctaBanner: { content: CtaBannerType };
  ctaBanner2: { content: FullWidthCtaBannerType };
  customerStory: { content: CustomerStoryBannerType };
  testimonialSlider: TestimonialSliderType[];
}

const AccuritySolutionPage = ({
                                seoMeta,
                                header,
                                ctaBanner,
                                ctaBanner2,
                                useCases,
                                useCasesTitle,
                                customerStory,
                                testimonialSlider
                              }: AccuritySolutionPageProps) => {

  return (
    <div className={'accuritySolutionPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]}/>

      <CtaBanner banner={ctaBanner.content} addMargin={true}/>

      <div className={'container accuritySolutionPage__contentContainer'}>

        <h2 className={'accuritySolutionPage__headline'}>{useCasesTitle}</h2>

        {useCases.map((block, index) => (
          <FiftyFiftyBlock
            key={index}
            {...block}
          />
        ))}
      </div>

      <CtaBanner banner={ctaBanner2.content}/>

      {testimonialSlider && testimonialSlider.length > 0 && <div className="container">
        <TestimonialSlider {...testimonialSlider[0]} />
      </div>}

      {customerStory && <div className="container callToAction__section">
        <CustomerStoryBanner {...customerStory.content} />
      </div>}

      <FooterContainer/>
    </div>
  )
};

export default AccuritySolutionPage;
