import React from 'react'
import classNames from 'classnames';
import { StorybookDeploymentModelTableRowType } from '../../../types/storyblokTypes';
import { render } from 'storyblok-rich-text-react-renderer-ts';
import Button from '../../../components/buttons/components/Button';
import IconLabelPair from './IconLabelPair';

export type DeploymentModelTableProps = {
  navLabels: string[];
  rows: StorybookDeploymentModelTableRowType[];
  currentColumn: number;
  setCurrentColumn: (index: number) => void
};

const DeploymentModelTable = ({
                                navLabels,
                                rows,
                                currentColumn,
                                setCurrentColumn
                              }: DeploymentModelTableProps) => {

  const getNavItemLabelClass = (index: number) => classNames('c-deploymentModelTable__navItemLabel', {
    'c-deploymentModelTable__navItemLabel--selected': index === currentColumn
  });

  const navItems = navLabels.map((label, index) => (
    <span
      className="c-deploymentModelTable__navItem"
      key={index}
    >
      <span
        className={getNavItemLabelClass(index)}
        onClick={() => setCurrentColumn(index)}
      >
        {label}
      </span>
    </span>
  ));

  const richTextRenderer = (document: any) => (
    <span className="c-deploymentModelTable__richText">
      {render(document, {
        blokResolvers: {
          'Icon Label Pair': IconLabelPair
        }
      })}
    </span>
  );

  const getRowCellClass = (index: number) => classNames('c-deploymentModelTable__rowCell', {
    'c-deploymentModelTable__rowCell--hidden': index !== currentColumn
  });

  const rowItems = rows.map((row, index) => {
    return (
      <div className="c-deploymentModelTable__row" key={index}>
        <div className="c-deploymentModelTable__rowLabel">{row.label}</div>

        {row.cloudText && <div className={getRowCellClass(0)}>
          {richTextRenderer(row.cloudText)}
        </div>}
        {row.onPremiseText && <div className={getRowCellClass(1)}>
          {richTextRenderer(row.onPremiseText)}
        </div>}
        {row.cloudCtaButton.length > 0 && <div className={getRowCellClass(0)}>
          <Button {...row.cloudCtaButton[0]}/>
        </div>}
        {row.onPremiseCtaButton.length > 0 && <div className={getRowCellClass(1)}>
          <Button {...row.onPremiseCtaButton[0]}/>
        </div>}
      </div>
    );
  });

  return (
    <div className="c-deploymentModelTable container" id="deploymentModelTable">
      <div className="c-deploymentModelTable__navigation">{navItems}</div>
      <div className="c-deploymentModelTable__content">{rowItems}</div>
    </div>
  )
};

export default DeploymentModelTable
