import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classNames from 'classnames'
import TopNav from './TopNav'
import TopNavLanding from './TopNavLanding'
import { navbarData as dummyNavBarData } from '../stories/nav.fakedata'

/**
 * There is currently a bug when using gatsby's useStaticQuery and storybook,
 * so until it is fixed we will fall back to hard-coded fake data when
 * useStaticQuery fails. See: https://github.com/gatsbyjs/gatsby/issues/26099
 */
const useNavDataOrFakeData = () => {
  try {
    const queryResult = useStaticQuery(
      graphql`
        query {
          storyblokEntry(name: { eq: "Top Navigation" }) {
            content
          }
        }
      `
    );
    return JSON.parse(queryResult.storyblokEntry.content)
  } catch {
    console.error(
      'Static Query for Top Navigation failed!!! Falling back to dummy data to populate navigation.'
    );
    return dummyNavBarData
  }
};

interface TopNavContainerProps {
  inverted?: boolean
  reducedMenu?: boolean
  hideTopBar?: boolean
}

const TopNavContainer = ({
                           inverted = false,
                           reducedMenu,
                           hideTopBar
                         }: TopNavContainerProps) => {
  const content = useNavDataOrFakeData();

  const renderClassNames = () =>
    classNames('topNavContainer', {
      'topNavContainer--reduced': reducedMenu,
      'topNavContainer--hasTopBar': !hideTopBar
    });

  // FIXME TopNav was originally position: sticky, but it means when used in Header it will only move inside the Header,
  //    so does not stay visible when scrolling further down the page.
  //    Solution is to change position: sticky to position: fixed, but now the Top Nav is completely out of flow, so all
  //    other content is moved up by the height of the top nav. Added this wrapping div with height of top nav manually
  //    set, to move the content down again by that much until we solve this properly.
  return (
    <div className={renderClassNames()}>
      {reducedMenu ? (
        <TopNavLanding inverted={inverted} {...content} />
      ) : (
        <TopNav inverted={inverted} hideTopBar={hideTopBar} {...content} />
      )}
    </div>
  )
};

export default TopNavContainer
