import React from "react"
import classNames from "classnames"
import Button from "../../buttons/components/Button"
import { StoryblokButtonType } from "../../../types/storyblokTypes"

export type FullWidthCtaBannerType = {
  component: "CTA Banner Full Width"
  title?: string
  paragraph?: string
  buttons?: StoryblokButtonType[]
}

const FullWidthCtaBanner = ({
  title,
  paragraph,
  buttons,
}: FullWidthCtaBannerType) => {
  return (
    <div className={classNames("ctaBannerFullWidth")}>
      <div className="ctaBannerFullWidth__topWave" />
      <div className="container ctaBannerFullWidth__textContent">
        {title && <h3 className="ctaBannerFullWidth__header">{title}</h3>}
        {paragraph && <p className="ctaBannerFullWidth__text">{paragraph}</p>}
      </div>
      <div className="ctaBannerFullWidth__buttons">
        {buttons &&
          buttons.map((button, idx) => <Button {...button} key={idx} />)}
      </div>
      <div className="ctaBannerFullWidth__backgroundDots" />
      <div className="ctaBannerFullWidth__bottomWave" />
    </div>
  )
}

export default FullWidthCtaBanner
