import React from 'react';
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import { StoryblokImageType } from '../../../types/storyblokTypes';

export type ThreeElementBlockType = {
  icon: StoryblokImageType;
  title: string;
  paragraph: string;
}

const ThreeElementBlock = ({ icon, title, paragraph }: ThreeElementBlockType) => {
  return (
    <div className={'threeElementBlock'}>
      <div className={'threeElementBlock__iconContainer'}>
        <StoryblokImage image={icon}/>
      </div>
      <h3>{title}</h3>
      <p>{paragraph}</p>
    </div>
  );
};

export default ThreeElementBlock;
