import React from 'react';

export type FormContainerProps = {
  title?: string;
  style?: 'default' | 'compact'
}

const FormContainer = ({ title, style, children }: React.PropsWithChildren<FormContainerProps>) => {
  return (
    <div className={`form__container ${style ? 'form__container--' + style : ''}`}>
      {title ? <h3 className="form__headline">{title}</h3> : null}
      {children}
    </div>
  );
};

export default FormContainer;
