import React from 'react';
import classNames from 'classnames';
import BasicCtaBanner, { BasicCtaBannerType } from './BasicCtaBanner';
import CtaNewsletterBanner, { CtaNewsletterBannerProps } from './CtaNewsletterBanner';
import FullWidthCtaBanner, { FullWidthCtaBannerType } from './FullWidthCtaBanner';
import CtaBannerPOC, { CtaBannerPOCType } from './CtaBannerPOC';
import BlogBanner, { BlogBannerType } from './BlogBanner';

export type CtaBannerType = BasicCtaBannerType | CtaNewsletterBannerProps | FullWidthCtaBannerType | CtaBannerPOCType | BlogBannerType;

export type CtaBannerProps = {
  banner?: CtaBannerType;
  addMargin?: boolean;
  className?: string;
}

const CtaBanner = ({ banner, addMargin, className }: CtaBannerProps) => {

  if (!banner) {
    return null;
  }

  const narrowBannerWrapperClassname = classNames(`ctaBanner__centerWrapper container`, className, {
    'ctaBanner__centerWrapper--withMargin': addMargin
  });

  switch (banner.component) {
    case 'ctaBanner':
      return <div className={narrowBannerWrapperClassname}>
        <BasicCtaBanner {...banner}/>
      </div>;
    case 'CTA Newsletter Banner':
      return <div className={narrowBannerWrapperClassname}>
        <CtaNewsletterBanner {...banner}/>
      </div>;
    case 'CTA Banner POC':
      return <div className={narrowBannerWrapperClassname}>
        <CtaBannerPOC {...banner}/>
      </div>;
    case 'CTA Banner Full Width':
      return <FullWidthCtaBanner {...banner}/>;
    case 'Blog Banner':
      return <BlogBanner {...banner} />;
    default:
      console.log('Missing banner', banner);
      return null
  }
};

export default CtaBanner;
