import React from 'react';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import TopNavContainer from '../../../navigation/components/TopNavContainer';
import FreeText, { FreeTextType } from '../../../components/freeText/components/FreeText';
import { LogoStripe } from '../../../components/logoStripe/components/LogoStripe';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import { StoryblokLogoStripeType } from '../../../types/storyblokTypes';
import { CalendlyWidget } from '../../../components/calendly/components/CalendlyWidget';

export type QualityTrialExpiredPageProps = {
  seoMeta: AccuritySeoMetaType
  text: FreeTextType[]
  logoStripe: { content: StoryblokLogoStripeType };
}

export const QualityTrialExpiredPage = ({
                                          seoMeta,
                                          logoStripe,
                                          text
                                        }: QualityTrialExpiredPageProps) => {

  return (
    <div className="qualityTrialExpiredPage">
      <AccuritySeoMeta {...seoMeta} />
      <TopNavContainer hideTopBar={true} reducedMenu={true}/>
      <div className="qualityTrialExpiredPage__contentContainer container">
        <div className="qualityTrialExpiredPage__freeTextColumn">
          {text ? <FreeText {...text[0]} /> : null}
        </div>
        <div className="qualityTrialExpiredPage__formColumn">
          <div className="form__container">
            <CalendlyWidget/>
          </div>
        </div>
        <div className="qualityTrialExpiredPage__trustedBy">
          {logoStripe?.content ? <LogoStripe
            {...logoStripe.content}
            title={undefined}
            variant={'demo-page'}
          /> : null}
        </div>
      </div>
      <FooterContainer isCompact={true}/>
    </div>
  )
};