import React from 'react'
import classNames from 'classnames'
import Button from '../../buttons/components/Button'
import { StoryblokImageType, StoryblokLinkType } from '../../../types/storyblokTypes'
import BigBanner from './BigBanner';

export type BasicCtaBannerType = {
  component: 'ctaBanner'
  title?: string
  paragraph?: string
  ctaButton: string
  ctaLink: StoryblokLinkType
  image?: StoryblokImageType
  notes?: string
  style?: any
}

const BasicCtaBanner = ({
                          title,
                          paragraph,
                          ctaButton,
                          ctaLink,
                          notes,
                          style,
                          image
                        }: BasicCtaBannerType) => {

  if (style === 'with-image' && image && title && paragraph) {
    return <BigBanner
      title={title}
      text={paragraph}
      linkLabel={ctaButton}
      link={ctaLink}
      image={image}
    />;
  }

  return (
    <div
      className={classNames('ctaBanner', { [`ctaBanner--${style}`]: style })}
    >
      <div className="ctaBanner__textContent">
        {title && <h3 className="ctaBanner__header">{title}</h3>}
        {paragraph && <p className="ctaBanner__text">{paragraph}</p>}
      </div>
      <Button
        severity="primary"
        label={ctaButton}
        link={ctaLink ? ctaLink : undefined}
      />
      {notes && <p className={'ctaBanner__notes'}>{notes}</p>}
    </div>
  )
};

export default BasicCtaBanner
