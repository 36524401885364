import React from 'react';

export type CheckBoxProps = {
  name: string;
  label: string | JSX.Element;
  value?: boolean;
  setValue: (name: string, value?: boolean) => void;
  error?: string;
  clearError?: (name: string) => void;
}

const CheckBox = ({ name, label, value, setValue, error, clearError }: CheckBoxProps) => {
  const handleChange = () => {
    if (error && clearError) {
      clearError(name);
    }
    setValue(name, !value);
  };

  return (
    <div className="checkBox">
      <div className="checkBox__labelContainer">
        <div
          className={`checkBox__box ${value ? 'checkBox__box--checked' : ''}`}
          onClick={handleChange}
        />
        <span className="checkBox__label">
          {label}
        </span>
      </div>
      {error &&
      <div className="checkBox__errorContainer">
        <span className="checkBox__error">{error}</span>
      </div>
      }
    </div>
  );
};

export default CheckBox;
