import * as React from 'react'
import classNames from 'classnames'
import { StoryblokLinkType, } from '../../types/storyblokTypes'
import { getUrlFromLink } from '../../utils/linkUtils'
// @ts-ignore
import ArrowIcon from '../../images/icons/dropdown.svg'

export type TopNavMenuItemContainerProps = {
  label: string;
  isExpanded?: boolean
  style?: string;
  link?: StoryblokLinkType;
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  Component?: 'div' | 'li'
}

const TopNavMenuItemContainer = ({
                                   label,
                                   isExpanded,
                                   style,
                                   link,
                                   children,
                                   onClick,
                                   onMouseEnter,
                                   onMouseLeave,
                                   className,
                                   Component = 'li'
                                 }: React.PropsWithChildren<TopNavMenuItemContainerProps>) => {

  const hasDropdown = !!children;

  let labelElm = null;

  if (hasDropdown) {
    labelElm = (
      <span
        className="topNavItem__label hover-bcgUnderline"
        onMouseEnter={onMouseEnter}
        onClick={onClick}
      >
      <span>{label}</span>
      <ArrowIcon/>
    </span>
    )
  } else if (link) {
    labelElm = (
      <a href={getUrlFromLink(link)} className="topNavItem__label hover-bcgUnderline">
        <span>{label}</span>
      </a>
    )
  }

  return (
    <Component
      className={classNames('topNavItem', className, {
        'topNavItem--expanded': isExpanded,
        [`topNavItem--${style}`]: !!style,
      })}
      onMouseLeave={onMouseLeave}
    >
      <span
        className={classNames('topNavItem__labelWrapper', {
          'topNavItem__labelWrapper--active': !!isExpanded,
        })}
      >
        {labelElm}
      </span>
      {children}
    </Component>
  );
};

export default TopNavMenuItemContainer
