import React, { useState } from 'react'
import TextField from '../../form/components/TextField'
import FormRow from '../../form/components/FormRow'
import CheckBox from '../../form/components/CheckBox'
import { DEMO_THANK_YOU_PAGE_URL } from '../../../rest/endpoints'
import { FormLabels } from '../../form/types'
import { useZohoFormLogic, ZohoFormFields, } from '../../form/zohoLogic'
// @ts-ignore
import { ZOHO_FORM_IDS } from '../zohoConfig';
import SubmitButton from '../../form/components/SubmitButton';
import { CalendlyWidget } from '../../calendly/components/CalendlyWidget';
import { navigate } from 'gatsby-link';
import FormContainer, { FormContainerProps } from '../../form/components/FormContainer';
import { pushDataLayerEvent } from '../../../utils/gtmUtils';
import PhoneField from '../../form/components/PhoneField';
import { getMarketingConsent } from '../utils/marketingConsent';

const ZOHO_DEMO_SIGN_UP_SUBMIT_URL =
  `https://form.accurity.ai/simplitygroup/form/${ZOHO_FORM_IDS.DEMO_SIGNUP}/htmlRecords/submit`;

const SUBMIT_FORM_DATA_LAYER_EVENT = 'demo-registration';

export type DemoSignUpType = {
  firstName: string
  lastName: string
  company: string
  jobTitle: string
  email: string
  phone: string
  newsletter: boolean
  marketing: boolean
}

export type ZohoDemoSignUpFormProps = {
  formLabels: FormLabels;
  formSource: string;
  style?: FormContainerProps['style'];
}

const ZohoDemoSignUpForm = ({
                              formLabels,
                              formSource,
                              style
                            }: ZohoDemoSignUpFormProps) => {
  const zohoFields: ZohoFormFields<DemoSignUpType> = {
    firstName: {
      zohoFieldName: 'SingleLine7',
      label: formLabels.firstNameLabel,
      initialValue: '',
      required: true,
    },
    lastName: {
      zohoFieldName: 'SingleLine8',
      label: formLabels.lastNameLabel,
      initialValue: '',
      required: true,
    },
    company: {
      zohoFieldName: 'SingleLine9',
      label: formLabels.companyLabel,
      initialValue: '',
      required: true,
    },
    jobTitle: {
      zohoFieldName: 'SingleLine10',
      label: formLabels.jobTitleLabel,
      initialValue: '',
      required: true,
    },
    phone: {
      zohoFieldName: 'SingleLine11',
      label: formLabels.phoneLabel,
      initialValue: '',
      required: true,
    },
    email: {
      zohoFieldName: 'Email',
      label: formLabels.emailLabel,
      initialValue: '',
      required: true,
      runEmailValidation: true,
    },
    newsletter: {
      zohoFieldName: 'DecisionBox',
      initialValue: false,
      required: false,
    },
    marketing: {
      zohoFieldName: 'DecisionBox1',
      initialValue: false,
      required: false,
    },
  };

  const [isSchedulerLoaded, setSchedulerLoaded] = useState<boolean>(false);
  const [isFormSubmitted, setFormSubmitted] = useState<boolean>(false);

  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
    setError
  } = useZohoFormLogic<DemoSignUpType>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_DEMO_SIGN_UP_SUBMIT_URL,
    successRedirectUrl: '', // thank-you page is shown after calendly event is scheduled
    submitErrorMessage: formLabels.submitErrorMessage || '',
    zohoFormFields: zohoFields,
    onSubmit: () => {
      pushDataLayerEvent(SUBMIT_FORM_DATA_LAYER_EVENT);
      setFormSubmitted(true)
    },
  });

  const DemoScheduler = (
    <div className={'demoScheduler__calendlyWidget'}>
      <CalendlyWidget
        prefill={{
          name: values.firstName + ' ' + values.lastName,
          email: values.email,
          customAnswers: {
            a1: values.company
          }
        }}
        onLoad={() => !isSchedulerLoaded ? setSchedulerLoaded(true) : undefined}
        onEventScheduled={() => navigate(DEMO_THANK_YOU_PAGE_URL)}
      />
    </div>
  );

  return (
    <FormContainer title={!isSchedulerLoaded ? formLabels.title : undefined} style={style}>
      {!isFormSubmitted && (
        <>
          <FormRow>
            <TextField
              name="firstName"
              label={zohoFields.firstName.label}
              value={values.firstName}
              setValue={setValue}
              error={errors.firstName}
              clearError={clearError}
            />
            <TextField
              name="lastName"
              label={zohoFields.lastName.label}
              value={values.lastName}
              setValue={setValue}
              error={errors.lastName}
              clearError={clearError}
            />
          </FormRow>
          <TextField
            name="company"
            label={zohoFields.company.label}
            value={values.company}
            setValue={setValue}
            error={errors.company}
            clearError={clearError}
          />
          <TextField
            name="jobTitle"
            label={zohoFields.jobTitle.label}
            value={values.jobTitle}
            setValue={setValue}
            error={errors.jobTitle}
            clearError={clearError}
          />
          <TextField
            name="email"
            label={zohoFields.email.label}
            value={values.email}
            setValue={setValue}
            error={errors.email}
            clearError={clearError}
          />
          <PhoneField
            name="phone"
            label={zohoFields.phone.label}
            value={values.phone}
            setValue={setValue}
            error={errors.phone}
            clearError={clearError}
            setError={setError}
          />
          <div className="form__checkBoxContainer">
            <CheckBox
              name="marketing"
              label={getMarketingConsent()}
              value={values.marketing}
              setValue={setValue}
            />
          </div>
          <div className="form__buttonContainer">
            <SubmitButton
              isFetching={isFetching}
              label={formLabels.ctaButtonLabel}
              doSubmit={doSubmit}
            />
            {!!submitError && <p className="form__submit-error">{submitError}</p>}
          </div>
        </>
      )}
      {isFormSubmitted ? DemoScheduler : null}
    </FormContainer>
  )
};
export default ZohoDemoSignUpForm
