import React from 'react';
import classNames from 'classnames';
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import PlayIcon from '../../../images/icons/youtube-play.svg';
import { StoryblokImageType } from '../../../types/storyblokTypes';

type VideoThumbnailProps = {
  image: StoryblokImageType;
  onClick?: () => void;
  className?: string;
  imageClassName?: string;
};

export const VideoThumbnail = ({
                                 className,
                                 image,
                                 onClick,
                                 imageClassName
                               }: VideoThumbnailProps) => {
  return (
    <div
      className={classNames('c-videoThumbnail', className)}
      onClick={onClick}
    >
      <StoryblokImage
        image={image}
        className={classNames('c-videoThumbnail__image', imageClassName)}
      />
      <PlayIcon
        className="c-videoThumbnail__playVideoIcon"
      />
    </div>
  )
};
