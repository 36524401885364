import React from 'react'
import classNames from 'classnames'
import StoryblokImage from '../../storyblokImage/components/StoryblokImage'
import { StoryblokImageType, StoryblokLinkType, } from '../../../types/storyblokTypes'
import { getUrlFromLink } from '../../../utils/linkUtils'
import Button, { ButtonProps } from '../../buttons/components/Button';

enum FiftyFiftyBlockStyle {
  REGULAR = 'regural',
  WIDER_IMAGE = 'wider-image',
}

export type FiftyFiftyBlockType = {
  mediaAsset: StoryblokImageType
  title: string
  paragraph: string
  button: ButtonProps[];
  ctaButton: string
  ctaLink: StoryblokLinkType
  bulletPoints: string
  style: FiftyFiftyBlockStyle;
}

type FiftyFiftyBlockProps = {
  version?: number;
} & FiftyFiftyBlockType;

/**
 * @deprecated
 *
 * Use FiftyFiftyBlockV2 instead
 */
const FiftyFiftyBlock = ({
                           mediaAsset,
                           title,
                           paragraph,
                           button,
                           ctaButton,
                           ctaLink,
                           bulletPoints,
                           style,
                           version
                         }: FiftyFiftyBlockProps) => {
  const bulletPointsItems =
    bulletPoints && bulletPoints.length > 0 ? bulletPoints.split('\n') : [];

  const getMainClass = () => classNames('fiftyFiftyBlock', {
    'fiftyFiftyBlock--regular': style === FiftyFiftyBlockStyle.REGULAR,
    'fiftyFiftyBlock--wider-image': style === FiftyFiftyBlockStyle.WIDER_IMAGE,
    'fiftyFiftyBlock--version2': version === 2,
  });

  return (
    <div className={getMainClass()}>
      <div className={'fiftyFiftyBlock__mediaAssetContainer'}>
        <StoryblokImage image={mediaAsset}/>
      </div>
      <div className={'fiftyFiftyBlock__textContainer'}>
        <h2>{title}</h2>
        <p>{paragraph}</p>
        {bulletPointsItems.length > 0 ? (
          <ul
            className={classNames('fiftyFiftyBlock__bulletPoints', {
              'fiftyFiftyBlock__bulletPoints--multiple':
                bulletPointsItems.length > 1,
            })}
          >
            {bulletPointsItems.map((text, idx) => (
              <li key={idx}>{text}</li>
            ))}
          </ul>
        ) : null}
        {ctaButton && ctaLink ? (
          <a
            className={'fiftyFiftyBlock__callToAction'}
            href={getUrlFromLink(ctaLink)}
          >
            {ctaButton}
          </a>
        ) : null}
        {button && button[0] && <Button {...button[0]} className={'fiftyFiftyBlock__callToAction'}/>}
      </div>
    </div>
  )
};

export default FiftyFiftyBlock
