import React, { useState, useEffect } from "react"

import { checkLinkUrl } from "../../../utils/linkUtils"

import { StoryblokLinkType } from "../../../types/storyblokTypes"

type StoryblokLinkProps = {
  to?: StoryblokLinkType
  className?: string
  children: React.ReactNode
}

const StoryblokLink = ({ to, className, children }: StoryblokLinkProps) => {
  const [link, setLink] = useState("/")

  useEffect(() => {
    if (to?.linktype === "story") {
      const checkedLink = checkLinkUrl(to?.cached_url)
      if (!to.anchor) {
        setLink(checkedLink)
      } else {
        setLink(checkedLink + "#" + to.anchor)
      }
    } else {
    }
  }, [to])

  let finalLink = null

  if (to?.linktype === "story") {
    finalLink = (
      <a href={link} className={className}>
        {children}
      </a>
    )
  } else {
    finalLink = (
      <a
        href={to?.cached_url}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }

  return finalLink
}

export default StoryblokLink
