import React, { useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { Prefill } from 'react-calendly/typings/calendly';

export const DEFAULT_CALENDLY_URL = 'https://calendly.com/accurity/discovery?text_color=363d4a&primary_color=e35205&hide_gdpr_banner=1';

interface CalendlyWidgetProps {
  url?: string;
  prefill?: Prefill;
  onLoad?: () => void;
  onEventScheduled?: () => void;
}

export const CalendlyWidget = ({
                                 url = DEFAULT_CALENDLY_URL,
                                 onLoad,
                                 onEventScheduled,
                                 prefill
                               }: CalendlyWidgetProps) => {

  const [isDateAndTimeSelected, setIsDateAndTimeSelected] = useState(false);

  useCalendlyEventListener({
    onEventTypeViewed: () => {
      if (onLoad) {
        onLoad();
      }
      setIsDateAndTimeSelected(false);
    },
    onEventScheduled: () => onEventScheduled ? onEventScheduled() : undefined,
    onDateAndTimeSelected: () => setIsDateAndTimeSelected(true),
  });

  return (
    <div className="c-calendlyWidget">
      <InlineWidget
        url={url}
        pageSettings={{
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
        }}
        styles={{
          height: isDateAndTimeSelected ? '970px' : '600px',
          width: '100%',
        }}
        prefill={prefill}
      />
    </div>
  );
};
