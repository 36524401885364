export const doFetch = async (url: string,
                              method: 'GET' | 'POST',
                              body?: string | FormData,
                              isZohoFormSubmission?: boolean) => {

  const headers = new Headers();
  if (!isZohoFormSubmission) {
    headers.append('Content-Type', 'application/json');
  }

  const response = await fetch(url, {
    method: method,
    body: body,
    headers: headers,
    mode: isZohoFormSubmission ? 'no-cors' : 'cors',
  });

  if (!isZohoFormSubmission && response.redirected && typeof window !== 'undefined') {
    window.location.href = response.url;
  }

  if (isZohoFormSubmission) {
    return response;
  } else {
    return parseResponse(response);
  }

};

const parseResponse = async (response: Response) => {
  let responseBody;
  try {
    if (response.headers.get('Content-Type') === 'application/json') {
      responseBody = await response.json();
    } else if (response.headers.get('Content-Type')?.includes('text/plain')) {
      responseBody = await response.text();
    }
  } catch (e) {
    console.error('Could not parse response body! ', e);
    return Promise.reject({ errorCode: response.status, errorBody: undefined });
  }

  if (response.ok) {
    return responseBody;
  } else {
    return Promise.reject({ errorCode: response.status, errorBody: responseBody });
  }
};
