import React from 'react'
import ThreeElementBlockContainer from '../../../components/threeElementBlock/components/ThreeElementBlockContainer';
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import SubHeading from '../../../components/subHeading/components/SubHeading';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';
import CustomerStoryBanner, { CustomerStoryBannerType } from '../../../components/customerStoryBanner/components/CustomerStoryBanner';
import FiftyFiftyBlock, { FiftyFiftyBlockType } from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlock';
import TestimonialSlider from '../../../components/testimonialsSlider/components/TestimonialSlider';
import { TestimonialType } from '../../../components/testimonialsSlider/components/Testimonial';
import { ResourceCardType, StoryblokImageType, StoryblokLogoStripeType } from '../../../types/storyblokTypes';
// @ts-ignore
import guyImage from '../../../images/testimonialForm-guy.png'
import ResourceCardsContainer from '../../../components/resourceCardContainer/components/ResourceCardsContainer';
import { LogoStripe } from '../../../components/logoStripe/components/LogoStripe';
import Posts from '../../../components/posts/components/Posts';

const testimonialGuyImage: StoryblokImageType = {
  filename: guyImage
};

export type SolutionsDetailPageV2Props = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  title: string;
  paragraph: string;
  useCases: ThreeElementBlockType[];
  useCasesTitle: string;
  ctaBanner?: { content: CtaBannerType };
  ctaBanner2?: { content: CtaBannerType };
  customerStory: { content: CustomerStoryBannerType };
  logoStripeTitle: string;
  logoStripe: { content: StoryblokLogoStripeType };
  featuresTitle: string;
  featuresBlocks: FiftyFiftyBlockType[];
  testimonialTitle: string;
  testimonial: { content: TestimonialType };
  resources: ResourceCardType[];
  resourcesTitle: string;
  resourcesType: 'carousel' | 'big-cards';
}

const SolutionsDetailPageV2 = ({
                                 seoMeta,
                                 header,
                                 title,
                                 paragraph,
                                 useCases,
                                 useCasesTitle,
                                 ctaBanner,
                                 ctaBanner2,
                                 customerStory,
                                 featuresBlocks,
                                 featuresTitle,
                                 testimonial,
                                 testimonialTitle,
                                 resources,
                                 resourcesTitle,
                                 logoStripe,
                                 logoStripeTitle,
                                 resourcesType = 'carousel'
                               }: SolutionsDetailPageV2Props) => {
  return (
    <div className={`solutionsDetailPageV2 lightGreyBcg`}>
      <AccuritySeoMeta {...seoMeta} />
      {header && <UniversalHeader {...header[0]} />}

      <div className="container solutionsDetailPageV2__firstSubheading sectionMargins">
        <SubHeading title={title} paragraph={paragraph}/>
      </div>

      {customerStory && <div className="container container--centered sectionMargins">
        <CustomerStoryBanner {...customerStory?.content} />
      </div>}

      {logoStripe?.content ? <div className="sectionMargins">
        <LogoStripe logos={logoStripe.content.logos} title={logoStripeTitle || logoStripe.content.title}/>
      </div> : null}

      <div className="container solutionsDetailPageV2__useCases sectionMargins">
        <SubHeading title={useCasesTitle}/>
        <ThreeElementBlockContainer
          blocksPerRow={4}
          blocks={useCases}
        />
      </div>

      <div className="sectionMargins">
        <CtaBanner banner={ctaBanner?.content}/>
      </div>

      <div className="container solutionsDetailPageV2__features sectionMargins">
        <SubHeading title={featuresTitle}/>
        {featuresBlocks?.map((block, index) => (
          <FiftyFiftyBlock
            key={index}
            {...block}
            version={2}
          />
        ))}
      </div>

      {testimonial?.content && <div className="container sectionMargins">
        <TestimonialSlider
          headline={testimonialTitle}
          cycleFrequencySeconds={0}
          testimonials={[testimonial]}
          rightImage={testimonialGuyImage}
        />
      </div>}

      {ctaBanner2?.content ? <div className="sectionMargins">
        <CtaBanner banner={ctaBanner2?.content}/>
      </div> : null}

      {resources?.length > 0 ? (
        resourcesType == 'carousel' ? <div className="sectionMargins">
          <ResourceCardsContainer
            resources={resources || []}
            title={resourcesTitle}
          />
        </div> : <Posts
          title={resourcesTitle}
          articles={resources.slice(0, 2)}
        />
      ) : null}

      <FooterContainer/>
    </div>
  )
};

export default SolutionsDetailPageV2;
