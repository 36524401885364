import React from 'react'
import classNames from 'classnames';

export type PricingTabsNavigationProps = {
  labels: string[];
  activeTabIndex: number;
  onChange: (tabIndex: number) => void;
}

const PricingTabsNavigation = ({ activeTabIndex, labels, onChange }: PricingTabsNavigationProps) => {

  const getTabClassNames = (index: number) => classNames('pricingTabsNavigation__tab', {
    'pricingTabsNavigation__tab--active': index === activeTabIndex
  });

  const tabWidth = 100 / labels.length; // in %

  return (
    <div className="pricingTabsNavigation">

      <div className="container">
        <div className="pricingTabsNavigation__tabsContainer">

          <div className="pricingTabsNavigation__tabsBackground">
            {Array(labels.length - 1).fill(0).map((_, index) => (
              <div className="pricingTabsNavigation__tabsDivider" key={index}/>
            ))}
          </div>

          <div className="pricingTabsNavigation__activeTab" style={{ width: `calc(${tabWidth}% + 2px)`, left: `${activeTabIndex * tabWidth}%` }}>
            <div className="pricingTabsNavigation__activeTabLeftRoundedCorner"/>
            <div className="pricingTabsNavigation__activeTabRightRoundedCorner"/>
          </div>

          {labels.map((label, index) => (
            <div className={getTabClassNames(index)} key={index} onClick={() => onChange(index)}>
              <div className="pricingTabsNavigation__tabLabel">
                {label}
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>
  )
};

export default PricingTabsNavigation;
