import React from 'react'
import { StoryblokRTEListItem } from '../../../types/storyblokTypes';
import { render } from 'storyblok-rich-text-react-renderer-ts'
import classNames from 'classnames';

const RTEListItem = ({
                       type,
                       number,
                       content
                     }: StoryblokRTEListItem) => {

  return (
    <div className={classNames('RTEListItem', type)}>
      <div className="RTEListItem__number">{number}</div>
      <div className="RTEListItem__content">{render(content)}</div>
    </div>
  )
};

export default RTEListItem;
