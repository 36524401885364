import React from 'react'

import StoryblokLink from '../../storyblokLink/components/StoryblokLink'
import { getResourceCardData } from '../../resourceCardContainer/utils';
import { ResourceCardType } from '../../../types/storyblokTypes';

type PostsType = {
  title: string
  articles: ResourceCardType[]
  label?: string
}

const Posts = ({
                 title,
                 articles,
                 label
               }: PostsType) => {

  const cards = articles?.map((article, i) => {
    const cardData = getResourceCardData(article);

    return cardData ? (
      <StoryblokLink to={cardData.link} className="c-posts__item" key={`blog-item-${i}`}>
        <div className="c-posts__item-hoverWrapper">
          <div
            className="c-posts__item-image"
            style={{
              backgroundImage: `url(${cardData.image?.filename})`,
            }}
          />
          <div className="c-posts__item-content">
            {cardData.tag && (
              <div>
                <span className="c-posts__item-tag">{cardData.tag}</span>
              </div>
            )}
            <h3 className="c-posts__item-title">{cardData.title}</h3>
            <span className="c-posts__item-link">{label || cardData.ctaLabel}</span>
          </div>
        </div>
      </StoryblokLink>
    ) : null;
  });

  return (
    <section className="c-posts">
      <div className="container">
        <div className="c-posts__content">
          <h2 className="c-posts__title">{title}</h2>
          <div className="c-posts__posts">{cards}</div>
        </div>
      </div>
    </section>
  )
};

export default Posts
