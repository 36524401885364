import React from "react"
import { Helmet } from "react-helmet"
import { useUtmTracking } from "../cookies/utmTrackingLogic"

const DEFAULT_ACCURITY_TITLE = "Accurity Data Intelligence Platform"

export type AccuritySeoMetaType = {
  title?: string
  description?: string
  ogImage?: string
  canonicalLink?: string
  noIndex?: boolean
}

const AccuritySeoMeta = ({
  title = DEFAULT_ACCURITY_TITLE,
  description,
  ogImage,
  canonicalLink,
  noIndex,
}: AccuritySeoMetaType) => {
  useUtmTracking()
  const meta = []
  const link = []

  meta.push({ name: `twitter:card`, content: `summary_large_image` })

  if (title) {
    meta.push({ name: `og:title`, content: title })
    meta.push({ property: `og:title`, content: title })
    meta.push({ name: `twitter:title`, content: title })
  }

  if (description) {
    meta.push({ name: `description`, content: description })
    meta.push({ name: `og:description`, content: description })
    meta.push({ property: `og:description`, content: description })
    meta.push({ name: `twitter:description`, content: description })
  }

  if (canonicalLink) {
    meta.push({ name: `og:url`, content: canonicalLink })
    meta.push({ property: `og:url`, content: canonicalLink })
    link.push({ rel: "canonical", href: canonicalLink })
  }

  if (ogImage) {
    meta.push({ name: `og:image`, content: ogImage })
    meta.push({ property: `og:image`, content: ogImage })
    meta.push({ name: `twitter:image`, content: ogImage })
  }

  if (noIndex) {
    meta.push({ name: "robots", content: "noindex" })
  }

  return (
    <>
      <Helmet
        title={title}
        script={[
          {
            innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied",
                functionality_storage: "denied",
                personalization_storage: "denied",
                security_storage: "granted",
                wait_for_update: 2000,
            });
            gtag("set", "ads_data_redaction", true);`,
          },
        ]}
        meta={meta}
        link={link}
      />
    </>
  )
}

export default AccuritySeoMeta
