import * as React from 'react'
import { ResourceCardType } from '../types';
import { createResourceCardElement } from '../utils';

export type ThreeResourceCardContainerProps = {
  title?: string;
  resources: ResourceCardType[];
};

const ThreeResourceCardsContainer = ({ resources, title }: ThreeResourceCardContainerProps) => {

  resources = (resources || []).slice(0, 3);

  return (
    <div className="container">
      <div className="threeResourceCardContainer">
        {title && <h2 className="threeResourceCardContainer__title">{title}</h2>}
        <div className="threeResourceCardContainer__cardsWrapper">
          {resources.map(createResourceCardElement)}
        </div>
      </div>
    </div>
  );
};

export default ThreeResourceCardsContainer;
