import React, { useMemo } from 'react';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import { StoryblokImageType } from '../../../types/storyblokTypes';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer-ts';
import RichTextCard from '../../../components/cards/components/RichTextCard';
import { Filtering } from '../../../components/filtering/components/Filtering';
import { useFiltering } from '../../../components/filtering/hooks/useFiltering';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import { hasCommonTag } from '../../../components/filtering/utils';
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import { usePagination } from '../../../hooks/usePagination';
import Button from '../../../components/buttons/components/Button';
import { Progress } from '../../../components/progress/Progress';

type FeatureCard = {
  image: StoryblokImageType;
  title: string;
  text: StoryblokRichtext;
  tags: string[];
  tags2: string[];
  releaseDate: string;
  isNewFeature: boolean;
}

type AllFeaturesPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  cards: FeatureCard[];
  ctaBanner: { content: CtaBannerType };
}

const filters = {
  'status': {
    label: 'Status',
    options: ['All features', 'New features'],
    preselectedOptions: ['All features']
  },
  'solutions': {
    label: 'Solutions',
    options: [
      'Business glossary & data catalog',
      'Data quality & data observability',
      'Business data model & data catalog',
      'Reference data management',
      'Add-on: Business process lineage',
      'Add-on: Technical data mappings',
      'Add-on: LDAP',
    ],
    multiSelection: true
  },
  'pricing': {
    label: 'Pricing plan',
    options: [
      'Starter',
      'Professional',
      'Premium',
    ],
    multiSelection: true
  }
};

const AllFeaturesPage = ({
                           header,
                           cards,
                           seoMeta,
                           ctaBanner
                         }: AllFeaturesPageProps) => {

  const { activeFilters, config, resetFilters, toggleFilterValue } = useFiltering<typeof filters>(filters);

  const filteredCards = useMemo(() => {
    const { pricing, solutions, status } = activeFilters;

    const isCardValid = ({ tags, tags2, isNewFeature }: FeatureCard) => {
      return (!status.includes('New features') || isNewFeature) &&
        (!solutions.length || hasCommonTag(tags, solutions)) &&
        (!pricing.length || hasCommonTag(tags2, pricing))
    };

    return cards.filter(isCardValid);
  }, [cards, activeFilters]);

  const { isLoadMoreButtonVisible, loadMore, shownItems, progress } = usePagination(filteredCards);

  return (
    <div className="allFeaturesPage lightGreyBcg">
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />
      <div className="container allFeaturesPage__mainContent sectionMargins">

        <Filtering
          activeFilters={activeFilters}
          onChange={toggleFilterValue as any}
          filters={config}
          onReset={resetFilters}
        />
        <div className="allFeaturesPage__cardsContainer">
          {shownItems.map((card, index) => {
            return (
              <RichTextCard
                image={card.image}
                title={card.title}
                text={card.text}
                tags={card.tags}
                tags2={card.tags2}
                note={card.releaseDate}
                key={index}
              />
            )
          })}
          <div className="allFeaturesPage__paginationContainer">
            {isLoadMoreButtonVisible && <Button severity={'primary'} label={'LOAD MORE FEATURES'} onClick={loadMore}/>}
            <Progress progress={progress}/>
          </div>
        </div>
      </div>
      {ctaBanner?.content ? <div className="sectionMargins">
        <CtaBanner banner={ctaBanner.content}/>
      </div> : null}
      <FooterContainer/>
    </div>
  )
};

export default AllFeaturesPage;
