import React from 'react'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import AccuritySeoMeta from '../../../components/AccuritySeoMeta'
import { WhitepaperType } from '../../../types/storyblokTypes'
import WhitepaperHero from './WhitepaperHero'
import FreeText from '../../../components/freeText/components/FreeText'
import StoryblokImage from '../../../components/storyblokImage/components/StoryblokImage'
import TestimonialSlider from '../../../components/testimonialsSlider/components/TestimonialSlider'
import ErrorBoundary from '../../../components/ErrorBoundary'
import ZohoWhitepaperForm from '../../../components/zohoForms/components/WhitepaperForm'

const WhitepaperPage = ({
                          _uid,
                          bodyImage,
                          bodyText,
                          docName,
                          docPath,
                          headerImage,
                          seoMeta,
                          tag,
                          testimonialSlider,
                          text,
                          title,
                          form,
                          formSource,
                        }: WhitepaperType) => {
  return (
    <div className={'whitepaperPage'}>
      <AccuritySeoMeta {...seoMeta} />

      <WhitepaperHero
        title={title}
        tag={tag}
        text={text}
        headerImage={headerImage}
        formLabels={form?.content}
        formSource={formSource}
        docPath={docPath}
        docName={docName}
      />

      <div className="container">
        <div className="whitepaperPage__freeText">
          <FreeText richText={bodyText} listStyle={'bullets'}/>

          <StoryblokImage
            image={bodyImage}
            className={'whitepaperHero__freeTextImage'}
          />
        </div>

        <ErrorBoundary placeholderStyle={{ width: '100px', height: '100px' }}>
          <TestimonialSlider {...testimonialSlider[0]} />
        </ErrorBoundary>

        <div className="container whitepaperPage__bottomForm hashTarget" id="form">
          <ZohoWhitepaperForm
            formLabels={form?.content}
            formSource={formSource}
            docPath={docPath}
            docName={docName}
          />
        </div>
      </div>

      <FooterContainer/>
    </div>
  )
};

export default WhitepaperPage
