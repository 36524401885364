import React from 'react'

import StoryblokLink from '../../../components/storyblokLink/components/StoryblokLink'
import { TopNavDropdownMainProps } from './NavDropdownMainLink'

type TopNavDropdownSmallProps = {
  links: TopNavDropdownMainProps[]
}

const NavDropdownSmallItems = ({ links }: TopNavDropdownSmallProps) => {
  const smallItems = links?.map((item, i) => (
    <li key={`navigation-small-item-${i}`}>
      <StoryblokLink to={item?.link}>{item?.label}</StoryblokLink>
    </li>
  ))

  return (
    <li>
      <ul className="topNavDropdown__smallList">{smallItems}</ul>
    </li>
  )
}

export default NavDropdownSmallItems
