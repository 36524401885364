import React from 'react'
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import FiftyFiftyBlock, { FiftyFiftyBlockType, } from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlock';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock';
import ThreeElementBlockContainer from '../../../components/threeElementBlock/components/ThreeElementBlockContainer';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type PlatformOverviewPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  benefit: ThreeElementBlockType[];
  modules: FiftyFiftyBlockType[];
  ctaBanner: { content: CtaBannerType };
}

const PlatformOverviewPage = ({ seoMeta, header, benefit, modules, ctaBanner }: PlatformOverviewPageProps) => {
  return (
    <div className={'platformOverviewPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]}/>
      <div className={'container platformOverviewPage__contentContainer'}>
        <ThreeElementBlockContainer
          blocksPerRow={3}
          blocks={benefit}
        />
        {modules.map((block, index) => (
          <FiftyFiftyBlock
            key={index}
            {...block}
          />
        ))}
        <BackgroundLines
          images={{
            'left': 'https://a.storyblok.com/f/91820/x/341d8f3260/acw-solution-overview-lines-01.svg',
            'right': 'https://a.storyblok.com/f/91820/x/970560b364/acw-solution-overview-lines-02.svg',
            'right2': 'https://a.storyblok.com/f/91820/x/341d8f3260/acw-solution-overview-lines-01.svg',
          }}
        />
      </div>

      <CtaBanner
        banner={ctaBanner.content}
        addMargin={true}
      />

      <FooterContainer/>
    </div>
  )
};

export default PlatformOverviewPage;
