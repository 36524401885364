import React from 'react'
import Components from '../../../components/components';

type CustomizablePageProps = {
  blocks: any[];
}

const CustomizablePage = ({ blocks }: CustomizablePageProps) => {

  return (
    <div>
      {blocks.map(block =>
        React.createElement(Components(block.component), { key: block._uid, ...block })
      )}
    </div>
  )
};

export default CustomizablePage;
