import React from 'react';
import classNames from 'classnames';
import { StoryblokImageType } from '../../../types/storyblokTypes'

export type StoryblokImageProps = {
  image?: StoryblokImageType;
  className?: string;
}

const StoryblokImage = ({ image, className }: StoryblokImageProps) => {

  if (!image || !image.filename) {
    return null;
  }

  return (
    <img
      className={classNames('accurityStoryblokImage', className)}
      src={image.filename}
      alt={image.alt}
    />
  )
};

export default StoryblokImage;
