import React from 'react'
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import { FaqSectionType } from '../../../components/faq/types';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';
import { StoryblokImageType, StoryblokLogoStripeType, StoryblokPricingSolution } from '../../../types/storyblokTypes';
import { TestimonialType } from '../../../components/testimonialsSlider/components/Testimonial';
// @ts-ignore
import guyImage from '../../../images/testimonialForm-guy.png'
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import { LogoStripe } from '../../../components/logoStripe/components/LogoStripe';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import Faq from '../../../components/faq/components/Faq';
import PricingTabs from './PricingTabs';
import TestimonialSlider from '../../../components/testimonialsSlider/components/TestimonialSlider';

const testimonialGuyImage: StoryblokImageType = {
  filename: guyImage
};

export type PricingPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  plansTitle: string;
  tabs: StoryblokPricingSolution[];
  ctaBannerTitle: string;
  ctaBanner: { content: CtaBannerType };
  ctaBanner2: { content: CtaBannerType };
  faqTitle: string;
  faqSections: FaqSectionType[];
  testimonialTitle: string;
  testimonial: { content: TestimonialType };
  logoStripe: { content: StoryblokLogoStripeType };
}

const PricingPage = ({
                       seoMeta,
                       header,
                       plansTitle,
                       tabs,
                       faqSections,
                       faqTitle,
                       testimonial,
                       testimonialTitle,
                       ctaBannerTitle,
                       ctaBanner,
                       ctaBanner2,
                       logoStripe,
                     }: PricingPageProps) => {


  return (
    <div className={'pricingPage'}>
      <AccuritySeoMeta {...seoMeta} />

      <UniversalHeader {...header[0]} />

      <PricingTabs
        plansTitle={plansTitle}
        tabs={tabs}
      />

      {logoStripe?.content ? <div className="sectionMargins">
        <LogoStripe {...logoStripe.content} />
      </div> : null}

      {ctaBanner?.content && <>
        <h3 className="pricingTabs__pocBannerTitle">{ctaBannerTitle}</h3>
        <CtaBanner banner={ctaBanner?.content} className="pricingTabs__pocBanner"/>
      </>}

      <Faq
        title={faqTitle}
        sections={faqSections}
      />

      {ctaBanner2?.content && <CtaBanner
        banner={ctaBanner2?.content}
      />}

      {testimonial?.content && <div className="pricingTabs__testimonial container">
        <TestimonialSlider
          headline={testimonialTitle}
          cycleFrequencySeconds={0}
          testimonials={[testimonial]}
          rightImage={testimonialGuyImage}
        />
      </div>}

      <FooterContainer/>
    </div>
  )
};

export default PricingPage;
