import React from 'react'
import { StoryblokButtonType, StoryblokImageType } from '../../../types/storyblokTypes'
import Button from '../../buttons/components/Button';

export type DemoPresenterCardStoryblokType = {
  component: 'Demo Presenter Card';
  name?: string;
  position?: string;
  description?: string;
  verticalPhoto?: StoryblokImageType;
  horizontalPhoto?: StoryblokImageType;
  notes?: string;
  ctaButton: StoryblokButtonType[];
}

const DemoPresenterCard = ({
                             position,
                             description,
                             name,
                             notes,
                             horizontalPhoto,
                             verticalPhoto,
                             ctaButton
                           }: DemoPresenterCardStoryblokType) => {

  const notesParts = notes?.split('\n');

  return (
    <div className="demoPresenterCard">
      <div className="demoPresenterCard__image demoPresenterCard__image--horizontal" style={{ 'backgroundImage': `url("${horizontalPhoto?.filename}")` }}/>
      <div className="demoPresenterCard__image demoPresenterCard__image--vertical" style={{ 'backgroundImage': `url("${verticalPhoto?.filename}")` }}/>
      <div className="demoPresenterCard__textContent">
        <div className="demoPresenterCard__leftTextContent">
          {name && <h3 className="demoPresenterCard__name">{name}</h3>}
          {position && <div className="demoPresenterCard__position">{position}</div>}
          {description && <div className="demoPresenterCard__description">{description}</div>}
        </div>
        <div className="demoPresenterCard__rightTextContent">
          {notesParts && <ul className="demoPresenterCard__notes">
            {notesParts.map((note, idx) => <li key={idx}>{note}</li>)}
          </ul>}
        </div>
        {ctaButton && ctaButton[0] && <Button {...ctaButton[0]} className={'demoPresenterCard__ctaButton'}/>}
      </div>
    </div>
  )
};

export default DemoPresenterCard
