import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import ZohoContactUsForm from '../../../components/zohoForms/components/ZohoContactUsForm';
import { CONTACT_US_THANK_YOU_PAGE_URL } from '../../../rest/endpoints';

export type PricingTabFormProps = {
  formUUID: string;
  formSource: string;
}

const useLoadForm = (formUUID: string) => {
  let queryResult;
  try {
    queryResult = useStaticQuery(
      graphql`
      query LoadForms {
        allStoryblokEntry(filter: {full_slug: {regex: "/reusable-forms/"}}) {
          nodes {
            uuid
            content
          }
        }
      }
    `
    );
  } catch {
    return null;
  }

  const form = queryResult.allStoryblokEntry.nodes.find((q: any) => q.uuid === formUUID);
  try {
    return JSON.parse(form?.content as any);
  } catch (e) {
    return null;
  }
};

const PricingTabForm = ({ formSource, formUUID }: PricingTabFormProps) => {

  const form = useLoadForm(formUUID);

  return (
    <div className="pricingTabForm hashTarget" id="form">
      <ZohoContactUsForm
        formLabels={form}
        formSource={formSource}
        successRedirectUrl={CONTACT_US_THANK_YOU_PAGE_URL}
      />
    </div>
  )
};


export default PricingTabForm;


