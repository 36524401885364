import React from 'react';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';
import { CertificationCardStoryblokType, FiftyFiftyBlockV2Type, LocationCardStoryblokType, StoryblokImageType, StoryblokLogoStripeType } from '../../../types/storyblokTypes';
import { LogoStripe } from '../../../components/logoStripe/components/LogoStripe';
import Awards from '../../../components/awards/components/Awards';
import { CertificationCards } from '../../../components/certificationCards/components/CertificationCards';
import FiftyFiftyContainer from '../../../components/fiftyFiftyBlock/components/FiftyFiftyContainer';
import { CompanyOffices } from '../../../components/companyOffices/components/CompanyOffices';

export type CompanyPageV2Props = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  logoStripe: { content: StoryblokLogoStripeType };
  awardsTitle: string;
  awardLogos: StoryblokImageType[];
  certificationTitle: string;
  certificationCards: CertificationCardStoryblokType[];
  ctaBanner: { content: CtaBannerType };
  whatWeDoTitle: string;
  whatWeDoCards: FiftyFiftyBlockV2Type[];
  officesTitle: string;
  offices: LocationCardStoryblokType[];
  companyName: string;
  companyInfo: string;
}

export const CompanyPageV2 = ({
                                awardLogos,
                                awardsTitle,
                                companyInfo,
                                companyName,
                                ctaBanner,
                                header,
                                logoStripe,
                                offices,
                                officesTitle,
                                seoMeta,
                                whatWeDoCards,
                                whatWeDoTitle,
                                certificationCards,
                                certificationTitle
                              }: CompanyPageV2Props) => {
  return (
    <div className="companyPage--v2">
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />

      {logoStripe?.content ? <LogoStripe {...logoStripe.content}/> : null}

      {awardLogos?.length > 0 ? <Awards title={awardsTitle} logos={awardLogos.map(q => ({ image: q }))}/> : null}

      {certificationCards?.length > 0 ? <CertificationCards title={certificationTitle} cards={certificationCards}/> : null}

      <FiftyFiftyContainer
        title={whatWeDoTitle}
        cards={whatWeDoCards}
      />

      {ctaBanner?.content ? <div className="sectionMargins">
        <CtaBanner banner={ctaBanner.content}/>
      </div> : null}

      <CompanyOffices
        title={officesTitle}
        offices={offices}
        companyInfo={companyInfo}
        companyName={companyName}
      />

      <FooterContainer/>
    </div>
  );
};
