import React from 'react'
import classNames from 'classnames';
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import { RoadmapCardType } from '../../../components/roadmapCard/types';
import RoadmapCard from '../../../components/roadmapCard/components/RoadmapCard';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type RoadmapPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  ctaBanner: { content: CtaBannerType };
  inConsiderationTitle: string;
  inConsiderationCards: RoadmapCardType[];
  inDevelopmentTitle: string;
  inDevelopmentCards: RoadmapCardType[];
  plannedTitle: string;
  plannedCards: RoadmapCardType[];
}

const filterCards = (cards: RoadmapCardType[], tier: string = 'All') => {
  return tier === 'All' ? cards : cards.filter(q => q.tiers.includes(tier));
};

const RoadmapPage = ({
                       seoMeta,
                       header,
                       ctaBanner,
                       inDevelopmentTitle,
                       inDevelopmentCards,
                       inConsiderationCards,
                       inConsiderationTitle,
                       plannedCards,
                       plannedTitle
                     }: RoadmapPageProps) => {

  const possibleTiers = ['All', 'Starter', 'Professional', 'Premium', 'Custom'];
  const [selectedTier, setSelectedTier] = React.useState('All');

  return (
    <div className={'roadmapPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]}/>

      {inDevelopmentCards && inDevelopmentCards.length > 0 && <div className="container roadmapPage__inDevelopmentSection">
        {inDevelopmentTitle && <h2 className={'roadmapPage__sectionTitle'}>{inDevelopmentTitle}</h2>}
        <div className="roadmapCardFilter">
          <h3>Filter</h3>
          <div className="roadmapCardFilter__chips">
            {possibleTiers.map(tier => (
              <div
                className={classNames('roadmapCardFilter__chip', { 'roadmapCardFilter__chip--selected': tier == selectedTier })}
                onClick={() => setSelectedTier(tier)}
              >
                {tier}
              </div>
            ))}
          </div>
        </div>
        <div className={'roadmapPage__cardsWrapper'}>
          {filterCards(inDevelopmentCards, selectedTier).map(card => <RoadmapCard {...card}/>)}
        </div>
      </div>}

      {inConsiderationCards && inConsiderationCards.length > 0 && <>
        <div className="roadmapPage__inConsiderationSectionTopWave"/>
        <div className="roadmapPage__inConsiderationSection">
          <div className="container">
            {inConsiderationTitle && <h2 className={'roadmapPage__sectionTitle'}>{inConsiderationTitle}</h2>}
            <div className={'roadmapPage__cardsWrapper'}>
              {inConsiderationCards.map(card => <RoadmapCard {...card}/>)}
            </div>
          </div>
          <div className="roadmapPage__inConsiderationSectionDots"/>
          <div className="roadmapPage__inConsiderationSectionBottomWave"/>
        </div>
      </>}

      {plannedCards && plannedCards.length > 0 && <div className="container roadmapPage__plannedFeaturesSection">
        {plannedTitle && <h2 className={'roadmapPage__sectionTitle'}>{plannedTitle}</h2>}
        <div className={'roadmapPage__cardsWrapper'}>
          {plannedCards.map(card => <RoadmapCard {...card}/>)}
        </div>
      </div>}

      <CtaBanner
        banner={ctaBanner.content}
      />
      <FooterContainer/>
    </div>
  )
};

export default RoadmapPage;
