import React from 'react'
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta from '../../../components/AccuritySeoMeta';
import { StoryblokProductTermsPageType, StoryblokRTEListItem, StoryblokTableType } from '../../../types/storyblokTypes';
import { UniversalHeader } from '../../../components/header/components/UniversalHeader';
import { render } from 'storyblok-rich-text-react-renderer-ts'
import Button from '../../../components/buttons/components/Button';
import RTEListItem from '../../../components/rte/components/RTEListItem';
import RTETable from '../../../components/rte/components/RTETable';

const ProductTermsPage = ({
                            seoMeta,
                            header,
                            content,
                            pdfDownload,
                            archiveTitle,
                            archiveItems
                          }: StoryblokProductTermsPageType) => {

  return (
    <div className="productTermsPage">
      <AccuritySeoMeta {...seoMeta} />

      <UniversalHeader {...header[0]} />

      <div className="container">
        <div className="productTermsPage__content">
          {render(content, {
            blokResolvers: {
              'RTE List Item': (props: StoryblokRTEListItem) => <RTEListItem {...props}/>,
              'RTE Table': (props: { table: StoryblokTableType, notes: string }) => <RTETable {...props}/>
            }
          })}

          <Button {...pdfDownload[0]} className="productTerms__pdfDownload"/>

          {archiveItems && archiveItems.length > 0 && <section className="productTerms__archive">
            <h2 className="productTerms__archiveTitle">{archiveTitle}</h2>
            {archiveItems.map((item, index) => {
              return <div className="productTerms__archiveItem" key={index}>
                <Button {...item}/>
              </div>
            })}
          </section>}
        </div>
      </div>

      <FooterContainer/>
    </div>
  )
};

export default ProductTermsPage;
