import React from 'react';
import classNames from 'classnames';
import { StoryblokPricingSolution } from '../../../../types/storyblokTypes';

export type SolutionMobileTierSwitchProps = {
  tab: StoryblokPricingSolution;
  activeTier: number;
  setActiveTier: (index: number) => void
}

const SolutionMobileTierSwitch = ({
                                    tab,
                                    activeTier,
                                    setActiveTier
                                  }: SolutionMobileTierSwitchProps) => {

  const tiers = [tab.tier1Title, tab.tier2Title, tab.tier3Title];

  return (
    <div className="solutionMobileTierSwitch">
      <div className="solutionMobileTierSwitch__container">
        {tiers.map((tier, index) => <div
          key={index}
          className={classNames('solutionMobileTierSwitch__tier', { 'solutionMobileTierSwitch__tier--active': index === activeTier })}
          onClick={() => setActiveTier(index)}
        >{tier}</div>)}
      </div>
    </div>
  )
};

export default SolutionMobileTierSwitch;


