import React from 'react'

import StoryblokImage from '../../storyblokImage/components/StoryblokImage'

import { StoryblokImageType } from '../../../types/storyblokTypes'

type AwardsType = {
  title: string
  logos: { image: StoryblokImageType }[]
}

const Awards = ({ title, logos }: AwardsType) => {
  const logoItems = logos?.map((logo, i) => (
    <div className="c-awards__logoWrapper" key={`awards-${i}`}>
      <StoryblokImage image={logo?.image} className="c-awards__logoItem" />
    </div>
  ));

  return (
    <section className="c-awards sectionMargins">
      <div className="container">
        <div className="c-awards__content">
          <h2 className="c-awards__title sectionTitle">{title}</h2>

          <div className="c-awards__logos">{logoItems}</div>
        </div>
      </div>
    </section>
  )
};

export default Awards
