import React from 'react'
import CtaBanner, { CtaBannerType, } from '../../../components/ctaBanner/components/CtaBanner'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import AccuritySeoMeta, { AccuritySeoMetaType, } from '../../../components/AccuritySeoMeta'
import { ResourceCardType } from '../../../components/resourceCardContainer/types'
import { StoryblokLinkType } from '../../../types/storyblokTypes'
import BlogCardsContainer from '../../../components/resourceCardContainer/components/BlogCardsContainer'
import ResourceCardContainer from '../../../components/resourceCardContainer/components/ResourceCardsContainer'
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines'
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type ResourcesOverviewProps = {
  seoMeta: AccuritySeoMetaType
  header: StorybookUniversalHeaderType[]
  ctaBanner: { content: CtaBannerType }

  blogTitle?: string
  blogCards: ResourceCardType[]
  blogCta?: string
  blogCtaLink?: StoryblokLinkType

  reportsTitle?: string
  reportsCards: ResourceCardType[]

  caseStudiesTitle?: string
  caseStudiesCards: ResourceCardType[]

  webinarsTitle?: string
  webinarsCards: ResourceCardType[]
  onDemandWebinarsTitle?: string
  onDemandWebinarsCards: ResourceCardType[]
}

const ResourcesOverview = ({
                             seoMeta,
                             header,
                             ctaBanner,
                             blogCards,
                             blogCta,
                             blogCtaLink,
                             blogTitle,
                             caseStudiesCards,
                             caseStudiesTitle,
                             reportsCards,
                             reportsTitle,
                             webinarsCards,
                             webinarsTitle,
                             onDemandWebinarsCards,
                             onDemandWebinarsTitle
                           }: ResourcesOverviewProps) => {
  return (
    <div className={'resourcesOverviewPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />

      {!!blogCards && blogCards.length > 0 && (
        <section
          className="resourcesOverviewPage__blogPosts hashTarget"
          id="blog-posts"
        >
          <BlogCardsContainer
            resources={blogCards}
            title={blogTitle}
            ctaButton={blogCta}
            ctaTarget={blogCtaLink}
          />
        </section>
      )}

      {!!reportsCards && reportsCards.length > 0 && (
        <section
          className="resourcesOverviewPage__reports hashTarget"
          id="whitepapers"
        >
          <ResourceCardContainer
            resources={reportsCards}
            title={reportsTitle}
          />
        </section>
      )}

      {!!caseStudiesCards && caseStudiesCards.length > 0 && (
        <section
          className="resourcesOverviewPage__caseStudies hashTarget"
          id="customer-stories"
        >
          <ResourceCardContainer
            resources={caseStudiesCards}
            title={caseStudiesTitle}
          />
        </section>
      )}

      {!!webinarsCards && webinarsCards.length > 0 && (
        <section
          className="resourcesOverviewPage__webinars hashTarget"
          id="webinars"
        >
          <ResourceCardContainer
            resources={webinarsCards}
            title={webinarsTitle}
          />
        </section>
      )}

      {!!onDemandWebinarsCards && onDemandWebinarsCards.length > 0 && (
        <section className="resourcesOverviewPage__webinars hashTarget" id="data-vibes">
          <ResourceCardContainer
            resources={onDemandWebinarsCards}
            title={onDemandWebinarsTitle}
          />
        </section>
      )}

      <div className="hashTarget" id="banner">
        <CtaBanner banner={ctaBanner.content}/>
      </div>

      <BackgroundLines
        images={{
          right:
            'https://a.storyblok.com/f/91820/x/0b3c19a396/acc-resource-center-lines-01.svg',
          left:
            'https://a.storyblok.com/f/91820/x/f0b29d97b0/acc-resource-center-lines-02.svg',
        }}
      />

      <FooterContainer/>
    </div>
  )
};

export default ResourcesOverview
