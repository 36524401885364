import React from 'react'
import CtaBanner from '../../../components/ctaBanner/components/CtaBanner';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta from '../../../components/AccuritySeoMeta';
import { BlogType } from '../../../types/storyblokTypes';
import BlogPostHero from '../../../components/blog/components/BlogPostHero';
import BlogPostText from '../../../components/blog/components/BlogPostText';
import BlogPostAuthorBio from '../../../components/blog/components/BlogPostAuthorBio';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';
import ThreeResourceCardsContainer from '../../../components/resourceCardContainer/components/ThreeResourceCardsContainer';

const BlogDetailPage = ({
                          seoMeta,
                          blogAuthor = [],
                          blogBody,
                          blogTitle,
                          ctaBanner,
                          datePublished,
                          headerImage,
                          leadParagraph,
                          readTime,
                          tag,
                          resources,
                        }: BlogType) => {

  const authors = blogAuthor.map(q => q.content).filter(q => !!q);

  return (
    <div className={'blogDetailPage'}>
      <AccuritySeoMeta {...seoMeta} />

      <BlogPostHero
        tag={tag}
        title={blogTitle}
        authors={authors}
        readTime={readTime}
        headerImage={headerImage}
        datePublished={datePublished}
      />

      <BlogPostText
        leadParagraph={leadParagraph}
        blogBody={blogBody}
      />

      <BlogPostAuthorBio
        authors={authors}
      />

      <ThreeResourceCardsContainer
        resources={resources}
      />

      <CtaBanner
        banner={ctaBanner?.content}
      />

      <BackgroundLines
        images={{
          'bottom': 'https://a.storyblok.com/f/91820/x/959c46d50a/acc-blog-detail-lines-04.svg',
        }}
      />

      <FooterContainer/>
    </div>
  )
};

export default BlogDetailPage;
