import React from 'react'
// @ts-ignore
import FacebookLogo from '../../../images/facebook.svg'
// @ts-ignore
import LinkedinLogo from '../../../images/linkedin.svg'
// @ts-ignore
import TwitterLogo from '../../../images/twitter.svg'
// @ts-ignore
import YoutubeLogo from '../../../images/youtube.svg'
import { StoryblokLinkType } from '../../../types/storyblokTypes'
import { getUrlFromLink } from '../../../utils/linkUtils'

type FooterSocialsProps = {
  facebook: StoryblokLinkType
  linkedIn: StoryblokLinkType
  twitter: StoryblokLinkType
  youtube: StoryblokLinkType
  showCapteraBadge?: boolean
}

const capteraBadge = (
  <a className="footer__socialLink footer__capteraLink" href="https://www.capterra.com/reviews/245900/Accurity?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge">
    <img src="https://assets.capterra.com/badge/88c031f21326c53ce5175990654da9eb.svg?v=2203379&p=245900"/>
  </a>
);

const FooterSocials = ({
                         facebook,
                         linkedIn,
                         twitter,
                         youtube,
                         showCapteraBadge
                       }: FooterSocialsProps) => {
  const socials = [
    { icon: TwitterLogo, url: getUrlFromLink(twitter) },
    { icon: FacebookLogo, url: getUrlFromLink(facebook) },
    { icon: LinkedinLogo, url: getUrlFromLink(linkedIn) },
    { icon: YoutubeLogo, url: getUrlFromLink(youtube) },
  ].filter(link => !!link.url);

  return (
    <div className="footer__socials">
      {socials.map((social, i) => <a href={social.url} target="_blank" key={i} className="footer__socialLink">
        <img src={social.icon}/>
      </a>)}
      {showCapteraBadge ? capteraBadge : null}
    </div>
  )
};

export default FooterSocials
