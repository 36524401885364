import React from 'react'
import TopNavContainer from '../../../navigation/components/TopNavContainer'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import AccuritySeoMeta, { AccuritySeoMetaType, } from '../../../components/AccuritySeoMeta'
import FreeText, { FreeTextType, } from '../../../components/freeText/components/FreeText'
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines'
import ResourceCardContainer from '../../../components/resourceCardContainer/components/ResourceCardsContainer'
import { ResourceCardType } from '../../../components/resourceCardContainer/types'
import { StoryblokButtonType } from '../../../types/storyblokTypes'
import { StorybookHeaderStyleEnum, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type ThankYouPageProps = {
  seoMeta: AccuritySeoMetaType
  text: FreeTextType[]
  pageStyle: "dark-header" | "default"
  blogCardsTitle: string
  blogCards: ResourceCardType[]
}

const DarkThankYouPage = ({
  seoMeta,
  text,
  blogCards,
  blogCardsTitle,
}: ThankYouPageProps) => {

  // do not let robots to index thank you pages
  seoMeta.noIndex = true;

  const { mediaAsset, richText, title, ctaButton, ctaLink } = text[0];
  const downloadButton: StoryblokButtonType | undefined =
    ctaButton && ctaLink
      ? {
          label: ctaButton,
          link: ctaLink,
          severity: "tertiary",
        }
      : undefined;

  return (
    <div className={"thankYouPage thankYouPage--darkHeader"}>
      <AccuritySeoMeta {...seoMeta} />
      {title && richText && mediaAsset && (
        <UniversalHeader
          headline={title}
          leadParagraph={richText}
          headerMedia={mediaAsset}
          ctaButtons={downloadButton && [downloadButton]}
          headerStyle={[StorybookHeaderStyleEnum.RATIO_5_7]}
        />
      )}
      <section id="customer-stories">
        <ResourceCardContainer
          resources={blogCards}
          title={blogCardsTitle}
          showArrows={false}
        />
      </section>
      <FooterContainer />
    </div>
  )
};

const DefaultThankYouPage = ({ seoMeta, text }: ThankYouPageProps) => {
  return (
    <div className={"thankYouPage"}>
      <AccuritySeoMeta {...seoMeta} />
      <TopNavContainer />
      <div className="container">
        <div className="thankYouPage__leftContent">
          <FreeText {...text[0]} />
        </div>
      </div>
      <BackgroundLines
        images={{
          right:
            "https://a.storyblok.com/f/91820/x/6ea11641c7/demo-form-thank-you-lines.svg",
        }}
      />
      <FooterContainer />
    </div>
  )
};

const ThankYouPage = (props: ThankYouPageProps) => {
  switch (props.pageStyle) {
    case "dark-header":
      return <DarkThankYouPage {...props} />;
    case "default":
    default:
      return <DefaultThankYouPage {...props} />;
  }
};

export default ThankYouPage
