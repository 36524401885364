import React from "react"
import { navigate } from "gatsby"
import Button from "../../buttons/components/Button"
import { getUrlFromLink } from "../../../utils/linkUtils"
import {
  StoryblokImageType,
  StoryblokLinkType,
} from "../../../types/storyblokTypes"
import StoryblokImage from "../../storyblokImage/components/StoryblokImage"

export type CustomerStoryBannerType = {
  image: StoryblokImageType
  title?: string
  headline?: string
  ctaButton: string
  ctaTarget: StoryblokLinkType
}

const CustomerStoryBanner = ({
  image,
  title,
  headline,
  ctaButton,
  ctaTarget,
}: CustomerStoryBannerType) => {
  return (
    <div className={"customerStoryBanner"}>
      <div className={"customerStoryBanner__imageContainer"}>
        <StoryblokImage image={image} />
      </div>
      <div className={"customerStoryBanner__textContainer"}>
        {title && <p className={"customerStoryBanner__title"}>{title}</p>}
        {headline && (
          <h3 className={"customerStoryBanner__headline"}>{headline}</h3>
        )}
        {ctaButton ? (
          <a
            className={
              "customerStoryBanner__callToAction fiftyFiftyBlock__callToAction"
            }
            href={getUrlFromLink(ctaTarget)}
          >
            {ctaButton}
          </a>
        ) : null}
      </div>
    </div>
  )
}

export default CustomerStoryBanner
