import React, { useEffect, useState } from 'react';
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import Testimonial, { TestimonialType } from './Testimonial';
import { StoryblokImageType } from '../../../types/storyblokTypes';

export type TestimonialSliderType = {
  headline: string;
  rightImage: StoryblokImageType;
  testimonials: { content: TestimonialType }[];
  cycleFrequencySeconds: number;
}

let _testimonialCycleInterval: any;

const TestimonialSlider = ({ headline, rightImage, testimonials, cycleFrequencySeconds = 10 }: TestimonialSliderType) => {
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  const handleLeftClick = () => {
    if (testimonialIndex - 1 < 0) {
      setTestimonialIndex(testimonials.length - 1);
    } else {
      setTestimonialIndex(testimonialIndex - 1);
    }
  };

  const handleRightClick = () => {
    if (testimonialIndex + 1 >= testimonials.length) {
      setTestimonialIndex(0);
    } else {
      setTestimonialIndex(testimonialIndex + 1);
    }
  };

  // Interval to automatically cycle testimonials
  useEffect(() => {
    clearInterval(_testimonialCycleInterval);
    _testimonialCycleInterval = setInterval(() => {
      handleLeftClick();
    }, cycleFrequencySeconds * 1000);
    return () => { clearInterval(_testimonialCycleInterval); };
  }, [testimonialIndex]);

  return (
    <div className="testimonialSlider">
      <h2>{headline}</h2>
      <div className="testimonialSlider__testimonialContainer">
        <div className="testimonialSlider__backgroundShapeWrapper">
          <div className="testimonialSlider__backgroundShape"/>
        </div>
        <div className="testimonialSlider__quoteStart">
          “
        </div>
        <Testimonial {...testimonials[testimonialIndex].content} />

        <div className="testimonialSlider__rightImage">
          <StoryblokImage image={rightImage}/>
        </div>
      </div>
      {testimonials.length > 1 ?
        <div className="testimonialSlider__controls">
          <div className="testimonialSlider__leftControl" onClick={handleLeftClick}/>
          <div className="testimonialSlider__rightControl" onClick={handleRightClick}/>
        </div>
        : null}
    </div>
  );
};

export default TestimonialSlider;
