import React from "react"
import { navigate } from "gatsby"
import StoryblokImage from "../../storyblokImage/components/StoryblokImage"
import { getUrlFromLink } from "../../../utils/linkUtils"

import {
  StoryblokImageType,
  StoryblokLinkType,
} from "../../../types/storyblokTypes"

type CaseStudiesProps = {
  caseStudyTitle: string
  companyLogo: StoryblokImageType
  image: StoryblokImageType
  text: string
  ctaButton: string
  ctaLink: StoryblokLinkType
}

const CaseStudies = ({
  image,
  caseStudyTitle,
  companyLogo,
  text,
  ctaButton,
  ctaLink,
}: CaseStudiesProps) => {
  return (
    <div className="caseStudies__content">
      <div className="caseStudies__image">
        <StoryblokImage image={image} />
      </div>
      <div className="caseStudies__text">
        <StoryblokImage image={companyLogo} />
        <h3>{caseStudyTitle}</h3>
        <p>{text}</p>
        {ctaButton ? (
          <a
            className={"fiftyFiftyBlock__callToAction"}
            href={getUrlFromLink(ctaLink)}
          >
            {ctaButton}
          </a>
        ) : null}
      </div>
    </div>
  )
}

export default CaseStudies
