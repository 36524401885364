import React from 'react'

import StoryblokLink from '../../../components/storyblokLink/components/StoryblokLink'
import StoryblokImage from '../../storyblokImage/components/StoryblokImage'

import { StoryblokImageType, StoryblokLinkType, } from '../../../types/storyblokTypes'

export type BigBannerType = {
  title: string
  text: string
  linkLabel: string
  link: StoryblokLinkType
  image: StoryblokImageType
}

const BigBanner = ({ title, text, linkLabel, link, image }: BigBannerType) => {
  return (
    <section className="c-bigBanner">
        <div className="c-bigBanner__card">
          <div className="c-bigBanner__content">
            <div className="c-bigBanner__text">
              <h2 className="c-bigBanner__title">{title}</h2>
              <p className="c-bigBanner__text">{text}</p>
            </div>

            <div className="c-bigBanner__link">
              <StoryblokLink
                to={link}
                className="button button--primary c-bigBanner__button"
              >
                {linkLabel}
              </StoryblokLink>
            </div>
          </div>

          <StoryblokImage image={image} className="c-bigBanner__image"/>
        </div>
    </section>
  )
};

export default BigBanner
