import React from 'react';
import { useActiveButtonState } from '../../../hooks/useActiveButtonState';

type FilteringHeadProps = {
  onReset: () => void;
};

const FilteringHead = ({ onReset }: FilteringHeadProps) => {

  const [isActive, setActive] = useActiveButtonState();

  const handleReset = () => {
    onReset();
    setActive(true);
  };

  return (
    <div className="c-filteringHead">
      <span>Filters</span>
      <button
        className={`c-FilteringHead__resetButton ${isActive ? 'c-FilteringHead__resetButton--active' : ''}`}
        onClick={handleReset}
      >
        Reset
      </button>
    </div>
  )
};

export default FilteringHead;