import React from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import TopNavContainer from "../../../navigation/components/TopNavContainer"
import StoryblokImage from "../../storyblokImage/components/StoryblokImage"
import {
  StoryblokButtonType,
  StoryblokImageType,
} from "../../../types/storyblokTypes"
import Button from "../../buttons/components/Button"

export type HeaderType = {
  headline: string
  leadParagraph: string
  headerMedia: StoryblokImageType
  className?: string
  ctaButtons?: StoryblokButtonType[]
  isLandingPage?: boolean
  reducedMenu?: boolean
  alignImageToRight?: boolean
  style?: string[]
}

const Header = ({
  headline,
  leadParagraph,
  headerMedia,
  ctaButtons = [],
  className,
  isLandingPage,
  alignImageToRight,
  reducedMenu,
  style,
}: HeaderType) => {
  const renderHeaderClassNames = () => {
    const storyblokStyle = style?.map(q => `header--${q}`)

    return classNames("header", className, storyblokStyle, {
      "header--imageAlignedToRight": alignImageToRight,
    })
  }

  const renderImageClassNames = () =>
    classNames({
      header__headerMediaContainer: true,
      "header__headerMediaContainer--landingPage": isLandingPage,
    })

  return (
    <div className={renderHeaderClassNames()}>
      <TopNavContainer inverted={true} reducedMenu={reducedMenu} />
      <div className="container">
        <div className="headerContent">
          <div className="header__textContainer">
            <h1>{headline}</h1>
            <p>{leadParagraph}</p>
            <div className="header__ctaButtonsContainer">
              {ctaButtons.map((button, idx) => (
                <React.Fragment key={idx}>
                  {button?.label && (
                    <Button
                      label={button.label}
                      severity={button.severity}
                      link={button.link ? button.link : undefined}
                      isScroll={button.isScroll}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          {headerMedia && headerMedia.filename ? (
            <div className={renderImageClassNames()}>
              <div className="header__headerMediaContainerInner">
                <StoryblokImage image={headerMedia} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Header
