import React from "react"

import StoryblokImage from "../../storyblokImage/components/StoryblokImage"
import StoryblokLink from "../../storyblokLink/components/StoryblokLink"

import {
  StoryblokImageType,
  StoryblokLinkType,
} from "../../../types/storyblokTypes"

type RatingType = {
  logo: StoryblokImageType
  value: string
  stars: StoryblokImageType
  date: string
  title: string
  keyValue: { key: string; value: string }[]
  text: string
  ctaLabel: string
  ctaLink: StoryblokLinkType
}

const Rating = ({
  logo,
  value,
  stars,
  date,
  title,
  keyValue,
  text,
  ctaLabel,
  ctaLink,
}: RatingType) => {
  const infoItems = keyValue?.map((item, i) => (
    <div className="c-rating__infoItem" key={`rating-info-${i}`}>
      <p>
        <strong>{item?.key}</strong>
      </p>
      <p>{item?.value}</p>
    </div>
  ))

  return (
    <section className="c-rating">
      <div className="container">
        <div className="c-rating__content">
          <div className="c-rating__top">
            <div className="c-rating__stars">
              <span className="c-rating__value">{value}</span>
              <StoryblokImage image={stars} />
              <span className="c-rating__date">{date}</span>
            </div>

            <div className="c-rating__logo">
              <StoryblokImage image={logo} className="c-rating__logoImage" />
            </div>
          </div>

          <h2 className="c-rating__title">{title}</h2>
          <div className="c-rating__info">{infoItems}</div>

          <p className="c-rating__text">{text}</p>

          <StoryblokLink to={ctaLink} className="c-rating__link">
            {ctaLabel}
          </StoryblokLink>
        </div>
      </div>
    </section>
  )
}

export default Rating
