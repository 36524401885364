import React from 'react'
import { useNewsletterSignup } from '../hooks/newsletterSignup';
import { StoryblokLinkType } from '../../../types/storyblokTypes';

export type CtaNewsletterBannerProps = {
  component: 'CTA Newsletter Banner';
  bannerTitle: string;
  bannerText: string;
  inputFieldText: string;
  bannerCtaButton: string;
  thankYouUrl: StoryblokLinkType;
}

const CtaNewsletterBanner = ({
                               bannerTitle,
                               bannerText,
                               inputFieldText,
                               bannerCtaButton,
                               thankYouUrl,
                             }: CtaNewsletterBannerProps) => {
  const {
    email,
    setEmail,
    doSubmit,
    isFetching,
  } = useNewsletterSignup('Banner Newsletter Signup', thankYouUrl.cached_url);

  const handleSubmit = () => {
    if (!isFetching) {
      doSubmit();
    }
  };

  return (
    <div className="ctaBanner ctaBanner--newsletter" id="newsletter">
      <h4 className="ctaBanner__heading">
        {bannerTitle}
      </h4>

      <p className="ctaBanner__text">
        {bannerText}
      </p>

      <div className="ctaBanner__inputFieldWrapper">
        <input
          type="text"
          className="ctaBanner__inputField"
          placeholder={inputFieldText}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          onClick={handleSubmit}
          disabled={isFetching}
          className="button button--primary ctaBanner__ctaButton"
        >
          {bannerCtaButton}
        </button>
      </div>
    </div>
  )
};

export default CtaNewsletterBanner
