import React from 'react'

import StoryblokImage from '../../storyblokImage/components/StoryblokImage'

import { CertificationCardStoryblokType } from '../../../types/storyblokTypes'

type CertificationCardsProps = {
  title: string
  cards: CertificationCardStoryblokType[]
}

export const CertificationCards = ({ title, cards }: CertificationCardsProps) => {

  const cardsElements = cards.map(card => <div className="c-certificationCards__card">
    <StoryblokImage image={card.logo} className="c-certificationCards__cardImage"/>
    <div className="c-certificationCards__cardTitle">{card.title}</div>
    <div className="c-certificationCards__cardDescription">{card.description}</div>
  </div>);

  return (
    <section className="c-certificationCards sectionMargins">
      <div className="c-certificationCards__content container">
        <h2 className="c-certificationCards__title sectionTitle">{title}</h2>
        <div className="c-certificationCards__cards">{cardsElements}</div>
      </div>
    </section>
  )
};
