import React from 'react'
import ThreeElementBlockContainer from '../../../components/threeElementBlock/components/ThreeElementBlockContainer';
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import SubHeading from '../../../components/subHeading/components/SubHeading';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type SolutionsDetailPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  title: string;
  paragraph: string;
  useCases: ThreeElementBlockType[];
  ctaBanner?: { content: CtaBannerType };
  detailId: string;
}

const SolutionsDetailPage = ({ seoMeta, header, title, paragraph, useCases, ctaBanner, detailId }: SolutionsDetailPageProps) => {
  return (
    <div className={`solutionsDetailPage solutionsDetailPage--${detailId}`}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />

      <CtaBanner
        banner={ctaBanner?.content}
        addMargin={true}
      />

      <div className={'container solutionsDetailPage__contentContainer'}>
        <SubHeading
          title={title}
          paragraph={paragraph}
        />
        <ThreeElementBlockContainer
          blocksPerRow={3} // TODO
          blocks={useCases}
        />
      </div>
      <BackgroundLines
        images={{
          'left': 'https://a.storyblok.com/f/91820/x/341d8f3260/acw-solution-overview-lines-01.svg',
          'right': 'https://a.storyblok.com/f/91820/x/970560b364/acw-solution-overview-lines-02.svg',
          'bottom': 'https://a.storyblok.com/f/91820/x/06f36ba3ef/acw-solution-overview-lines-03.svg'
        }}
      />
      <FooterContainer/>
    </div>
  )
};

export default SolutionsDetailPage;
