import React from 'react'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import AccuritySeoMeta from '../../../components/AccuritySeoMeta'
import TestimonialSlider from '../../../components/testimonialsSlider/components/TestimonialSlider'
import { CustomerStoryType } from '../../../types/storyblokTypes'
import FreeText from '../../../components/freeText/components/FreeText'
import ErrorBoundary from '../../../components/ErrorBoundary'
import CtaBanner from '../../../components/ctaBanner/components/CtaBanner'
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines'
import CaseStudyNumbers from '../../../components/caseStudyNumbers/components/CaseStudyNumbers'
import { UniversalHeader } from '../../../components/header/components/UniversalHeader';

const CustomerStoryPage = ({
  seoMeta,
  header,
  challengeTitle,
  challenge,
  objectiveTitle,
  objectives,
  solutionTitle,
  solution,
  benefitTitle,
  benefits,
  logo,
  companyText,
  numbers,
  projectText,
  testimonials,
  ctaBanner,
  numbersHeadline,
  challengeListStyle,
  solutionListStyle,
  objectivesListStyle,
  benefitsListStyle,
}: CustomerStoryType) => {
  return (
    <div className={"customerStoryPage"}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />
      <div className={"customerStoryPage__contentContainer"}>
        <div className="overflow-hidden expand-top expand-bottom">
          <div className="container">
            <div className="customerStoryPage__freeTextColumn">
              <FreeText
                h2Title={challengeTitle}
                richText={challenge}
                listStyle={challengeListStyle}
              />
              {challenge && (
                <BackgroundLines
                  images={{
                    topLeft:
                      "https://a.storyblok.com/f/91820/x/18ea2b04ae/acw-case-study-lines-02.svg",
                    topRight:
                      "https://a.storyblok.com/f/91820/x/09a45d10b5/acw-case-study-lines-01.svg",
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <CaseStudyNumbers
          title={numbersHeadline}
          text={companyText}
          numbers={numbers}
          projectText={projectText}
        />
        <div className="overflow-hidden expand-top expand-bottom">
          <div className="container">
            <div className="customerStoryPage__freeTextColumn">
              <FreeText
                h2Title={objectiveTitle}
                richText={objectives}
                listStyle={objectivesListStyle}
              />
              <FreeText
                h2Title={solutionTitle}
                richText={solution}
                listStyle={solutionListStyle}
              />
              <FreeText
                h2Title={benefitTitle}
                richText={benefits}
                listStyle={benefitsListStyle}
              />
              {(objectives || solution || benefits) && (
                <BackgroundLines
                  images={{
                    bottomLeft:
                      "https://a.storyblok.com/f/91820/x/ccd3bb844e/customer-stories-bottom-left.svg",
                    bottomRight:
                      "https://a.storyblok.com/f/91820/x/88dee246ec/customer-stories-bottom-right.svg",
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <ErrorBoundary placeholderStyle={{ width: "100px", height: "100px" }}>
            <TestimonialSlider {...testimonials[0]} />
          </ErrorBoundary>
        </div>
      </div>

      <CtaBanner
        banner={ctaBanner?.content}
        className={'customerStoryPage__ctaBanner'}
      />

      <FooterContainer />
    </div>
  )
};

export default CustomerStoryPage
