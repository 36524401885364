import React from 'react'
import { BlogAuthorType, StoryblokImageType } from '../../../types/storyblokTypes';
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import TopNavContainer from '../../../navigation/components/TopNavContainer';

type BlogPostHeroProps = {
  tag: string;
  title: string;
  authors: BlogAuthorType[];
  readTime: string;
  headerImage: StoryblokImageType;
  datePublished: string;
}

const BlogPostHero = ({
                        authors,
                        headerImage,
                        readTime,
                        tag,
                        title,
                        datePublished,
                      }: BlogPostHeroProps) => {

  return (
    <section className="blogPostHero">

      <TopNavContainer inverted={true}/>

      <div className="blogPostHero__background"/>

      <div className="blogPostHero__content container">
        <div className="blogPostHero__tag">{tag}</div>
        <h1 className="blogPostHero__headline">{title}</h1>
        <div className="blogPostHero__author">
          <div className="blogPostHero__authorPhotosWrapper">
            {authors.map((author, idx) => (
              <StoryblokImage
                image={author.profilePhoto}
                className={'blogPostHero__authorPhoto'}
                key={idx}
              />
            ))}
          </div>
          <div className="blogPostHero__nameAndDateWrapper">
            <div className="blogPostHero__authorName">
              {authors.map(q => q.name).join(', ')}
            </div>
            <div className="blogPostHero__datePublished">
              {datePublished} | {readTime}
            </div>
          </div>
        </div>
      </div>

      <div className="blogPostHero__imageWrapper container">
        <StoryblokImage
          image={headerImage}
          className={'blogPostHero__image'}
        />
      </div>
    </section>
  )
};

export default BlogPostHero
