import * as React from 'react'

export type TopNavHamburgerProps = {
  isMenuExpanded: boolean;
  onClick: () => void;
}

const TopNavHamburger = ({
                           isMenuExpanded,
                           onClick
                         }: TopNavHamburgerProps) => {

  // todo replace with correct svg
  const closeSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
    </svg>
  );

  // todo replace with correct svg
  const hamburgerSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
    </svg>
  );

  return <span className="topNav__menuToggle" onClick={onClick}>
    {isMenuExpanded ? closeSvg : hamburgerSvg}
  </span>;
};

export default TopNavHamburger
