import React from 'react'
import classNames from 'classnames'
import { cutInLines } from '../../../utils/contentEdit'
import { ThreeElementBlockType } from '../../threeElementBlock/components/ThreeElementBlock'
import ThreeElementBox, { CardStyle } from './ThreeElementBox';

export type BoxesProps = {
  title?: string;
  subTitle?: string;
  blocks: ThreeElementBlockType[];
  style?: CardStyle;
}

const Boxes = ({ title, blocks, style, subTitle }: BoxesProps) => {
  const boxItems = blocks?.map((box, i) => (
    <ThreeElementBox element={box} key={i} style={style}/>
  ));

  const isEvenCount = blocks.length % 2 === 0;

  return (
    <section className={classNames('boxes', {
      'boxes--evenCount': isEvenCount
    })}>
      <div className="container">
        {title ? <h2 className="boxes__title">{cutInLines(title)}</h2> : null}
        {subTitle ? <div className="boxes__subtitle">{subTitle}</div> : null}
        <div className="boxes__wrapper">{boxItems}</div>
      </div>
    </section>
  )
};

export default Boxes
