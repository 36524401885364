import React from 'react';
import FreeText from '../../../components/freeText/components/FreeText';
import { LocationCardStoryblokType } from '../../../types/storyblokTypes';
import StoryblokImage from '../../../components/storyblokImage/components/StoryblokImage';
import Button from '../../../components/buttons/components/Button';

export const OfficeLocation = (location: LocationCardStoryblokType) => {
  return (
    <div className="c-officeLocation">
      <StoryblokImage className="c-officeLocation__flag" image={location.flag}/>
      <h3 className="c-officeLocation__title">{location.name}</h3>
      <FreeText className="c-officeLocation__address" richText={location.address}/>
      {location.ctaButton[0] ? <Button
        {...location.ctaButton[0]}
        severity="link"
        isCallToAction={true}
        className="c-officeLocation__ctaButton"
      /> : null}
    </div>
  )
};