import React from 'react'
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines';

export const LandingPageCtaBanner = ({ ctaBanner, classname  }: { ctaBanner: { content: CtaBannerType }, classname?: string }) => {

  if (!ctaBanner || !ctaBanner.content) {
    return null;
  }

  return (
    <section className={'landingPage__ctaBanner ' + classname}>
      <CtaBanner
        banner={ctaBanner.content}
      />
      <BackgroundLines
        images={{
          'center': 'https://a.storyblok.com/f/91820/x/9ece3d213d/lp-ctabanner.svg',
        }}
      />
    </section>
  );
};
