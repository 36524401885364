import * as React from 'react';
import { ResourceCardType, StoryblokImageType, StoryblokLinkType } from '../../types/storyblokTypes';
import ResourceCard from '../resourceCard/components/ResourceCard';

type ResourceCardData = {
  image: StoryblokImageType | undefined,
  tag: string | undefined,
  title: string,
  link: StoryblokLinkType,
  ctaLabel: string,
  date?: string
};

export const getResourceCardData = (card: ResourceCardType): ResourceCardData | null => {

  const linkObject: StoryblokLinkType = {
    cached_url: card.full_slug,
    linktype: 'story',
    url: '',
    id: ''
  };

  switch (card.content?.component) {
    case 'Blog':
      return {
        ctaLabel: 'Show me the post',
        image: card.content.headerImageThumb,
        link: linkObject,
        tag: 'Blog post',
        title: card.content.blogTitle
      };
    case 'Customer Story pages':
      return {
        title: card.content.header[0]?.headline,
        tag: 'Customer Story',
        link: linkObject,
        ctaLabel: 'Show me the story',
        image: card.content.thumbImage
      };
    case 'Whitepaper':
      return {
        title: card.content.title,
        tag: card.content.tag || 'Whitepaper',
        link: linkObject,
        ctaLabel: card.content.cardCtaLabel || 'READ THE WHITEPAPER',
        image: card.content.headerImageThumb
      };
    case 'Webinar':
      return {
        title: card.content.webinarTitle,
        tag: 'Webinar',
        link: linkObject,
        ctaLabel: 'REGISTER ON WEBINAR',
        image: card.content.headerImageBigThumb,
        date: card.content.date
      };
    case 'Webinar Recording':
      return {
        title: card.content.webinarTitle,
        tag: card.content.tag,
        link: linkObject,
        ctaLabel: 'WATCH THE RECORDING',
        image: card.content.headerImageBigThumb,
      };
    case 'Infographic Page':
      return {
        title: card.content.header[0]?.headline,
        tag: card.content.header[0]?.tag,
        link: linkObject,
        ctaLabel: card.content.cardCtaLabel,
        image: card.content.headerImageThumb || card.content.header[0]?.headerMedia
      };
    default:
      return null;
  }
};

export const createResourceCardElement = (card: ResourceCardType, idx: number): React.ReactNode => {

  const cardData = getResourceCardData(card);

  if (!cardData) {
    return null;
  }

  return (
    <ResourceCard
      image={cardData.image}
      title={cardData.title}
      ctaTarget={cardData.link}
      tag={cardData.tag}
      ctaButton={cardData.ctaLabel}
      key={idx}
      date={cardData.date}
    />
  );
};
