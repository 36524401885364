import * as React from 'react'
import '../../main.less'
import NavDropdownMainLink from './dropdown/NavDropdownMainLink'
import NavDropdownSmallItems from './dropdown/NavDropdownSmallItems'

const Components = (type: string): any => {
  const ComponentList = {
    'Top Nav Submenu Main Link': NavDropdownMainLink,
    'Top Nav Submenu Small Items': NavDropdownSmallItems,
  } as Record<string, any>;

  if (typeof ComponentList[type] === 'undefined') {
    return null
  }
  return ComponentList[type]
}

export default Components
