import React from 'react'
import AccuritySeoMeta, { AccuritySeoMetaType, } from '../../../components/AccuritySeoMeta'
import SubHeading from '../../../components/subHeading/components/SubHeading'
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock'
import { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner'
import TestimonialSlider, { TestimonialSliderType, } from '../../../components/testimonialsSlider/components/TestimonialSlider'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import ThreeElementBlockContainer from '../../../components/threeElementBlock/components/ThreeElementBlockContainer'
import BackgroundLines from '../../../components/backgroundLines/components/BackgroundLines'
import FiftyFiftyBlock, { FiftyFiftyBlockType, } from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlock'
import CaseStudies from '../../../components/caseStudies/components/CaseStudies'
import { StoryblokImageType, StoryblokLinkType, } from '../../../types/storyblokTypes'
import ErrorBoundary from '../../../components/ErrorBoundary'
import { LandingPageCtaBanner } from '../components/LandingPageCtaBanner'
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

type LandingPageV3Props = {
  seoMeta: AccuritySeoMetaType
  header: StorybookUniversalHeaderType[]

  title: string
  paragraph: string

  title2: string
  companyLogo: StoryblokImageType
  caseStudyTitle: string
  text: string
  ctaButton: string
  ctaLink: StoryblokLinkType
  image: StoryblokImageType

  useCaseTitle: string
  useCaseOrBenefit: ThreeElementBlockType[]

  featuresTitle: string
  featuresBlocks: FiftyFiftyBlockType[]

  testimonials: TestimonialSliderType[]

  ctaBanner: { content: CtaBannerType }
  ctaBanner2: { content: CtaBannerType }
}

const LandingPageV3 = ({
  seoMeta,
  header,
  title,
  paragraph,
  title2,
  companyLogo,
  caseStudyTitle,
  text,
  ctaButton,
  ctaLink,
  image,
  useCaseTitle,
  useCaseOrBenefit,
  featuresTitle,
  featuresBlocks,
  testimonials,
  ctaBanner,
  ctaBanner2,
}: LandingPageV3Props) => {
  return (
    <div className="landingPageV3">
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />

      <section className={"landingPageV3__subHeading"}>
        <div className="container">
          <SubHeading title={title} paragraph={paragraph} />
        </div>
      </section>

      <section className="landingPageV3__caseStudies caseStudies">
        <div className="container">
          <SubHeading title={title2} />
          <CaseStudies
            image={image}
            caseStudyTitle={caseStudyTitle}
            companyLogo={companyLogo}
            text={text}
            ctaButton={ctaButton}
            ctaLink={ctaLink}
          />
        </div>
        <BackgroundLines
          images={{
            center:
              "https://a.storyblok.com/f/91820/x/3f27aa36d1/acc-data-governance-lines.svg",
          }}
        />
      </section>

      <section className={"landingPageV3__useCases container"}>
        <SubHeading title={useCaseTitle} />
        <ThreeElementBlockContainer
          blocksPerRow={4}
          blocks={useCaseOrBenefit}
          centered
        />
      </section>

      <LandingPageCtaBanner
        ctaBanner={ctaBanner}
        classname={"landingPageV3__ctaBanner"}
      />

      <section className={"landingPageV3__features container"}>
        <SubHeading title={featuresTitle} />
        {featuresBlocks.map((block, index) => (
          <FiftyFiftyBlock key={index} {...block} />
        ))}
      </section>

      <section className="landingPageV3__testimonial container">
        <ErrorBoundary placeholderStyle={{ width: "100px", height: "100px" }}>
          <TestimonialSlider {...testimonials[0]} />
        </ErrorBoundary>
      </section>

      <LandingPageCtaBanner
        ctaBanner={ctaBanner2}
        classname={"landingPageV3__ctaBanner"}
      />

      <FooterContainer />
    </div>
  )
}

export default LandingPageV3
