import React from 'react'
import classNames from 'classnames';
import { StoryblokImageType, WebinarType } from '../../../types/storyblokTypes';
import StoryblokImage from '../../../components/storyblokImage/components/StoryblokImage';
import TopNavContainer from '../../../navigation/components/TopNavContainer';
import { VideoThumbnail } from '../../../components/videoThumbnail/components/VideoThumbnail';

export type WebinarHeroProps = {
  tag: string;
  title: string;
  date: string;
  presenters: WebinarType['presenters'];
  headerImage: StoryblokImageType;
  isOnDemand?: boolean;
  onHeaderImageClick?: () => void;
}

const WebinarHero = ({
                       headerImage,
                       tag,
                       presenters,
                       date,
                       title,
                       isOnDemand,
                       onHeaderImageClick
                     }: WebinarHeroProps) => {

  const presentersElms = presenters?.map((presenter, idx) => {
    return (
      <div className="webinarHero__presenter" key={idx}>
        <StoryblokImage image={presenter.content?.profilePhoto} className={'webinarHero__presenterPhoto'}/>
        <span className="webinarHero__presenterName">{presenter.content?.name}</span>
      </div>
    )
  });

  return (
    <div className={classNames('webinarHero', { 'webinarHero--onDemand': isOnDemand })}>
      <TopNavContainer inverted={true}/>

      <div className="container">
        <div className="webinarHero__container">
          <div className="webinarHero__leftContent">
            <div className="webinarHero__tag">{tag}</div>
            <h1 className="webinarHero__title">{title}</h1>
            <div className="webinarHero__date">{date}</div>
            <div className="webinarHero__presenters">{presentersElms}</div>
          </div>
          <div className="webinarHero__imageWrapper">
            {isOnDemand
              ? <VideoThumbnail image={headerImage} onClick={onHeaderImageClick} imageClassName={'webinarHero__image'}/>
              : <StoryblokImage image={headerImage} className={'webinarHero__image'}/>}
          </div>
        </div>
      </div>

    </div>
  )
};

export default WebinarHero;
