import { useEffect, useState } from 'react';

export const useHasSearchParam = (paramName: string) => {
  const [hasParam, setHasParam] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setHasParam(searchParams.has(paramName));
  }, []);

  return hasParam;
};