import React from 'react';
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import { StoryblokLogoStripeType } from '../../../types/storyblokTypes';

export type LogoStripeProps = {
  variant?: 'default' | 'demo-page' | 'compact'
} & StoryblokLogoStripeType;

export const LogoStripe = ({
                             logos,
                             title,
                             variant = 'default'
                           }: LogoStripeProps) => {

  if (!logos || logos.length === 0) {
    return null;
  }

  return (
    <div className={`c-logoStripe c-logoStripe--${variant} container ${variant === 'compact' ? 'sectionMargins--compact' : 'sectionMargins'}`}>
      {title && <h2 className="c-logoStripe__title sectionTitle">{title}</h2>}
      <div className="c-logoStripe__logosContainer">
        {logos.map((logo, idx) => {
          return (
            <StoryblokImage
              image={logo}
              className="c-logoStripe__logoImage"
              key={idx}
            />
          );
        })}
      </div>
    </div>
  )
};
