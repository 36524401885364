import React from 'react';

export type BackgroundLinesProps = {
  images: { [imagePosition: string]: string }
}

const BackgroundLines = ({ images }: BackgroundLinesProps) => {

  return (
    <div className={'backgroundLinesContainer'}>
      {Object.keys(images).map(imagePosition => (
        <div
          key={imagePosition}
          className={`backgroundLines backgroundLines--${imagePosition}`}
          style={{ backgroundImage: `url(${images[imagePosition]})` }}
        />
      ))}
    </div>
  );
};

export default BackgroundLines;
