import React from 'react';

export type LocationBlockType = {
  mapSrc: string;
  city: string;
  address: string;
  address2: string;
  id: string;
  taxNumber: string;
  telNumber: string;
  email: string;
}

const LocationBlock = ({ mapSrc, city, address, address2, id, taxNumber, telNumber, email }: LocationBlockType) => {
  return (
    <div className={"locationBlock"}>
      <div className="locationBlock__mapContainer">
        <iframe src={mapSrc}/>
      </div>
      <div className="locationBlock__infoContainer">
        <h3>{city}</h3>
        <p>{address}</p>
        <p>{address2}</p>
        <p>{id}</p>
        <p>{taxNumber}</p>
        {telNumber ? <p>tel.: <a href={`tel:${telNumber}`}>{telNumber}</a></p> : null}
        {email ? <p>email: <a href={`mailto:${email}`}>{email}</a></p> : null}
      </div>
    </div>
  );
};

export default LocationBlock;
