import React from "react"
import { render } from "storyblok-rich-text-react-renderer-ts"

import StoryblokLink from "../../storyblokLink/components/StoryblokLink"
import StoryblokImage from "../../storyblokImage/components/StoryblokImage"

import { ReferenceLogo } from "../../referenceLogo/components/ReferenceLogosContainer"

type ClientsType = {
  title: string
  text: any
  logos: { content: ReferenceLogo }[]
}

const Clients = ({ title, text, logos }: ClientsType) => {
  const paragraph = render(text)

  const logoLinks = logos.map((item, i) => (
    <StoryblokLink
      to={item?.content?.link}
      className="c-clients__logoItem"
      key={`logos-reference-${i}`}
    >
      <StoryblokImage image={item?.content?.logo} />
    </StoryblokLink>
  ))

  return (
    <section className="c-clients">
      <div className="container">
        <h2 className="c-clients__title">{title}</h2>
        <div className="c-clients__text">{paragraph}</div>

        {logos.length && <div className="c-clients__logos">{logoLinks}</div>}
      </div>
    </section>
  )
}

export default Clients
