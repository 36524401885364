import * as React from 'react';
import classNames from 'classnames';
import { ResourceCardType, StoryblokLinkType } from '../../../types/storyblokTypes';
import Button from '../../buttons/components/Button';
import { createResourceCardElement } from '../utils';

export type ThreeResourceCardContainerProps = {
  title?: string;
  resources: ResourceCardType[];
  ctaButton?: string;
  ctaTarget?: StoryblokLinkType;
};

const BlogCardsContainer = ({ resources, title, ctaButton, ctaTarget }: ThreeResourceCardContainerProps) => {

  resources = (resources || []).slice(0, 3);

  return (
    <div className="container">
      <div className="blogCardsContainer">
        {title && <h2 className="blogCardsContainer__title">{title}</h2>}
        <div className={classNames('blogCardsContainer__cardsWrapper', { 'blogCardsContainer__cardsWrapper--fullHouse': resources.length === 3 })}>
          {resources.map(createResourceCardElement)}
        </div>
        {ctaTarget && ctaButton && <Button
          severity={'tertiary-small'}
          link={ctaTarget}
          label={ctaButton}
        />}
      </div>
    </div>
  );
};

export default BlogCardsContainer;
