import * as React from "react"
import {
  StoryblokImageType,
  StoryblokLinkType,
} from "../../../types/storyblokTypes"
import StoryblokImage from "../../storyblokImage/components/StoryblokImage"

export type ReferenceLogosContainerProps = {
  referenceLogos: { content: ReferenceLogo; published_at: string }[]
}

export type ReferenceLogo = {
  logo: StoryblokImageType
  link?: StoryblokLinkType
}

const ReferenceLogosContainer = ({
  referenceLogos,
}: ReferenceLogosContainerProps) => {
  return (
    <div className="referenceLogoContainer">
      {referenceLogos
        .filter(story => story && story.content && story.content.logo)
        .sort(
          (a, b) =>
            new Date(b.published_at).valueOf() -
            new Date(a.published_at).valueOf()
        )
        .map((story, idx) => {
          const { link, logo } = story.content
          const className = "referenceLogo"

          return link && link.url ? (
            <a className={className} key={idx} href={link.url}>
              <StoryblokImage image={logo} />
            </a>
          ) : (
            <div className={className} key={idx}>
              <StoryblokImage image={logo} />
            </div>
          )
        })}
    </div>
  )
}

export default ReferenceLogosContainer
