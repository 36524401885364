import React from 'react';
import FreeText from '../../../components/freeText/components/FreeText';
import { LocationCardStoryblokType } from '../../../types/storyblokTypes';
import { OfficeLocation } from './OfficeLocation';

export type CompanyOfficesProps = {
  title: string
  offices: LocationCardStoryblokType[];
  companyName: string;
  companyInfo: string;
}

export const CompanyOffices = ({
                                 title,
                                 companyInfo,
                                 companyName,
                                 offices
                               }: CompanyOfficesProps) => {

  return (
    <div className="c-companyOffices container sectionMargins">
      <h2 className="c-companyOffices__title sectionTitle">{title}</h2>
      <div className="c-companyOffices__offices">
        {offices.map(office => <OfficeLocation {...office}/>)}
      </div>
      <h4 className="c-companyOffices__companyName">{companyName}</h4>
      <FreeText
        richText={companyInfo}
        className="c-companyOffices__companyInfo"
      />
    </div>
  );
};
