import React from 'react'
import classNames from 'classnames'
import StoryblokImage from '../../storyblokImage/components/StoryblokImage'
import { ThreeElementBlockType } from '../../threeElementBlock/components/ThreeElementBlock'

export type CardStyle = 'white' | 'dark';

export type ThreeElementBoxProps = {
  element: ThreeElementBlockType;
  style?: CardStyle;
}

const ThreeElementBox = ({ element, style = 'white' }: ThreeElementBoxProps) => {
  return (
    <div className="boxes__itemWrapper">
      <div className={classNames('boxes__item', { 'boxes__item--dark': style === 'dark' })}>
        <div className="boxes__imageWrapper">
          <StoryblokImage image={element.icon} className="boxes__icon"/>
        </div>
        <h3 className="boxes__name">{element.title}</h3>
        <p className="boxes__text">{element.paragraph}</p>
      </div>
    </div>
  )
};

export default ThreeElementBox
