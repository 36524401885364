import React from 'react';
import { BlogType, StoryblokButtonType, StoryblokLinkType } from '../../../types/storyblokTypes';
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import { StorybookHeaderBackgroundEnum, UniversalHeader } from '../../header/components/UniversalHeader';


type FeaturedBlogPostHeroProps = {
  blogPost: { content: BlogType, full_slug: string };
  tag?: string;
  readPostLabel?: string;
}

const FeaturedBlogPostHero = ({
                                blogPost,
                                tag,
                                readPostLabel
                              }: FeaturedBlogPostHeroProps) => {

  const author = blogPost.content.blogAuthor[0];

  const blogLink: StoryblokLinkType = {
    cached_url: blogPost.full_slug,
    linktype: 'story',
    url: '',
    id: ''
  };

  const ctaButton: StoryblokButtonType | undefined = readPostLabel ? {
    label: readPostLabel,
    link: blogLink,
    severity: 'tertiary'
  } : undefined;

  const authorsElements = author ? (
    <div className="c-featuredBlogPostHero__authors">
      <div className="c-featuredBlogPostHero__author">
        <StoryblokImage
          className="c-featuredBlogPostHero__authorImage"
          image={author.content.profilePhoto}
        />
        <div className="c-featuredBlogPostHero__authorName">
          {author.content.name}
          {blogPost.content.readTime && <span className="c-featuredBlogPostHero__readTime">{blogPost.content.readTime}</span>}
        </div>
      </div>
    </div>
  ) : undefined;

  return (
    <UniversalHeader
      className="c-featuredBlogPostHero"
      tag={tag}
      headline={blogPost.content.blogTitle}
      leadParagraphPlain={blogPost.content.leadParagraph}
      headerMedia={blogPost.content.headerImage}
      ctaButtons={ctaButton ? [ctaButton] : []}
      customLeftElement={authorsElements}
      background={StorybookHeaderBackgroundEnum.BACKGROUND_2}
    />
  );
};

export default FeaturedBlogPostHero
