import * as React from 'react'
import { StoryblokLinkType, } from '../../types/storyblokTypes'

export type TopNavLogoProps = {
  logo_link: StoryblokLinkType
}

const TopNavLogo = ({
                      logo_link,
                    }: TopNavLogoProps) => {

  return logo_link?.url
    ? <a href={logo_link.url} className={'topNav__logo'}/>
    : <span className={'topNav__logo'}/>

};

export default TopNavLogo
