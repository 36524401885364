import React from 'react';
import className from 'classnames';
import { CheckboxIcon } from '../../icons/components/CheckboxIcon';

export type FilterOptionProps = {
  group: string;
  label: string;
  checked: boolean;
  onChange: (isChecked: boolean) => void;
};

const FilterOption: React.FC<FilterOptionProps> = ({ label, group, checked, onChange }) => {
  const optionId = `${group}-${label}`;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  const classes = className('c-filterOption', { 'c-filterOption--active': checked });

  return (
    <li className={classes}>
      <label htmlFor={optionId}>
        <input
          type="checkbox"
          id={optionId}
          name={optionId}
          checked={checked}
          onChange={handleOnChange}
        />
        <CheckboxIcon isChecked={checked} className="c-filterOption__checkbox"/>
        {label}
      </label>
    </li>
  )
};

export default FilterOption;