import React from 'react'
import classNames from 'classnames'
import StoryblokImage from '../../storyblokImage/components/StoryblokImage'
import { FiftyFiftyBlockV2Type, StoryblokButtonType, } from '../../../types/storyblokTypes'
import Button from '../../buttons/components/Button';

const renderCtaButton = (button: StoryblokButtonType | undefined) => {
  if (!button) {
    return null;
  }

  const isCallToActionButton = button.severity === 'link';

  return <Button {...button} className="c-fiftyFiftyBlockV2__callToAction" isCallToAction={isCallToActionButton}/>
};

export const FiftyFiftyBlockV2 = ({
                                    mediaAsset,
                                    title,
                                    paragraph,
                                    button,
                                    bulletPoints = '',
                                  }: FiftyFiftyBlockV2Type) => {

  const renderBulletPoints = () => {
    if (bulletPoints.length <= 0) {
      return null;
    }

    const items = bulletPoints.split('\n');
    return <ul className={classNames('c-fiftyFiftyBlockV2__bulletPoints', { 'c-fiftyFiftyBlockV2__bulletPoints--multiple': items.length > 1 })}>
      {items.map((text, idx) => <li key={idx}>{text}</li>)}
    </ul>
  };

  return (
    <div className="c-fiftyFiftyBlockV2">
      <div className="c-fiftyFiftyBlockV2__mediaAssetContainer">
        <StoryblokImage image={mediaAsset} className="c-fiftyFiftyBlockV2__mediaAsset"/>
      </div>
      <div className="c-fiftyFiftyBlockV2__textContainer">
        <h3 className="c-fiftyFiftyBlockV2__title">{title}</h3>
        <p className="c-fiftyFiftyBlockV2__paragraph">{paragraph}</p>
        {renderBulletPoints()}
        {renderCtaButton(button[0])}
      </div>
    </div>
  )
};
