import React from 'react'
import AccuritySeoMeta, { AccuritySeoMetaType, } from '../../../components/AccuritySeoMeta'
import { FiftyFiftyBlockV2Type, StoryblokButtonType, StoryblokImageType, StoryblokLogoStripeType, StorybookUniversalFormType, } from '../../../types/storyblokTypes'
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';
import { LogoStripe } from '../../../components/logoStripe/components/LogoStripe';
import Features from '../components/Features';
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock';
import Rating from '../components/Rating';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import FiftyFifty from '../components/FiftyFifty';
import Benefits from '../components/Benefits';
import ZohoUniversalForm from '../../../components/zohoForms/components/UniversalForm';
import ZohoDemoSignUpForm from '../../../components/zohoForms/components/ZohoDemoSignUpForm';

type LandingPageCompactProps = {
  seoMeta: AccuritySeoMetaType
  header: StorybookUniversalHeaderType[]

  form?: { content: StorybookUniversalFormType, name: string };
  formSource: string;

  logoStripe: { content: StoryblokLogoStripeType };

  featuresTitle: string;
  featuresSubtitle: string;
  featuresDescription: string;
  featuresImage: StoryblokImageType;
  featuresCtaButton: StoryblokButtonType[];

  benefitsTitle: string;
  benefitsSubtitle: string;
  benefitsCards: ThreeElementBlockType[];

  ratingTitle: string;
  ratingValue: string;
  ratingText: string;
  ratingAuthor: { key: string; value: string }[];
  ratingLogo: StoryblokImageType;
  ratingStars: StoryblokImageType;

  fiftyFiftyBlocks: FiftyFiftyBlockV2Type[];
}

const LandingPageCompact = ({
                              seoMeta,
                              header,
                              form,
                              formSource,
                              logoStripe,

                              featuresTitle,
                              featuresSubtitle,
                              featuresDescription,
                              featuresImage,
                              featuresCtaButton,

                              benefitsTitle,
                              benefitsSubtitle,
                              benefitsCards,

                              ratingTitle,
                              ratingValue,
                              ratingStars,
                              ratingText,
                              ratingAuthor,
                              ratingLogo,
                              fiftyFiftyBlocks,
                            }: LandingPageCompactProps) => {

  let formElement = null;
  if (form?.content) {
    if (form.name === 'LP Demo Form') {
      formElement = <ZohoDemoSignUpForm
        style={'compact'}
        formLabels={{
          title: form.content.titleForm,
          firstNameLabel: 'First Name*',
          lastNameLabel: 'Last Name*',
          companyLabel: 'Company*',
          jobTitleLabel: 'Job Title*',
          emailLabel: 'Email*',
          phoneLabel: 'Phone*',
          ctaButtonLabel: form.content.submitLabel,
          submitErrorMessage: form.content.submitErrorMessage
        }}
        formSource={formSource}
      />;
    } else {
      formElement = <ZohoUniversalForm
        {...form.content}
        formSource={formSource}
        style={'compact'}
      />;
    }

    formElement = <div className="hashTarget" id="form">
      {formElement}
    </div>;
  }

  return (
    <div className="landingPageCompact lightGreyBcg">
      <AccuritySeoMeta {...seoMeta} />

      {header[0] ? <UniversalHeader
        {...header[0]}
        className="landingPageCompact__header"
        rightContent={formElement}
        reducedMenu={true}
      /> : null}

      {logoStripe?.content ? <LogoStripe
        {...logoStripe.content}
        variant="compact"
      /> : null}

      <Features
        featuresTitle={featuresTitle}
        featuresSubtitle={featuresSubtitle}
        featuresDescription={featuresDescription}
        featuresImage={featuresImage}
        featuresCtaButton={featuresCtaButton}
      />

      {benefitsCards.length > 0 ? <Benefits
        benefitsCards={benefitsCards}
        benefitsSubtitle={benefitsSubtitle}
        benefitsTitle={benefitsTitle}
      /> : null}

      <Rating
        ratingTitle={ratingTitle}
        ratingStars={ratingStars}
        ratingValue={ratingValue}
        ratingText={ratingText}
        ratingAuthor={ratingAuthor}
        ratingLogo={ratingLogo}
      />

      <FiftyFifty
        fiftyFiftyBlocks={fiftyFiftyBlocks}
      />

      <FooterContainer isCompact={true}/>
    </div>
  )
};

export default LandingPageCompact
