import React, { CSSProperties, ErrorInfo } from 'react';
import classNames from 'classnames';

type ErrorBoundaryProps = {
  placeholderStyle?: CSSProperties;
  className?: string;
}

type ErrorBoundaryState = {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service

    // TODO logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          className={classNames('errorPlaceholder', this.props.className)}
          style={this.props.placeholderStyle}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
