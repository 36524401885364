import React from 'react';
import classNames from 'classnames';
import CheckBox from './CheckBox';

export const OPTIONS_SEPARATOR = ';';

export type MultipleCheckBoxesProps = {
  name: string;
  label: string | JSX.Element;
  options: string[];
  value: string;
  setValue: (name: string, value?: string) => void;
  error?: string;
  clearError?: (name: string) => void;
}

const MultipleCheckBoxes = ({ name, label, options, value, setValue, error, clearError }: MultipleCheckBoxesProps) => {

  const handleCheckBoxChange = (option: string, checked?: boolean) => {
    if (error && clearError) {
      clearError(name);
    }

    let parts = value.split(OPTIONS_SEPARATOR);

    if (checked && !parts.includes(option)) {
      parts.push(option);
    } else if (!checked && parts.includes(option)) {
      parts = parts.filter(q => q !== option);
    }

    setValue(name, parts.filter(q => !!q).join(OPTIONS_SEPARATOR));
  };

  return (
    <div className={classNames('multipleCheckBoxes', { 'multipleCheckBoxes--error': error })}>
      <span className="multipleCheckBoxes__label">
        {label}
      </span>
      {options.map(option => (
        <CheckBox
          name={option}
          label={option}
          setValue={handleCheckBoxChange}
          key={option}
          value={isChecked(value, option)}
        />
      ))}
      {error && <span className="multipleCheckBoxes__error">{error}</span>}
    </div>
  );
};

const isChecked = (value: string, option: string) => value.split(OPTIONS_SEPARATOR).includes(option);

export default MultipleCheckBoxes;
