import React, { useState } from 'react'
import FooterContainer from '../../../components/footer/components/FooterContainer'
import AccuritySeoMeta from '../../../components/AccuritySeoMeta'
import { StorybookDeploymentPageType } from '../../../types/storyblokTypes';
import CtaBanner from '../../../components/ctaBanner/components/CtaBanner';
import { UniversalHeader } from '../../../components/header/components/UniversalHeader';
import ResourceCardsContainer from '../../../components/resourceCardContainer/components/ResourceCardsContainer';
import FiftyFiftyBlock from '../../../components/fiftyFiftyBlock/components/FiftyFiftyBlock';
import DeploymentModelTable from './DeploymentModelTable';
import scrollTo from 'gatsby-plugin-smoothscroll'

export type DeploymentModelPageProps = StorybookDeploymentPageType

const DeploymentModelPage = ({
                               seoMeta,
                               ctaBanner,
                               fiftyFiftyCards,
                               header,
                               resources,
                               resourcesTitle,
                               cloudLabel,
                               onPremiseLabel,
                               rows
                             }: DeploymentModelPageProps) => {

  const [currentColumn, setCurrentColumn] = useState<number>(0);

  const scrollToTable = (columnIndex: number) => {
    setCurrentColumn(columnIndex);
    scrollTo('.c-deploymentModelTable');
  };

  return (
    <div className="deploymentModelPage">
      <AccuritySeoMeta {...seoMeta}/>
      <UniversalHeader {...header[0]}/>
      <div className="deploymentModelPage__fiftyFiftyBlocks container">
        {fiftyFiftyCards.map((card, index) => {
          return (
            <FiftyFiftyBlock
              key={index}
              {...card}
              // button override to achieve scroll to table and switching tab in same time
              button={[{
                label: card.button[0].label,
                severity: 'link',
                onClick: () => scrollToTable(index)
              }]}
            />
          );
        })}
      </div>
      <DeploymentModelTable
        rows={rows}
        navLabels={[cloudLabel, onPremiseLabel]}
        currentColumn={currentColumn}
        setCurrentColumn={setCurrentColumn}
      />
      <CtaBanner
        banner={ctaBanner?.content}
        addMargin={true}
      />
      <ResourceCardsContainer
        resources={resources}
        title={resourcesTitle}
      />
      <FooterContainer/>
    </div>
  )
};

export default DeploymentModelPage
