import React from 'react';
import StoryblokImage from '../../storyblokImage/components/StoryblokImage';
import { StoryblokImageType } from '../../../types/storyblokTypes';

export type TestimonialType = {
  name: string;
  jobTitle?: string;
  text: string;
  logo: StoryblokImageType;
}

const Testimonial = ({ name, jobTitle, text, logo }: TestimonialType) => {
  return (
    <div className="testimonial">
      <p>{text}</p>
      <h4 className="testimonial__name">{name}</h4>
      <h4>{jobTitle}</h4>
      <StoryblokImage image={logo}/>
    </div>
  );
};

export default Testimonial;
