import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Footer from './Footer'
import { data as dummyFooterData } from '../stories/data'

type FooterContainerProps = {
  isCompact?: boolean
}

/**
 * There is currently a bug when using gatsby's useStaticQuery and storybook,
 * so until it is fixed we will fall back to hard-coded fake data when
 * useStaticQuery fails. See: https://github.com/gatsbyjs/gatsby/issues/26099
 */
const useNavDataOrFakeData = () => {
  try {
    const queryResult = useStaticQuery(
      graphql`
        query {
          storyblokEntry(name: { eq: "Footer" }) {
            content
          }
        }
      `
    );
    return JSON.parse(queryResult.storyblokEntry.content)
  } catch {
    console.error(
      'Static Query for Footer failed!!! Falling back to dummy data to populate footer.'
    );
    return dummyFooterData
  }
};

const FooterContainer = ({ isCompact }: FooterContainerProps) => {
  const content = useNavDataOrFakeData();

  return <Footer isCompact={isCompact} {...content} />
};

export default FooterContainer
