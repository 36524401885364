import React from 'react'
import { navigate } from 'gatsby'
import TextField from '../../../components/form/components/TextField'
import FormRow from '../../../components/form/components/FormRow'
import CheckBox from '../../../components/form/components/CheckBox'
import { useZohoFormLogic, ZohoFormFields } from '../../form/zohoLogic'
import { StoryblokLinkType, WhitepaperFormLabelsType } from '../../../types/storyblokTypes'
import ReactMarkdown from 'react-markdown'
import SubmitButton from '../../form/components/SubmitButton';
import { ZOHO_FORM_IDS } from '../zohoConfig';

const ZOHO_WHITEPAPER_SUBMIT_URL =
  `https://form.accurity.ai/simplitygroup/form/${ZOHO_FORM_IDS.WHITEPAPER}/htmlRecords/submit`;

export type WhitepaperFormType = {
  firstName: string
  lastName: string
  company: string
  email: string
  marketing: boolean
}

export type ZohoWhitepaperFormProps = {
  formLabels: WhitepaperFormLabelsType
  formSource: string
  docPath: StoryblokLinkType
  docName: string
}

const ZohoWhitepaperForm = ({
                              formLabels,
                              formSource,
                              docName,
                              docPath,
                            }: ZohoWhitepaperFormProps) => {
  const zohoFields: ZohoFormFields<WhitepaperFormType> = {
    firstName: {
      zohoFieldName: 'SingleLine7',
      label: formLabels?.firstNameLabel,
      initialValue: '',
      required: true,
    },
    lastName: {
      zohoFieldName: 'SingleLine8',
      label: formLabels?.lastNameLabel,
      initialValue: '',
      required: true,
    },
    company: {
      zohoFieldName: 'SingleLine9',
      label: formLabels?.companyLabel,
      initialValue: '',
      required: true,
    },
    email: {
      zohoFieldName: 'Email',
      label: formLabels?.emailLabel,
      initialValue: '',
      required: true,
      runEmailValidation: true,
    },
    marketing: {
      zohoFieldName: 'DecisionBox',
      initialValue: false,
      required: false,
      label: formLabels?.marketingLabel,
    },
  };

  const onSubmit = () => {
    const anchor = document.createElement('a');
    anchor.href = docPath.cached_url;
    anchor.target = '_blank';
    anchor.setAttribute('download', docName);

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    navigate(formLabels?.successRedirectUrl)
  };

  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
  } = useZohoFormLogic<WhitepaperFormType>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_WHITEPAPER_SUBMIT_URL,
    successRedirectUrl: formLabels?.successRedirectUrl,
    submitErrorMessage: formLabels?.submitErrorMessage || '',
    zohoFormFields: zohoFields,
    onSubmit: onSubmit,
  });

  return (
    <div className="form__container">
      <h3 className="form__headline">{formLabels?.titleForm}</h3>
      <FormRow>
        <TextField
          name="firstName"
          label={zohoFields.firstName.label}
          value={values.firstName}
          setValue={setValue}
          error={errors.firstName}
          clearError={clearError}
        />
        <TextField
          name="lastName"
          label={zohoFields.lastName.label}
          value={values.lastName}
          setValue={setValue}
          error={errors.lastName}
          clearError={clearError}
        />
      </FormRow>
      <TextField
        name="company"
        label={zohoFields.company.label}
        value={values.company}
        setValue={setValue}
        error={errors.company}
        clearError={clearError}
      />
      <TextField
        name="email"
        label={zohoFields.email.label}
        value={values.email}
        setValue={setValue}
        error={errors.email}
        clearError={clearError}
      />
      <div className="form__checkBoxContainer">
        <CheckBox
          name="marketing"
          label={
            <ReactMarkdown
              renderers={{
                link: (props: any) => (
                  <a href={props.href} target="_blank">
                    {props.children}
                  </a>
                ),
              }}
            >
              {zohoFields.marketing.label}
            </ReactMarkdown>
          }
          value={values.marketing}
          setValue={setValue}
        />
      </div>
      <div className="form__buttonContainer">
        <SubmitButton
          isFetching={isFetching}
          label={formLabels?.submitLabel}
          doSubmit={doSubmit}
        />
        {!!submitError && <p className="form__submit-error">{submitError}</p>}
      </div>
    </div>
  )
};

export default ZohoWhitepaperForm
