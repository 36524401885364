import { graphql, useStaticQuery } from 'gatsby';
import { CtaBannerType } from '../components/CtaBanner';

export const useLoadCtaBanner = (uuid?: string): CtaBannerType | null => {
  if (!uuid) {
    return null;
  }

  let queryResult;
  try {
    queryResult = useStaticQuery(
      graphql`
      query LoadCtaBanners {
        allStoryblokEntry(filter: {full_slug: {regex: "/cta-banners/"}}) {
          nodes {
            uuid
            content
          }
        }
      }
    `
    );
  } catch {
    return null;
  }

  const banner = queryResult.allStoryblokEntry.nodes.find((q: any) => q.uuid === uuid);
  try {
    return JSON.parse(banner?.content as any);
  } catch (e) {
    return null;
  }
};