import React from 'react'
import CtaBanner, { CtaBannerType } from '../../../components/ctaBanner/components/CtaBanner';
import FooterContainer from '../../../components/footer/components/FooterContainer';
import AccuritySeoMeta, { AccuritySeoMetaType } from '../../../components/AccuritySeoMeta';
import ThreeElementBlockContainer from '../../../components/threeElementBlock/components/ThreeElementBlockContainer';
import { ThreeElementBlockType } from '../../../components/threeElementBlock/components/ThreeElementBlock';
import { StorybookUniversalHeaderType, UniversalHeader } from '../../../components/header/components/UniversalHeader';

export type AccurityFeaturesPageProps = {
  seoMeta: AccuritySeoMetaType;
  header: StorybookUniversalHeaderType[];
  features: ThreeElementBlockType[];
  ctaBanner: { content: CtaBannerType };
  ctaBanner2: { content: CtaBannerType };
}

const AccurityFeaturesPage = ({
                                seoMeta,
                                header,
                                ctaBanner,
                                ctaBanner2,
                                features
                              }: AccurityFeaturesPageProps) => {

  return (
    <div className={'accurityFeaturesPage'}>
      <AccuritySeoMeta {...seoMeta} />
      <UniversalHeader {...header[0]} />

      <div className={'container accurityFeaturesPage__contentContainer'}>
        <ThreeElementBlockContainer
          blocksPerRow={3}
          blocks={features}
        />
      </div>

      <CtaBanner
        banner={ctaBanner.content}
        addMargin={true}
      />

      <CtaBanner
        banner={ctaBanner2.content}
      />

      <FooterContainer/>
    </div>
  )
};

export default AccurityFeaturesPage;
