import './../main.less'
import React from 'react';
import ComponentNotFound from './component_not_found'
import Footer from './footer/components/Footer'
import NavigationComponents from '../navigation/components/components'
import Header from './header/components/Header'

import SolutionsOverviewPage from '../accurityPages/solutionsOverview/components/SolutionsOverviewPage'
import SolutionsDetailPage from '../accurityPages/solutionsDetail/components/SolutionsDetailPage'
import CustomizablePage from '../accurityPages/customizablePage/components/CustomizablePage'
import ThreeElementBlock from './threeElementBlock/components/ThreeElementBlock'
import CtaBannerPreview from './ctaBanner/components/CtaBannerPreview';
import HomePage from '../accurityPages/home/components/HomePage'
import DemoPage from '../accurityPages/demo/components/DemoPage'
import ThankYouPage from '../accurityPages/thankYou/components/ThankYouPage'
import CompanyPage from '../accurityPages/company/components/CompanyPage'
import BusinessGlossaryPage from '../accurityPages/businessGlossary/components/BusinessGlossaryPage'
import Page404 from '../accurityPages/404/components/Page404'
import ContactUsPage from '../accurityPages/contactUs/components/ContactUsPage'
import PlatformOverviewPage from '../accurityPages/platformOverview/components/PlatformOverviewPage'
import PlatformDetailPage from '../accurityPages/platformDetail/components/PlatformDetailPage'
import CustomerStoryBanner from './customerStoryBanner/components/CustomerStoryBanner'
import CustomerStoryPage from '../accurityPages/customerStory/components/CustomerStoryPage'
import LandingPage from '../accurityPages/landingPage/pages/LandingPage'
import PricingPage from '../accurityPages/pricing/components/PricingPage'
import RoadmapPage from '../accurityPages/roadmap/components/RoadmapPage'
import AccuritySaasPage from '../accurityPages/accuritySaas/components/AccuritySaasPage'
import AccurityFeaturesPage from '../accurityPages/accuritySaas/components/AccurityFeaturesPage'
import AccurityLatestFeaturesPage from '../accurityPages/accuritySaas/components/AccurityLatestFeaturesPage'
import AccuritySolutionPage from '../accurityPages/accuritySaas/components/AccuritySolutionPage'
import FullWidthCtaBanner from './ctaBanner/components/FullWidthCtaBanner'
import LandingPageV2 from '../accurityPages/landingPage/pages/LandingPageV2'
import LandingPageV3 from '../accurityPages/landingPage/pages/LandingPageV3'
import ResourcesOverview from '../accurityPages/resourcesOverview/components/ResourcesOverview'
import BlogOverviewPage from '../accurityPages/blog/components/BlogOverview'
import BlogDetailPage from '../accurityPages/blog/components/BlogDetail'
import CtaNewsletterBanner from './ctaBanner/components/CtaNewsletterBanner'
import ServicesPage from '../accurityPages/services/components/ServicesPage'
import WhitepaperPage from '../accurityPages/whitepaper/components/WhitepaperPage';
import ZohoBecomePartnerForm from './zohoForms/components/BecomePartnerForm';
import ZohoUniversalForm from './zohoForms/components/UniversalForm';
import WebinarPage from '../accurityPages/webinar/components/WebinarPage';
import OnDemandWebinarPage from '../accurityPages/webinar/components/OnDemandWebinarPage';
import DeploymentModelPage from '../accurityPages/deploymentModel/components/DeploymentModelPage';
import { LogoStripe } from './logoStripe/components/LogoStripe';
import CtaBannerPOC from './ctaBanner/components/CtaBannerPOC';
import DemoPresenterCard from './demoPresenterCard/components/DemoPresenterCard';
import ProductTermsPage from '../accurityPages/productTerms/components/ProductTerms';
import BlogBanner from './ctaBanner/components/BlogBanner';
import InfographicPage from '../accurityPages/infographic/InfographicPage';
import SolutionsDetailPageV2 from '../accurityPages/solutionsDetail/components/SolutionsDetailPageV2';
import { TopNavPreview } from '../navigation/stories/TopNavPreview';
import { CompanyPageV2 } from '../accurityPages/company/components/CompanyPageV2';
import { QualityTrialExpiredPage } from '../accurityPages/qualityTrial/components/QualityTrialExpiredPage';
import LandingPageCompact from '../accurityPages/landingPage/pages/LandingPageCompact';
import ZohoWhitepaperForm from './zohoForms/components/WhitepaperForm';
import ZohoContactUsForm from './zohoForms/components/ZohoContactUsForm';
import AllFeaturesPage from '../accurityPages/allFeatures/components/AllFeaturesPage';

/* eslint-disable */
const ComponentList = {
  // Common Components
  ['Footer']: Footer,
  ['Header']: Header,
  ['Customizable Page']: CustomizablePage,
  // 'Content Components'
  ['3-element Block']: ThreeElementBlock,
  ['ctaBanner']: CtaBannerPreview,
  ['CTA Banner Full Width']: FullWidthCtaBanner,
  ['CTA Newsletter Banner']: CtaNewsletterBanner,
  ['CTA Banner POC']: CtaBannerPOC,
  ['customerStoryBanner']: CustomerStoryBanner,
  ['Blog Banner']: BlogBanner,
  // 'Reusable Pages'
  ['Solution Detail pages']: SolutionsDetailPage,
  ['Solution Detail Page V2']: SolutionsDetailPageV2,
  ['Thank You pages']: ThankYouPage,
  ['Landing Page']: LandingPage,
  ['Landing Page V2']: LandingPageV2,
  ['Landing Page V3']: LandingPageV3,
  // 'Unique Pages'
  ['Solution Overview Page']: SolutionsOverviewPage,
  ['Home page']: HomePage,
  ['demo']: DemoPage,
  ['Company']: CompanyPage,
  ['Company v2']: CompanyPageV2,
  ['contactUs']: ContactUsPage,
  ['businessGlossary']: BusinessGlossaryPage,
  ['404']: Page404,
  ['platformOverview']: PlatformOverviewPage,
  ['Platform Modules pages']: PlatformDetailPage,
  ['Customer Story pages']: CustomerStoryPage,
  ['Roadmap Page']: RoadmapPage,
  ['Accurity Saas Page']: AccuritySaasPage,
  ['Accurity Features Page']: AccurityFeaturesPage,
  ['Accurity Latest Features Page']: AccurityLatestFeaturesPage,
  ['Accurity Solution Page']: AccuritySolutionPage,
  ['Blog']: BlogDetailPage,
  ['Blog Overview']: BlogOverviewPage,
  ['Resources Overview']: ResourcesOverview,
  ['servicesPage']: ServicesPage,
  ['Whitepaper']: WhitepaperPage,
  ['Universal Form']: ZohoUniversalForm,
  ['Become Partner Form']: labels => <ZohoBecomePartnerForm formLabels={labels} formSource={''}/>,
  ['Whitepaper Form']: labels => <ZohoWhitepaperForm formLabels={labels} docName={''} docPath={''} formSource={''}/>,
  ['getInTouchForm']: labels => <ZohoContactUsForm formLabels={labels} successRedirectUrl={''} formSource={''}/>,
  ['Webinar']: WebinarPage,
  ['Webinar Recording']: OnDemandWebinarPage,
  ['Logo Stripe']: LogoStripe,
  ['Deployment Page']: DeploymentModelPage,
  ['Pricing Page #2']: PricingPage,
  ['Demo Presenter Card']: DemoPresenterCard,
  ['Product Terms']: ProductTermsPage,
  ['Infographic Page']: InfographicPage,
  ['TopNav']: TopNavPreview,
  ['Quality Trial Expired Page']: QualityTrialExpiredPage,
  ['Landing Page - compact']: LandingPageCompact,
  ['All Features Page V2']: AllFeaturesPage
};
/* eslint-enable */

const Components = type => {
  if (NavigationComponents(type)) {
    return NavigationComponents(type)
  }

  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
};

export default Components
