import React from "react"
import { render } from "storyblok-rich-text-react-renderer-ts"

export type CustomerStoryNumberType = {
  value: string
  title: string
}

type CaseStudyNumbersProps = {
  title: string
  text: any
  numbers: CustomerStoryNumberType[]
  projectText: string
}

const CaseStudyNumbers = ({
  title,
  text,
  numbers,
  projectText,
}: CaseStudyNumbersProps) => {
  const boxes = numbers.map((box, i) => (
    <div className="caseStudyNumbers__box" key={`case-studt-number-${i}`}>
      <span className="caseStudyNumbers__box-number">{box.value}</span>
      <p className="caseStudyNumbers__box-title">{box.title}</p>
    </div>
  ))

  return (
    <section className="caseStudyNumbers">
      <div className="container">
        <div className="caseStudyNumbers__columns">
          <h2 className="caseStudyNumbers__headline">{title}</h2>
          <div className="caseStudyNumbers__text">{render(text)}</div>
          <div className="caseStudyNumbers__boxWrapper">{boxes}</div>
          {projectText && (
            <p className="caseStudyNumbers__project">{projectText}</p>
          )}
        </div>
      </div>
    </section>
  )
}

export default CaseStudyNumbers
