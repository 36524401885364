import React, { useState } from 'react'
import { StoryblokTableType } from '../../../../types/storyblokTypes';
import { PricingTableUtils } from './utils';
import { render } from 'storyblok-rich-text-react-renderer-ts'
import { SolutionTableRow } from './SolutionTableRow';
import SolutionTableTooltip from './SolutionTableTooltip';

export type SolutionAddonsTableProps = {
  addonsTitle: string;
  addonsSwitchLabel: string;
  addonsTable: StoryblokTableType;
}


const SolutionAddonsTable = ({
                               addonsSwitchLabel,
                               addonsTable,
                               addonsTitle
                             }: SolutionAddonsTableProps) => {

  const [isAnnual, setIsAnnual] = useState(true);
  const columnSelection = isAnnual ? [0, 1, 3] : [0, 1, 2];

  const headerCells: string[] = columnSelection.map(colIdx => addonsTable.thead[colIdx]?.value);
  const rows: SolutionTableRow[] = addonsTable.tbody?.map(PricingTableUtils.mapStoryblokTableRow)
    .map(row => ({
      cells: columnSelection.map(colIdx => row.cells[colIdx])
    }));

  return (
    <div className="solutionAddonsTable">
      <div className="solutionAddonsTable__switch" onChange={() => setIsAnnual(!isAnnual)}>
        <input className="accuritySwitch" type="checkbox" value={isAnnual as any}/>
        {render(addonsSwitchLabel)}
      </div>

      {addonsTitle && <h3 className="solutionAddonsTable__title">{addonsTitle}</h3>}

      <div className="c-st__table solutionAddonsTable__desktopTable">
        <div className="c-st__row c-st__row--border c-st__row--header">
          {headerCells.map((cell, index) => <div className="c-st__cell" key={index}>{cell}</div>)}
        </div>
        {rows?.map((row, rowIndex) => (
          <div className="c-st__row c-st__row--border" key={rowIndex}>
            {row.cells.map((cell, colIndex) => <div className="c-st__cell" key={colIndex}>
              {cell.value}
              <SolutionTableTooltip tooltip={cell.tooltip}/>
            </div>)}
          </div>
        ))}
      </div>

      <div className="c-st_table solutionAddonsTable__mobileTable">
        {rows.map((row, rowIndex) => <div className="solutionAddonsTable__mobileTableSection" key={rowIndex}>
          {row.cells.map((cell, cellIndex) => <div className="c-st__row c-st__row--border" key={cellIndex}>
            <div className="c-st__cell">{headerCells[cellIndex]}</div>
            <div className="c-st__cell">
              {cell.value}
              <SolutionTableTooltip tooltip={cell.tooltip}/>
            </div>
          </div>)}
        </div>)}
      </div>
    </div>
  )
};

export default SolutionAddonsTable;


